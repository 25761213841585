import { Component, OnInit, OnDestroy } from '@angular/core';
import { Client } from '../core/entity/client';
import { TodayDate } from '../shared/misc/today.date';
import { ClientService } from '../core/services/clients.service';
import { UserSettingsStorage } from '../core/storage/user.settings.storage';
import { TableColumn } from '../shared/components/table/interfaces/table-column';
import { TodoType } from '../core/state/types/todo.types';
import { ToastActions } from '../core/state/toast/toast.actions';
import { filter, first, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ListSelectors } from '@app/core/state/list/list.selectors';
import { TodosSelectors } from '@app/core/state/todos/todos.selectors';
import { TodosActions } from '@app/core/state/todos/todos.actions';
import { ListActions } from '@app/core/state/list/list.actions';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { takeFirstTruthy } from '@app/core/misc/rxjs-operators';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { showTodoDialog } from '@app/shared/misc/showTodoDialog';
import { NotesService } from '@app/core/services/notes.service';
import { toTodoTransformer } from '@app/core/state/transformers/todo.transformers';
import { getTranslationProvider } from '@core/i18n/getTranslationProvider';
import { TranslatableComponent } from '@core/i18n/TranslatableComponent';
import { TranslatableStringType } from '@core/i18n/types';
import { translateColumnHeaders } from '@shared/components/table/misc/translate-column-header';
import { openTranslatableDialog } from '../shared/misc/openDialog';
import { RemoveTodoDialogComponent } from './remove.todo.dialog.component';

interface TodoListComponentTranslations {
  client: TranslatableStringType;
  orgNumber: TranslatableStringType;
  customerNumber: TranslatableStringType;
  responsibleUser: TranslatableStringType;
  type: TranslatableStringType;
  assignmentName: TranslatableStringType;
  deadline: TranslatableStringType;
  notes: TranslatableStringType;
  createdBy: TranslatableStringType;
  executionUser: TranslatableStringType;
  status: TranslatableStringType;
  archived: TranslatableStringType;
  todoItemBelongsToArchivedClientMessage: TranslatableStringType;
}

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo.list.component.html',
  providers: [getTranslationProvider('TodoListComponent')],
})
export class TodoListComponent
  extends TranslatableComponent<TodoListComponentTranslations>
  implements OnInit, OnDestroy
{
  public loading$: Observable<boolean>;
  public statusText$: Observable<string>;
  public includeFinishedLabel$: Observable<string>;
  public list$: Observable<TodoType[]>;

  public fromDate: string = new TodayDate().getDefaultSearchFromDate();
  public toDate: string = new TodayDate().getDefaultSearchToDate();

  public columns$: Observable<TableColumn[]>;
  private columns: TableColumn[] = [
    { field: 'client.name', header: 'client', width: '11%' },
    { field: 'client.corporateIdentity', header: 'orgNumber', width: '8%' },
    { field: 'client.customerNumber', header: 'customerNumber', width: '7%' },
    { field: 'client.responsible.name', header: 'responsibleUser', width: '9%' },
    { field: 'type', header: 'type', width: '5%' },
    { field: 'assignment', header: 'assignmentName', width: '7%' },
    { field: 'deadline', header: 'deadline', width: '100px' },
    { field: 'text', header: 'notes' },
    { field: 'creator.initials', header: 'createdBy', width: '6%' },
    { field: 'usernames', header: 'executionUser', width: '13%' },
    {
      field: 'status',
      header: 'status',
      width: '170px',
      sortable: false,
      fieldValue: (_fieldValue, rowData) => rowData?.state?.description || '',
    },
    { field: 'action', header: '', width: '40px', sortable: false, selectable: false, exportable: false },
  ];

  public selectedUserId: number = null;
  public includeAllUncompleted = true;
  public includeFinished = true;
  public defaultNumberOfRows = 50;

  public afterDeleteDialogCallback: (cancel?: boolean) => void;
  public additionalFilterFields: string[] | null = null;

  private uss = new UserSettingsStorage();

  constructor(
    private clientService: ClientService,
    private notesService: NotesService,
    private dialogService: DialogService,
  ) {
    super();
    this.statusText$ = this.store.select(ListSelectors.getStatusText);
    this.loading$ = this.store.select(ListSelectors.isLoading);

    this.includeFinishedLabel$ = this.store.select(TodosSelectors.allFilteredTodos).pipe(
      map(({ filteredCompositesCount }) => {
        const label = this.translate('constants.INCLUDE_FINISHED_LABEL', { useComponentScope: false });
        const pieces = this.translate('shared.pieces', { useComponentScope: false });
        return `${label} (${filteredCompositesCount} ${pieces})`;
      }),
    );

    this.list$ = this.store.select(TodosSelectors.allFilteredTodos).pipe(map(({ filteredTodos }) => filteredTodos));
    this.additionalFilterFields = ['state.description'];

    this.columns$ = this.onTranslationChangeMapTo(translateColumnHeaders(this.columns));
  }

  get defaultFromSearchDate() {
    return new TodayDate().getDefaultSearchFromDate();
  }

  get defaultToSearchDate() {
    return new TodayDate().getDefaultSearchToDate();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.loadUserSettings();
    this.initListLoading();
  }

  override ngOnDestroy() {
    this.saveUserSettings();
    super.ngOnDestroy();
  }

  private loadUserSettings() {
    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.TOD_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.fromDate = this.uss.loadSetting(
      UserSettingsStorage.TOD_START_DATE,
      new TodayDate().getDefaultSearchFromDate(),
    );
    this.toDate = this.uss.loadSetting(UserSettingsStorage.TOD_END_DATE, new TodayDate().getDefaultSearchToDate());
    this.includeFinished = this.uss.loadSettingAsBoolean(UserSettingsStorage.TOD_SHOW_FINISHED, true);
    this.includeAllUncompleted = this.uss.loadSettingAsBoolean(UserSettingsStorage.TOD_INCLUDE_UNCOMPLETED, true);
    this.selectedUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.TOD_USER_ID, null);
  }

  private saveUserSettings() {
    try {
      this.uss.saveSetting(UserSettingsStorage.TOD_START_DATE, this.fromDate);
      this.uss.saveSetting(UserSettingsStorage.TOD_END_DATE, this.toDate);
      this.uss.saveSetting(UserSettingsStorage.TOD_USER_ID, `${this.selectedUserId}`);
      this.uss.saveSetting(UserSettingsStorage.TOD_SHOW_FINISHED, `${this.includeFinished}`);
      this.uss.saveSetting(UserSettingsStorage.TOD_INCLUDE_UNCOMPLETED, `${this.includeAllUncompleted}`);
    } catch (e: unknown) {
      // eslint-disable-next-line no-console
      console.error((e as Error).message);
    }
  }

  private initListLoading() {
    this.store
      .select(AuthSelectors.selectAuthUser)
      .pipe(takeFirstTruthy())
      .subscribe((currentUser) => {
        this.selectedUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.TOD_USER_ID, currentUser.id);
      });
  }

  loadList() {
    const todosFilter = {
      includeAllDoneClients: this.includeFinished,
      includeAllUncompleted: this.includeAllUncompleted,
    };
    const params = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      executorId: this.selectedUserId,
    };

    this.store.dispatch(TodosActions.load({ filter: todosFilter, params }));
  }

  filterTodos() {
    const todosFilter = {
      includeAllDoneClients: this.includeFinished,
      includeAllUncompleted: this.includeAllUncompleted,
    };
    this.store.dispatch(ListActions.filterList({ filter: todosFilter }));
  }

  showExistingClient(event: { client: Client }) {
    showClientDialog({
      clientService: this.clientService,
      dialogService: this.dialogService,
      id: event.client.id,
      store: this.store,
    })
      .pipe(first())
      .subscribe((result) => {
        const clientId = result?.client?.id;
        if (clientId && clientId !== -1) {
          this.loadList();
        }
      });
  }

  onStateChanged(stateName: string, todoToUpdate: TodoType) {
    const predicate = (todo: TodoType) => todo.id === todoToUpdate.id;
    this.store.dispatch(TodosActions.updateState({ state: stateName, todo: todoToUpdate, predicate }));
  }

  paginatorTriggered({ rows }: { rows: number }) {
    this.uss.saveSetting(UserSettingsStorage.TOD_NUMBER_OF_ROWS, String(rows));
  }

  showDeleteWarningDialog(todoToDelete: TodoType) {
    openTranslatableDialog({
      deps: {
        dialogService: this.dialogService,
        translationService: this.translationService,
      },
      componentType: RemoveTodoDialogComponent,
      data: {},
    })
      .pipe(filter((result) => Boolean(result?.confirm)))
      .subscribe(() => {
        this.tryToDeleteTodo(todoToDelete);
      });
  }

  showExistingTodo(selectedTodoRow: TodoType) {
    const todo = toTodoTransformer.transform(selectedTodoRow);
    const deps = {
      notesService: this.notesService,
      dialogService: this.dialogService,
      translationService: this.translationService,
    };
    showTodoDialog({ deps, id: todo.id })
      .pipe(filter((result) => Boolean(result?.todo)))
      .subscribe();
  }

  private tryToDeleteTodo(todoToDelete: TodoType) {
    if (!todoToDelete.client) {
      this.dispatchDeleteAction(todoToDelete);
      return;
    }

    this.store
      .select(ClientSelectors.getClientById(todoToDelete.client.id))
      .pipe(take(1))
      .subscribe((client) => {
        if (!client.archived) {
          this.dispatchDeleteAction(todoToDelete);
          return;
        }

        this.store.dispatch(
          ToastActions.showWarnMessage({
            summary: this.translate('archived'),
            detail: this.translate('todoItemBelongsToArchivedClientMessage'),
          }),
        );
      });
  }

  private dispatchDeleteAction(todoToDelete: TodoType) {
    const predicate = (todo: TodoType) => todo.id !== todoToDelete.id;
    this.store.dispatch(TodosActions.todoDelete({ todo: todoToDelete, predicate }));
  }
}
