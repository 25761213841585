import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  DialogData,
  DialogForm,
  DialogResult,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslatableComponent } from './TranslatableComponent';
import { Actions } from '@ngrx/effects';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TranslatableDialogType = TranslatableFormDialogBaseComponent<any, any, any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TranslatableDataType<T extends TranslatableDialogType> =
  T extends TranslatableFormDialogBaseComponent<infer Data, any, any> ? Data : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TranslatableResultType<T extends TranslatableDialogType> =
  T extends TranslatableFormDialogBaseComponent<any, infer Result, any> ? Result : never;

@Component({
  selector: 'app-translatable-dialog-wrapper',
  template: '',
  standalone: true,
  imports: [],
  providers: [DialogService],
})
export abstract class TranslatableFormDialogBaseComponent<
    Data extends DialogData = DialogData,
    Result extends DialogResult = DialogResult,
    Form extends DialogForm<Form> = unknown,
  >
  extends TranslatableComponent
  implements OnInit, OnDestroy
{
  form: FormGroup<Form>;
  protected builder: FormBuilder;
  protected dialogService: DialogService;
  protected actions$: Actions;

  constructor(
    protected ref: DynamicDialogRef,
    protected config: DynamicDialogConfig<Data>,
  ) {
    super();
    this.builder = inject(FormBuilder);
    this.dialogService = inject(DialogService);
    this.actions$ = inject(Actions);

    this.assertNotUseInitDestroy();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.onInitialized();
  }

  override ngOnDestroy(): void {
    this.onDestroyed();
    super.ngOnDestroy();
  }

  /**
   * Called last in base class's ngOnInit
   */
  protected onInitialized = () => {};

  /**
   * Called first in base class's ngOnDestroy
   */
  protected onDestroyed = () => {};

  /**
   * Override the onBeforeClose when you need to preprocess things before closing the dialog with `close`
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onBeforeClose = (_result: Result) => {};

  /**
   * Override the onAfterClose when you need to postprocess things after closing the dialog with `close`
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onAfterClose = (_result: Result) => {};

  /**
   * Use the close function from your derived class when closing your dialog
   */
  protected close = (result: Result) => {
    this.onBeforeClose(result);
    this.ref.close(result);
    this.onAfterClose(result);
    this.form?.reset();
  };

  /**
   * Used when you using the `getDialogConfig` function to get the correct translation scope for the dialog
   * @returns The translation scope for the dialog
   */
  protected static getTranslationScope(): string {
    return null;
  }

  /**
   * Used by the FirmSupport/src/app/shared/misc/openDialog.ts -> openDialog function to extract DialogConfig for a specific Dialog instance
   * Must be overriden or use openDialogWithConfig instead.
   * Can't be used with dynamic- or instance data.
   * @returns Return the dialog configuration that you need to show your dialog
   */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected static getDialogConfig(_translation: Record<string, string>): Omit<DynamicDialogConfig, 'data'> {
    return {};
  }

  private assertNotUseInitDestroy(): void {
    const props = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
    const hasOnInit = props.includes('ngOnInit') && typeof this.ngOnInit === 'function';
    const hasOnDestroy = props.includes('ngOnDestroy') && typeof this.ngOnDestroy === 'function';

    if (hasOnInit || hasOnDestroy) {
      throw new Error(
        'ngOnInit/ngOnDestroy should not be implemented in derived classes of FormDialogBaseComponent, override onInitialized or onDestroyed instead!',
      );
    }
  }
}
