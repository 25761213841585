import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import {
  DialogData,
  DialogForm,
  DialogResult,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { TranslocoModule } from '@jsverse/transloco';
import { InputTextModule } from 'primeng/inputtext';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableFormDialogBaseComponent } from 'src/app/core/i18n/translatable-form-dialog-base.component';

interface Result extends DialogResult {
  email: string;
  password: string;
}

interface Form extends DialogForm<unknown> {
  email: FormControl<string>;
  password: FormControl<string>;
}

interface Data extends DialogData {
  email: string | null;
}

@Component({
  template: `
    <ng-container *transloco="let t; prefix: scope">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" pFocusTrap>
        <p [innerHTML]="t('enterIntegrationText')"></p>

        <label for="email" class="mt-4 mb-0">{{ t('apiEmail') }}</label>
        <input pInputText type="text" formControlName="email" class="w-full" />

        <label for="password" class="mt-6">{{ t('apiPassword') }}</label>
        <input pInputText type="password" formControlName="password" class="w-full" />

        <div class="bg-bl-grey-100 rounded-lg mt-6 p-4">
          <p class="mb-0">{{ t('notCustomerQuestion') }}</p>
          <div [innerHTML]="t('readMoreAboutDueLink')"></div>
        </div>

        <div class="flex flex-row w-full justify-end mt-8" *transloco="let t2; prefix: 'shared'">
          <bl-frontend-button
            type="button"
            variant="secondary"
            (click)="close(null)"
            [text]="t2('cancel')"></bl-frontend-button>
          <bl-frontend-button
            type="submit"
            variant="primary"
            class="ml-2"
            [disabled]="form.invalid"
            [text]="t('activateIntegration')"></bl-frontend-button>
        </div>
      </form>
    </ng-container>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputTextModule, ReactiveFormsModule, BlFrontendButtonComponent, TranslocoModule],
  providers: [getTranslationProvider('ActivateIntegrationDialogComponent')],
})
export class ActivateIntegrationDialogComponent extends TranslatableFormDialogBaseComponent<Data, Result, Form> {
  protected override onInitialized = () => {
    this.form = this.builder.group({
      email: new FormControl(this.config.data.email, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });
  };

  onSubmit() {
    const { email, password } = this.form.value;
    this.close({ email, password });
  }
}
