import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CountryType, isSweden, LanguageType } from '@app/core/entity/locale';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { PartnerProgramSelectors } from '@app/core/state/partnerprogram/partnerprogram.selectors';
import { FirmSettingsDialog } from '@app/firm-settings/firm.settings.dialog';
import { openTranslatableDialog } from '@app/shared/misc/openDialog';
import { TranslocoModule } from '@jsverse/transloco';
import { createPopup } from '@typeform/embed';
import { EmbedPopup } from '@typeform/embed/types/base';
import { MenuItem } from 'primeng/api/menuitem';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { combineLatest, filter, Observable } from 'rxjs';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { FeatureSelectors } from 'src/app/core/state/feature/feature.selectors';
import { UserType } from 'src/app/core/state/types';
import { BlFrontendButtonComponent } from 'src/app/core/wrappers/bl-frontend-button.component';
import { environment } from 'src/environments/environment';
import { HideIfCountriesDirective } from '../../directives/hide-if-countries.directive';
import { DueComplianceTeaserDialogComponent } from '../due-compliance-teaser-dialog/due-compliance-teaser.dialog';
import { PartnerProgramAccessDirective } from '../partner-program-access/partner-program-access.directive';
import { NavbarComponentTranslationType } from './types';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MenuModule,
    ButtonModule,
    BlFrontendButtonComponent,
    HideIfCountriesDirective,
    PartnerProgramAccessDirective,
    TranslocoModule,
  ],
  providers: [getTranslationProvider('NavbarComponent')],
})
export class NavbarComponent extends TranslatableComponent<NavbarComponentTranslationType> implements OnInit {
  @Output() newNote = new EventEmitter<boolean>();
  @Output() settings = new EventEmitter<boolean>();
  @Output() logout = new EventEmitter<boolean>();

  loggedInUser: UserType | undefined;
  helpItems: MenuItem[];
  profileItems: MenuItem[];
  missingPartnerPackages$: Observable<number>;

  private popup: EmbedPopup;
  private isTranslationFeatureActive: boolean;
  private i18n: NavbarComponentTranslationType;

  constructor(private dialogService: DialogService) {
    super();
    this.i18n = {} as NavbarComponentTranslationType;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.store
      .select(AuthSelectors.selectAuthUser)
      .pipe(this.takeUntilDestroyed())
      .subscribe((user) => {
        this.loggedInUser = user;
      });

    combineLatest([
      this.store.select(FirmSelectors.selectFirm),
      this.store.select(AuthSelectors.selectAuthUser),
      this.store.select(FeatureSelectors.selectFeaturesState).pipe(filter((state) => state?.initialized)),
      this.getFirmCountryCodeObservable(),
      this.translationsLoaded$.pipe(filter(Boolean)),
    ])
      .pipe(
        filter(([firm, user]) => Boolean(firm) && Boolean(user)),
        this.takeUntilDestroyed(),
      )
      .subscribe(([firm, user, { features }, firmCountryCode, translations]) => {
        this.isTranslationFeatureActive = features?.i18nSupport;
        this.i18n = translations;
        this.helpItems = this.initHelpItems(firmCountryCode);
        this.profileItems = this.createProfileItems(this.loggedInUser, firmCountryCode);

        this.popup = createPopup(environment.forms.giveFeedback, {
          size: 50,
          hideFooter: true,
          hidden: {
            firmId: String(firm.id),
            userId: String(user.id),
            formType: 'popup',
          },
        });
      });

    this.missingPartnerPackages$ = this.store.select(PartnerProgramSelectors.missingPartnerProgramPackages);
  }

  logoutClicked() {
    this.logout.emit(true);
  }

  get loggedInUserFirm() {
    return this.loggedInUser.firm.name;
  }

  navigateToPartnerProgram() {
    this.router.navigate(['partnerprogram']);
  }

  onDueComplianceClick() {
    openTranslatableDialog({
      deps: {
        dialogService: this.dialogService,
        translationService: this.translationService,
      },
      componentType: DueComplianceTeaserDialogComponent,
      data: {},
    });
  }

  onProfileMenuShow() {
    // because PrimeNG doesn't support a mix of menu items with headers and without, we need to hide the empty headers
    const headers = document.querySelectorAll('#profileMenu li.p-submenu-header');
    headers.forEach((header) => {
      if (header.textContent === '') {
        header?.classList.add('hidden');
      }
    });
  }

  private createProfileItems(user: UserType | undefined, firmCountryCode: CountryType): MenuItem[] {
    if (!user) {
      return [];
    }

    const items: MenuItem[] = [
      {
        label: '',
        items: [
          {
            label: this.i18n.logout,
            command: () => {
              this.logoutClicked();
            },
          },
        ],
      },
    ];

    if (isSweden(firmCountryCode)) {
      return items;
    }

    return [...this.createTranslationItems(this.getActiveLanguage()), ...items];
  }

  private createTranslationItems(activeLang: LanguageType): MenuItem[] {
    if (!this.isTranslationFeatureActive) {
      return [];
    }

    return [
      {
        label: this.i18n.language,
        items: [
          {
            label: this.i18n.english,
            icon: activeLang === 'en' ? 'pi pi-fw pi-check' : null,
            childStyleClass: 'pl-6',
            command: () => {
              this.setActiveLanguage('en');
            },
          },
          {
            label: this.i18n.swedish,
            icon: activeLang === 'sv' ? 'pi pi-fw pi-check' : null,
            childStyleClass: 'pl-6',
            command: () => {
              this.setActiveLanguage('sv');
            },
          },
          {
            label: this.i18n.dutch,
            icon: activeLang === 'nl' ? 'pi pi-fw pi-check' : null,
            childStyleClass: 'pl-6',
            command: () => {
              this.setActiveLanguage('nl');
            },
          },
        ],
      },
      {
        separator: true,
      },
    ];
  }

  private startChat = (): void => {
    if (!window.$kundo_chat) {
      return;
    }

    if (this.loggedInUser) {
      window.$kundo_chat.user = {
        name: `${this.loggedInUser.name} - ${this.loggedInUser.firm?.name} - ${this.loggedInUser.firm?.corporateIdentity}`,
        email: `${this.loggedInUser.email}`,
      };
    }

    window.$kundo_chat.start_chat();
  };

  private getFirmCountryCodeObservable(): Observable<CountryType> {
    return this.store.select(FirmSelectors.selectCountryCode).pipe(filter(Boolean));
  }

  private initHelpItems(countryCode: CountryType): MenuItem[] {
    return isSweden(countryCode) ? this.getSwedishHelpMenuItems() : this.getDutchHelpMenuItems();
  }

  private getSwedishHelpMenuItems(): MenuItem[] {
    return [
      { label: this.i18n.questionsAndAnswers, url: 'https://byrastodsforum.blinfo.se/org/bl-byrastod/' },
      { label: this.i18n.manual, url: 'https://support.bjornlunden.se/category/bl-byrastod' },
      {
        label: this.i18n.filmedGuides,
        url: 'https://www.youtube.com/playlist?list=PL009yjRM5nBhrjxSv7xa3fvehLRNf0kZH',
      },
      {
        label: this.i18n.chatWithSupport,
        command: () => this.startChat(),
      },
      { label: this.i18n.remoteSupport, url: 'https://download.blinfo.se/v2/public/fjarrsupport.exe' },
      { label: this.i18n.facebookUserGroup, url: 'https://www.facebook.com/groups/bjornlunden/' },
      {
        separator: true,
      },
      {
        label: this.i18n.downloadProgram,
        command: () =>
          openTranslatableDialog({
            deps: { dialogService: this.dialogService, translationService: this.translationService },
            componentType: FirmSettingsDialog,
            data: { tabIdToOpen: 'tabFirmSystemSettings' },
          }),
      },
      {
        separator: true,
      },
      {
        label: this.i18n.giveFeedback,
        command: () => {
          this.popup.open();
        },
      },
    ];
  }

  private getDutchHelpMenuItems(): MenuItem[] {
    return [
      { label: this.i18n.manual, url: 'https://support.nl.bjornlunden.com/' },
      { label: this.i18n.remoteSupport, url: 'https://download.blinfo.se/v2/public/fjarrsupport.exe' },
    ];
  }
}
