import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { AppState } from 'src/app/core/state/appState';
import { AssignmentSelectors } from 'src/app/core/state/assignments/assignments.selectors';
import { AssignmentType } from 'src/app/core/state/types';

@Pipe({
  name: 'pickAssignmentName',
  standalone: true,
})
export class PickAssignmentNamePipe implements PipeTransform {
  private store = inject(Store<AppState>);

  transform(value: AssignmentType) {
    if (!value) {
      return null;
    }
    return this.store
      .select(AssignmentSelectors.selectAssignments)
      .pipe(map((assignments) => assignments.find((t) => t.label === value?.label)?.name ?? value?.name));
  }
}
