import { Directive, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppState } from '@app/core/state/appState';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

@Directive({
  selector: '[appPartnerProgramAccess]',
  standalone: true,
})
export class PartnerProgramAccessDirective implements OnChanges {
  private partnerProgram: boolean;

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<unknown>,
    private store: Store<AppState>,
  ) {
    this.store
      .select(AuthSelectors.selectAuthUser)
      .pipe(filter((user) => Boolean(user)))
      .subscribe((user) => {
        this.partnerProgram = user.partnerProgram;
        this.ngOnChanges();
      });
  }

  ngOnChanges(): void {
    if (this.partnerProgram) {
      this.view.clear();
      this.view.createEmbeddedView(this.template);
      return;
    }

    this.view.clear();
  }
}
