import { Translatable } from 'src/app/core/i18n/types';

export const showArchiveWarningIfRecentlyUsed = (lastUsed: string): boolean => {
  if (!lastUsed) {
    return false;
  }
  const lastUsedDate = new Date(lastUsed);
  const currentDate = new Date();
  const diffInDays = Math.floor((currentDate.getTime() - lastUsedDate.getTime()) / (1000 * 60 * 60 * 24));
  return diffInDays < 90;
};

export const archiveWarningMessage = (translateFn: Translatable['translate'], lastUsed: string): string =>
  `<div class="mt-3">${translateFn('archiveWarningMessage', { params: { lastUsed } })}</div>`;
