import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogData } from '../form-dialog-base/form-dialog-base.component';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { openTranslatableDialog } from '../../misc/openDialog';
import { FirmSettingsDialog } from 'src/app/firm-settings/firm.settings.dialog';
import { TranslatableFormDialogBaseComponent } from 'src/app/core/i18n/translatable-form-dialog-base.component';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslocoModule } from '@jsverse/transloco';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

const TRANSLATION_SCOPE = 'dueComplianceTeaserDialogComponent';

@Component({
  selector: 'app-due-compliance-teaser-dialog',
  template: `
    <ng-container *transloco="let t; prefix: this.scope">
      <div>
        <p>
          {{ t('text1') }}
        </p>
        <div class="px-8 py-2">
          <ul class="list-disc">
            <li [innerHTML]="t('text2') | safeHtml"></li>
            <li [innerHTML]="t('text3') | safeHtml" (click)="openFirmSettings($event)"></li>
            <li [innerHTML]="t('text4') | safeHtml"></li>
          </ul>
        </div>
      </div>
    </ng-container>
    <div class="flex mt-4 justify-end">
      <bl-frontend-button
        [text]="'shared.close' | transloco"
        variant="primary"
        (click)="close({})"></bl-frontend-button>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, BlFrontendButtonComponent, TranslocoModule, SafeHtmlPipe],
  providers: [DialogService, getTranslationProvider(TRANSLATION_SCOPE)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DueComplianceTeaserDialogComponent extends TranslatableFormDialogBaseComponent {
  openFirmSettings(event: MouseEvent): void {
    const isAnchoreElement = event.target instanceof HTMLAnchorElement;

    if (!isAnchoreElement) {
      return;
    }

    openTranslatableDialog({
      deps: { dialogService: this.dialogService, translationService: this.translationService },
      componentType: FirmSettingsDialog,
      data: { tabIdToOpen: 'tabFirmSystemSettings' },
    });
  }

  protected static override getTranslationScope(): string {
    return TRANSLATION_SCOPE;
  }

  protected static override getDialogConfig(
    translation: Record<string, string>,
  ): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    return {
      header: translation.title,
      width: '700px',
    };
  }
}
