import { RadioButtonProps } from '@bl/components';
import {
  ActionType,
  ClientActionType,
  CollaborationPackageItem,
} from '@app/core/state/types/collaboration-package.types';
import { Translatable } from 'src/app/core/i18n/types';

type TranslateFunction = Translatable['translate'];

const getBoldText = (text: string): string => `<span class="font-semibold">${text}</span>`;

const getOnboardRadioItem = (translateFn: TranslateFunction): RadioButtonProps => ({
  name: 'onboard',
  value: ActionType.ONBOARD,
  text: translateFn('selectCollaborationPackage'),
  useUnsafeHtml: true,
  description: translateFn('selectCollaborationPackageDescription', {
    params: {
      proText: getBoldText('Pro'),
      essentialText: getBoldText('Essential'),
      goText: getBoldText('Go'),
      miniText: getBoldText('Mini'),
    },
  }),
});

const getOnboardMiniRadioItem = (translateFn: TranslateFunction): RadioButtonProps => ({
  name: 'onboardWithMini',
  value: ActionType.ONBOARD_WITH_MINI,
  text: translateFn('selectMiniPackage'),
  useUnsafeHtml: true,
  description: translateFn('selectMiniPackageInfoText'),
});

const getChangePackageRadioItem = (translateFn: TranslateFunction): RadioButtonProps => ({
  name: 'changePackageOnNewSubscription',
  value: ActionType.CHANGE_PACKAGE_ON_NEW_SUBSCRIPTION,
  text: translateFn('changeInCollaborationPackage'),
  description: translateFn('changeInCollaborationPackageInfoText'),
});

const getArchiveRadioItem = (
  translateFn: TranslateFunction,
  subscriptionTypeId: number | null,
  archivedTo: string | null,
): RadioButtonProps => {
  const notArchivedDescription = translateFn('notArchivedInfoText');
  const archivedWithBlappDescription = translateFn('archivedWithBlappInfoText', { params: { archivedTo } });
  const archivedWithoutBlappDescription = translateFn('archivedWithoutBlappInfoText', { params: { archivedTo } });

  let description = notArchivedDescription;
  if (archivedTo) {
    description = subscriptionTypeId ? archivedWithBlappDescription : archivedWithoutBlappDescription;
  }

  return {
    name: 'archive',
    value: ActionType.ARCHIVE,
    text: translateFn('archiveDatabase'),
    description,
    useUnsafeHtml: true,
    disabled: !!archivedTo,
  };
};

const getUnarchiveRadioItem = (translateFn: TranslateFunction, archivedTo: string | null): RadioButtonProps => ({
  name: 'unarchive',
  value: ActionType.UNARCHIVE,
  text: translateFn('unarchiveDatabase'),
  description: translateFn('unarchiveDatabaseInfoText', { params: { archivedTo } }),
  useUnsafeHtml: true,
});

const getDeleteRadioItem = (translateFn: TranslateFunction): RadioButtonProps => ({
  name: 'delete',
  value: ActionType.DELETE,
  text: translateFn('deleteDatabase'),
  description: translateFn('deleteDatabaseInfoText'),
});

// eslint-disable-next-line complexity
export const initializeActionRadioButtons = (
  config: CollaborationPackageItem,
  translateFn: TranslateFunction,
): RadioButtonProps[] => {
  const { subscription, archivedTo, canUseOnboarding, canUseSimpleOnboarding, canChangePackage } = config;
  const { subscriptionTypeId } = subscription || {};
  const formattedArchivedTo = archivedTo ? new Date(archivedTo).toLocaleDateString() : null;
  const radioButtons = [getDeleteRadioItem(translateFn)];

  if (!archivedTo) {
    radioButtons.unshift(getArchiveRadioItem(translateFn, subscriptionTypeId, formattedArchivedTo));
  }

  if (archivedTo) {
    radioButtons.unshift(getUnarchiveRadioItem(translateFn, formattedArchivedTo));
  }

  // Adds the onboard to just "mini" package option if the cloud company doesn't have a blapp package or has whitelisted packages
  if (canUseSimpleOnboarding) {
    radioButtons.unshift(getOnboardMiniRadioItem(translateFn));
  }

  // Adds the onboard option if the cloud company doesn't have a blapp package or
  // subscription type is old firm package
  if (canUseOnboarding) {
    radioButtons.unshift(getOnboardRadioItem(translateFn));
  }

  // Adds the change package option if the cloud company has the new firm package
  if (canChangePackage) {
    radioButtons.unshift(getChangePackageRadioItem(translateFn));
  }

  // mark the first non disabled item as checked as default
  radioButtons.filter((rb) => !rb.disabled)[0].checked = true;
  return radioButtons;
};

const getSkipClientRadioItem = (translateFn: TranslateFunction): RadioButtonProps => ({
  name: 'skip',
  checked: true,
  value: ClientActionType.SKIP,
  text: translateFn('noLeaveClientAsIs'),
});

const getArchiveClientRadioItem = (translateFn: TranslateFunction): RadioButtonProps => ({
  name: 'archive',
  value: ClientActionType.ARCHIVE,
  text: translateFn('archiveClientInFirmSupport'),
});

const getDeleteClientRadioItem = (deleteable: boolean, translateFn: TranslateFunction): RadioButtonProps => ({
  name: 'delete',
  value: ClientActionType.DELETE,
  text: translateFn('deleteClientInFirmSupport'),
  description: deleteable ? '' : translateFn('clientHasSavedActivities'),
  disabled: !deleteable,
});

// radio buttons for action on client in Byråstöd
export const initializeClientActionRadioButtons = (
  deleteable: boolean,
  translateFn: TranslateFunction,
): RadioButtonProps[] => [
  getSkipClientRadioItem(translateFn),
  getArchiveClientRadioItem(translateFn),
  getDeleteClientRadioItem(deleteable, translateFn),
];
