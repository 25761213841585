import { Injectable } from '@angular/core';
import { TranslocoConfig, TranslocoMissingHandler } from '@jsverse/transloco';
import { LOCAL_STORAGE_SHOW_KEYS_KEY } from './constants';

@Injectable()
export class DefaultMissingHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    const showKeys = localStorage.getItem(LOCAL_STORAGE_SHOW_KEYS_KEY) || false;
    if (showKeys) {
      return key;
    }

    if (config.missingHandler.logMissingKey && !config.prodMode) {
      const msg = `Missing translation for '${key}'`;
      console.warn(`%c ${msg}`, 'font-size: 12px; color: red');
    }

    return key;
  }
}
