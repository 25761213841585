import { Pipe, PipeTransform } from '@angular/core';
import { AmlRisk } from '@app/core/state/types/aml.types';
import { ComponentVariant } from '@bl/components';

@Pipe({
  name: 'amlRiskVariantType',
  standalone: true,
})
export class AmlRiskVariantTypePipe implements PipeTransform {
  transform(value: AmlRisk | null): ComponentVariant {
    switch (value) {
      case AmlRisk.LOW:
        return 'success';
      case AmlRisk.NORMAL:
        return 'warning';
      case AmlRisk.HIGH:
        return 'danger';
      default:
        return 'success';
    }
  }
}
