import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { AppState } from 'src/app/core/state/appState';
import { todoStatesFeature } from 'src/app/core/state/todo-states/todo-states.reducers';
import { NameDescriptionType } from 'src/app/core/state/types';

@Pipe({
  name: 'pickTodoTypeDescription',
  standalone: true,
})
export class PickTodoTypeDescriptionPipe implements PipeTransform {
  private store = inject(Store<AppState>);

  transform(value: NameDescriptionType) {
    if (!value) {
      return null;
    }
    return this.store
      .select(todoStatesFeature.selectTypes)
      .pipe(map((types) => types.find((t) => t.name === value?.name)?.description ?? value?.description));
  }
}
