<ng-content select="app-table-filter"></ng-content>

<p-table
  #ptable
  [value]="data"
  [columns]="visibleColumns"
  [rows]="paginator ? rowsPerPage : null"
  [rowsPerPageOptions]="[25, 50, 100, 250, 500, 1000]"
  [paginator]="paginator"
  [reorderableColumns]="!!storageKey"
  [resizableColumns]="true"
  [exportFilename]="exportFilename"
  [csvSeparator]="';'"
  [sortField]="sortFieldKey"
  (onColReorder)="onColReorder($event)"
  (onColResize)="onColResize($event)"
  (onPage)="pageChange.emit($event)"
  [scrollable]="isScrollable"
  [scrollHeight]="scrollHeightValue"
  [style]="maxHeightStyle"
  [tableStyle]="tableStyle"
  [globalFilterFields]="globalFilterFields"
>
  <ng-container *ngIf="searchField || columnSelector">
    <ng-template pTemplate="caption">
      <div class="flex justify-between">
        <div *ngIf="searchField" class="flex items-end">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              type="text"
              pInputText
              [placeholder]="'shared.search' | transloco"
              (input)="ptable.filterGlobal($any($event.target).value, 'contains')"
            />
          </span>
          <div *ngIf="checkboxField" class="ml-4">
            <p-checkbox
              [binary]="true"
              [(ngModel)]="showOnlyActiveTemplates"
              (ngModelChange)="checkboxChange.emit($event)"
              [label]="checkboxLabel"
            ></p-checkbox>
          </div>
        </div>
        <div *ngIf="columnSelector">
          <p-multiSelect
            [options]="columnSelectorOptions"
            [showHeader]="false"
            [(ngModel)]="visibleSelectorOptions"
            optionLabel="header"
            dataKey="field"
            [selectedItemsLabel]="'shared.numberSelectedColumns' | transloco | transformParam:'count'"
            appendTo="body"
            (onPanelHide)="onPanelHide()"
            [scrollHeight]="columnPickerScrollHeight"
            [autoOptionFocus]="false">
          </p-multiSelect>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <div class="my-3">
          <ng-container *ngTemplateOutlet="getTemplateRef('emptyMessage')"></ng-container>
        </div>
      </td>
    </tr>
  </ng-template>
  <!-- // todo - Se över det här i nyare angular, om template och ngtemplateoutlet funkar bättre -->
  <ng-template pTemplate="header" let-columns>
    <ng-container *ngIf="customHeader">
      <tr *ngFor="let row of customHeader">
        <th
          *ngFor="let col of row.columns"
          [attr.colspan]="col.colspan"
          [attr.rowspan]="col.rowspan"
          [ngClass]="col.class"
          [ngStyle]="getColumnStyle(col)"
        >
          {{ col.header }}
        </th>
      </tr>
    </ng-container>
    <tr *ngIf="!customHeader">
      <th
        *ngFor="let col of columns"
        class="{{col.class ?? 'font-normal truncate'}}"
        [ngStyle]="getColumnStyle(col)"
        [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="col.sortable === false"
        pResizableColumn
        pReorderableColumn
      >
        {{ col.header }}
        <p-sortIcon *ngIf="col.sortable !== false" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
    <ng-container
      *ngTemplateOutlet="defaultTableBodyRow; context: { $implicit: rowData, columns: columns, rowIndex: rowIndex }"
    ></ng-container>
  </ng-template>

  <ng-container *ngIf="getTemplateRef('summary') as summaryTemplate">
    <ng-template pTemplate="summary">
      <ng-container *ngTemplateOutlet="summaryTemplate; context: { $implicit: data }"></ng-container>
    </ng-template>
  </ng-container>
</p-table>

<!-- // table row template -->
<ng-template #defaultTableBodyRow let-rowData let-columns="columns" let-rowIndex="rowIndex">
  <tr (click)="rowClick.emit({ columns: columns, data: rowData })" [ngClass]="rowClass">
    <td
      *ngFor="let col of columns"
      [ngStyle]="getColumnStyle(col)"
      [style]="col.cellStyle"
      (click)="cellClick.emit({ column: col, data: rowData })"
    >
      <ng-container
        *ngTemplateOutlet="
          getCellTemplateRefForColumn(col);
          context: { $implicit: getCellData(rowData, col), column: col, rowData: rowData, rowIndex: rowIndex }
        "
      ></ng-container>
    </td>
  </tr>
</ng-template>

<!-- // table cell template -->
<ng-template #defaultTableBodyCell let-content>{{ content }}</ng-template>
