import { createSelector } from '@ngrx/store';
import { AssignmentType, TaskType } from '../types';
import { AppState } from '../appState';
import { i18nFeature } from '../i18n/i18n.reducers';
import { TranslationConstants } from '../../i18n/constants';

const assignments = (state: AppState) => state.assignments;

const assignmentsWithChoose = createSelector(assignments, i18nFeature.selectConstants, (state, translations) => {
  const choose: AssignmentType = {
    ...initialAssignmentType,
    name: translations[TranslationConstants.SELECT_WITH_HYPHENS],
  };
  return [choose].concat(state.assignments);
});

const selectAssignments = createSelector(assignments, (state) => state.assignments);

export const initialAssignmentType: AssignmentType = {
  id: null,
  label: '',
  name: '',
  rootTemplates: [],
  task: {} as TaskType,
};

export const AssignmentSelectors = {
  assignmentsWithChoose,
  selectAssignments,
};
