export type CountryType = 'NL' | 'SE';
export type LanguageType = 'en' | 'nl' | 'sv';

export enum Country {
  NL = 'NL',
  SE = 'SE',
}

export enum Language {
  EN = 'en',
  NL = 'nl',
  SV = 'sv',
}

export const isSweden = (country: CountryType) => country === Country.SE;
export const isNetherlands = (country: CountryType) => country === Country.NL;
