import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CountryType } from '@app/core/entity/locale';
import { asArray } from '@app/core/misc/as-array';
import { AppState } from '@app/core/state/appState';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { Store } from '@ngrx/store';
import { combineLatest, filter, Observable, Subject } from 'rxjs';

@Directive({
  selector: '[appHideIfCountries]',
  standalone: true,
})
export class HideIfCountriesDirective {
  @Input() set appHideIfCountries(countries: CountryType | CountryType[]) {
    this.hideForCountriesSubject.next(asArray(countries));
  }

  private hideForCountriesSubject: Subject<CountryType[]>

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private store: Store<AppState>,
  ) {
    this.hideForCountriesSubject = new Subject<CountryType[]>();
    this.handleVisibilityByCountries()
  }

  private handleVisibilityByCountries(): void {
    combineLatest([this.getCurrentCountry(), this.hideForCountriesSubject])
      .pipe(takeUntilDestroyed())
      .subscribe(([firmCountry, hideForCountries]) => {
        this.viewContainerRef.clear();

        if (!hideForCountries.includes(firmCountry)) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      });
  }

  private getCurrentCountry(): Observable<CountryType> {
    return this.store.select(FirmSelectors.selectCountryCode).pipe(filter(Boolean));
  }
}
