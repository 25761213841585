import { Component, ContentChildren, QueryList, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { NavigationEnd } from '@angular/router';
import { TabComponent } from './tab.component';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';

@Component({
  selector: 'app-tabs',
  template: `
    <div class="mb-6 mt-2">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" (click)="selectTab(tab)" [class.active]="tab.active">
          <a *ngIf="tab.route" routerLink="{{ tab.route }}" routerLinkActive="active">
            <div class="relative">
              <span class="relative">{{ tab.tabTitle }}</span>

              <ng-container *ngIf="tab.notificationBadge !== null">
                <div
                  class="flex absolute pin-r pin-t -mr-2 rounded-full text-white text-xs bg-bl-orange-500 h-5 w-5 justify-center items-center -top-2 -right-4">
                  {{ tab.notificationBadge }}
                </div>
              </ng-container>
              <ng-container *ngIf="tab.newFeature">
                <div
                  [ngStyle]="{ opacity: tab.newFeature ? '1' : '0' }"
                  class="absolute -mr-2 rounded-full text-white text-xs bg-bl-orange-500 -top-2 -right-8 px-1">
                  <ng-container *transloco="let t; prefix: this.scope">{{ t('news') }}</ng-container>
                </div>
              </ng-container>
            </div>
          </a>
          <a
            *ngIf="!tab.route"
            class="tab"
            tooltipPosition="bottom"
            [pTooltip]="tab.disabled ? tab.tabTooltip : undefined"
            [ngClass]="{ disabled: tab.disabled }">
            {{ tab.tabTitle }}
            <ng-container *ngIf="tab.newFeature">
              <div
                [ngStyle]="{ opacity: tab.newFeature ? '1' : '0' }"
                class="absolute rounded-full text-white text-xs bg-bl-orange-500 -top-2 -right-6 px-1">
                <ng-container *transloco="let t; prefix: this.scope">{{ t('news') }}</ng-container>
              </div>
            </ng-container>
          </a>
        </li>
      </ul>
    </div>
    <ng-content></ng-content>
  `,
  styles: [
    `
      .tab {
        cursor: pointer;
      }
      .disabled {
        color: darkgray;
        cursor: not-allowed;
      }
    `,
  ],
  providers: [getTranslationProvider('TabsComponent')],
})
export class TabsComponent extends TranslatableComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Output() tabChange: EventEmitter<number> = new EventEmitter<number>();

  public unfinishedTodos$: Observable<number>;
  public notifications$: Observable<number>;
  public showNotifications$: Observable<boolean>;

  private delayedTabSelection: string = null;

  constructor() {
    super();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        this.takeUntilDestroyed(),
      )
      .subscribe((event: NavigationEnd) => {
        this.delayedTabSelection = null;

        if (!this.tabs) {
          this.delayedTabSelection = event.url;
        }

        if (this.tabs?.some((tab) => Boolean(tab.route))) {
          this.clearActiveTabs();
          this.selectTab(this.tabs.find((tab) => tab.route === event.url));
        }
      });
  }

  get currentTabIndex() {
    return this.tabs.toArray().findIndex((t) => t.active);
  }

  ngAfterContentInit() {
    const activeTab = this.tabs.find((tab) => tab.active || tab.route === this.delayedTabSelection);
    // delay tab selection so we don't get ExpressionChangedAfterItHasBeenCheckedError when render the tabs
    setTimeout(() => {
      this.selectTab(activeTab || this.tabs.first);
    }, 0);
  }

  selectTabById(tabId: string) {
    setTimeout(() => {
      this.selectTab(this.findTabBy('tabId', tabId));
    }, 20);
  }

  // @deprecated - use the selectTabById method instead
  selectTabByTitle(tabTitle: string) {
    setTimeout(() => {
      this.selectTab(this.findTabBy('tabTitle', tabTitle));
    }, 20);
  }

  selectFirst() {
    if (this.tabs && this.tabs.length > 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: TabComponent) {
    if (!tab || tab.disabled) {
      return;
    }

    this.clearActiveTabs();
    tab.active = true;

    this.tabChange.emit(this.currentTabIndex);
  }

  private clearActiveTabs() {
    this.tabs.toArray().forEach((tab) => {
      tab.active = false;
    });
  }

  private findTabBy(prop: 'tabId' | 'tabTitle', value: string) {
    return this.tabs.find((tab) => tab[prop] === value);
  }
}
