import { HasWithParams } from './types';

export class TranslatableString extends String implements HasWithParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  withParams(params: Record<string, any>): string {
    const value = this.toString();
    const replaced = value.replace(/<<([^>>]*)>>/g, (match, key) => params[key] || match);
    return new TranslatableString(replaced).toString();
  }
}
