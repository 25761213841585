<ng-container *transloco="let t; prefix: this.scope">
    <app-tabs>
    <app-tab [tabTitle]="t('start')" route="/start"></app-tab>
    <app-tab route="/activities" [tabTitle]="t('allActivities')" *appHideIfCountries="'NL'"></app-tab>
    <app-tab route="/accounting" [tabTitle]="t('accounting')" *appHideIfCountries="'NL'"></app-tab>
    <app-tab route="/salaries" [tabTitle]="t('salaries')" *appHideIfCountries="'NL'"></app-tab>
    <app-tab route="/taxdeclaration" [tabTitle]="t('taxDeclaration')" *appHideIfCountries="'NL'"></app-tab>
    <app-tab route="/closing" [tabTitle]="t('annualReport')" *appHideIfCountries="'NL'"></app-tab>
    <app-tab route="/incometaxdeclaration" [tabTitle]="t('incomeTaxReturn')" *appHideIfCountries="'NL'"></app-tab>
    <app-tab route="/incomestatement" [tabTitle]="t('controlReports')" *appHideIfCountries="'NL'"></app-tab>
    <app-tab route="/todo" [tabTitle]="t('todos')" [notificationBadge]="unfinishedTodos$ | async"></app-tab>
    <app-tab route="/notifications" [tabTitle]="t('notifications')"></app-tab>
    <app-tab route="/clients" [tabTitle]="t('clients')"></app-tab>
    <app-tab route="/collaborationpackages" [tabTitle]="t('collaborationPackages')" *appShowIfBlFirmService [notificationBadge]="numberOfMissingCollaborationPackages$ | async"></app-tab>
    <app-tab route="/advisorytool" [tabTitle]="t('advisoryTools')" [notificationBadge]="advisoryNotification$ | async" *appHideIfCountries="'NL'"></app-tab>
    <app-tab route="/sustainability" [tabTitle]="t('sustainabilityReporting')" *appHideIfCountries="'NL'"></app-tab>
  </app-tabs>
</ng-container>

<router-outlet></router-outlet>