import { inject, Injectable } from '@angular/core';
import { HashMap, TranslocoService } from '@jsverse/transloco';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LanguageType } from '../entity/locale';
import { AppState } from '../state/appState';
import { TranslatableString } from './TranslatableString';
import { TranslatableStringType } from './types';
import { translate } from './utils';
import { i18nActions } from '../state/i18n/i18n.actions';

@Injectable()
export abstract class TranslatableEffect {
  protected actions$: Actions;
  protected store: Store<AppState>;
  protected scope: string;
  protected translationService: TranslocoService;
  private translationsInitialized = false;

  constructor(serviceName: string) {
    this.scope = serviceName.charAt(0).toLowerCase() + serviceName.slice(1);
    this.actions$ = inject(Actions);
    this.store = inject(Store);
    this.translationService = inject(TranslocoService);
  }

  protected onTranslationsLoaded(): void {}

  getActiveLanguage = (): string => this.translationService.getActiveLang();

  setActiveLanguage = (lang: LanguageType): void => {
    this.store.dispatch(i18nActions.languageChange({ languageCode: lang }));
  };

  translate = (
    key: string,
    options: {
      params?: HashMap;
      useComponentScope?: boolean;
    } = { useComponentScope: true },
  ): TranslatableStringType => {
    const { scope, translationService, translationsInitialized } = this;
    const translated = translate(key, { scope, translationService, translationsInitialized }, options);
    return new TranslatableString(translated) as TranslatableStringType;
  };

  get selectedTranslation$() {
    return this.translationService.selectTranslation(this.scope);
  }
}
