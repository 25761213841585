/* eslint-disable @typescript-eslint/no-extraneous-class */
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import {
  DefaultFallbackStrategy,
  DefaultTranspiler,
  provideTransloco,
  provideTranslocoFallbackStrategy,
  provideTranslocoInterceptor,
  provideTranslocoMissingHandler,
  provideTranslocoTranspiler,
  TranslocoModule,
} from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { TranslocoHttpLoader } from './core/i18n/TranslocoHttpLoader';
import { DefaultMissingHandler } from './core/i18n/DefaultMissingHandler';
import { DefaultInterceptor } from './core/i18n/DefaultInterceptor';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['sv', 'en', 'nl'],
        defaultLang: 'sv',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoTranspiler(DefaultTranspiler),
    provideTranslocoMissingHandler(DefaultMissingHandler),
    provideTranslocoInterceptor(DefaultInterceptor),
    provideTranslocoFallbackStrategy(DefaultFallbackStrategy),
    provideTranslocoMessageformat({
      locales: ['sv', 'en', 'nl'],
    }),
  ],
})
export class TranslocoRootModule {}
