import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TaskService } from '@app/core/services/task.service';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { TaskType } from '@app/core/state/types';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { TranslocoModule } from '@jsverse/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { TranslatableStringType } from 'src/app/core/i18n/types';

interface TeamTaskListComponentTranslation {
  assignment: TranslatableStringType;
  taskType: TranslatableStringType;
}

@Component({
  selector: 'app-team-task-list',
  templateUrl: './team-task-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BystSharedModule, TranslocoModule],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TeamTaskListComponent), multi: true },
    getTranslationProvider('TeamTaskListComponent'),
  ],
})
export class TeamTaskListComponent
  extends TranslatableComponent<TeamTaskListComponentTranslation>
  implements ControlValueAccessor
{
  tasks$: Observable<TaskType[]>;
  columns$: Observable<TableColumn[]>;

  selectedTasks: TaskType[];
  showOnlySelectedRows = false;

  onChange: ControlValueAccessorFn = () => {};
  onTouched: ControlValueAccessorFn = () => {};

  private showOnlySelectedRowsBS = new BehaviorSubject<boolean>(false);

  get filterFields$() {
    return this.columns$.pipe(map((columns) => columns.map((column) => column.field)));
  }

  get sortField$() {
    return this.columns$.pipe(map((columns) => columns[0]?.field));
  }

  constructor(
    private taskService: TaskService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();

    this.columns$ = this.getTranslatedColumns();

    this.tasks$ = this.getTasksObservable();
  }

  writeValue(tasks: TaskType[]): void {
    this.selectedTasks = tasks;
    this.changeDetectorRef.markForCheck();
    // reset checkbox and filter
    this.showOnlySelectedRowsBS.next(false);
    this.showOnlySelectedRows = false;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  refilterTasks(event?: boolean) {
    const showOnlySelectedRows = typeof event === 'boolean' ? event : this.showOnlySelectedRowsBS.value;
    this.showOnlySelectedRowsBS.next(showOnlySelectedRows);
  }

  private getTranslatedColumns(): Observable<TableColumn[]> {
    return this.translationsLoaded$.pipe(
      map((translations) => [
        { field: 'assignmentName', header: translations?.assignment || '' },
        { field: 'type', header: translations?.taskType || '' },
      ]),
    );
  }

  private getTasksObservable(): Observable<TaskType[]> {
    return this.taskService.getActiveTaskTypeTemplates().pipe(
      switchMap((tasks) =>
        this.showOnlySelectedRowsBS.pipe(
          map((showOnlySelectedRows) => {
            if (!showOnlySelectedRows) return tasks;
            const filteredTaskIds = this.selectedTasks.map((task) => task.id);
            return tasks.filter((task) => filteredTaskIds.includes(task.id));
          }),
        ),
      ),
    );
  }
}
