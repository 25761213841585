<ng-container *transloco="let t; prefix: scope">
  <app-table
    #table
    storageKey="notificationsTable"
    [columns]="columns$ | async"
    [data]="todos"
    [rowsPerPage]="defaultNumberOfRows"
    [exportFilename]="t('notifications')"
    (pageChange)="paginatorTriggered($event)">
    <app-table-filter>
      <app-team-multi-selector
        localStorageKey="notifications"
        (OnTeamsInit)="initEverything()"></app-team-multi-selector>
      <app-table-filter-item [label]="t('responsibleUser')">
        <app-user-selector [(userId)]="selectedResponsibleUserId" styleClass="min-w-48"></app-user-selector>
      </app-table-filter-item>
      <app-table-filter-item>
        <div class="ml-4">{{ t('showDataForPeriod') }}:</div>
      </app-table-filter-item>
      <app-table-filter-item [label]="t('dateFrom')">
        <app-calendar
          [(selectedDate)]="startDate"
          [style]="{ 'max-width': '150px' }"
          [defaultDate]="defaultStartDate"></app-calendar>
      </app-table-filter-item>
      <app-table-filter-item [label]="t('to')">
        <app-calendar [(selectedDate)]="endDate" [style]="{ 'max-width': '150px' }"></app-calendar>
      </app-table-filter-item>
      <app-table-filter-item>
        <app-refresh-button
          [loading]="loading"
          [disabled]="!isFilterDatesValid"
          (onClick)="loadTodoInformation(true)"></app-refresh-button>
      </app-table-filter-item>
      <app-table-filter-item>
        <div style="font-size: 12px">{{ t('lastUpdated') }}: {{ lastUpdatedDate | date: 'yyyy-MM-dd HH:mm' }}</div>
      </app-table-filter-item>
      <app-table-filter-item>
        <p-checkbox
          [binary]="true"
          [ngModel]="onlyShowRowsWithContent"
          (ngModelChange)="onOnlyShowRowsWithContentChange($event)"
          [label]="onlyShowRowsWithContentLabel"
          [disabled]="loading"></p-checkbox>
      </app-table-filter-item>
    </app-table-filter>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="client.customerNumber" let-rowData="rowData">
      <ng-container
        *ngTemplateOutlet="
          defaultClientInfoCell;
          context: { $implicit: rowData, content: rowData.client.customerNumber }
        "></ng-container>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="client.corporateIdentity" let-rowData="rowData">
      <ng-container
        *ngTemplateOutlet="
          defaultClientInfoCell;
          context: { $implicit: rowData, content: rowData.client.corporateIdentity }
        "></ng-container>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="client.name" let-rowData="rowData">
      <ng-container
        *ngTemplateOutlet="
          defaultClientInfoCell;
          context: { $implicit: rowData, content: rowData.client.name }
        "></ng-container>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.UnhandledDocuments" let-rowData="rowData">
      <ng-container
        *ngTemplateOutlet="
          agentCallCell;
          context: { $implicit: rowData, content: rowData.todo.UnhandledDocuments, task: 'REGI' }
        "></ng-container>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.UnpaidSupplierInvoices" let-rowData="rowData">
      <ng-container
        *ngTemplateOutlet="
          agentCallCell;
          context: { $implicit: rowData, content: rowData.todo.UnpaidSupplierInvoices, task: 'BETF' }
        "></ng-container>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.PaymentsToRemediate" let-rowData="rowData">
      <ng-container
        *ngTemplateOutlet="
          blappCallCell;
          context: { $implicit: rowData, content: rowData.todo.PaymentsToRemediate }
        "></ng-container>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.AutoRegisteredInvoice" let-rowData="rowData">
      <ng-container
        *ngTemplateOutlet="
          agentCallCell;
          context: { $implicit: rowData, content: rowData.todo.AutoRegisteredInvoice, task: 'LEVF' }
        "></ng-container>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.AutoRegisteredReceipt" let-rowData="rowData">
      <ng-container
        *ngTemplateOutlet="
          agentCallCell;
          context: { $implicit: rowData, content: rowData.todo.AutoRegisteredReceipt, task: 'BOKV' }
        "></ng-container>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.UncoupledQredTransactions" let-rowData="rowData">
      <p
        (click)="isLoggedInWithAllInclusiveService ? openBlappInNewTab(rowData.client) : undefined"
        [ngClass]="isLoggedInWithAllInclusiveService ? 'hover:underline cursor-pointer' : 'cursor-text'"
        class="text-black mb-0">
        {{ rowData.todo.UncoupledQredTransactions }}
      </p>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="todo.TaxAccount" let-rowData="rowData">
      <ng-container [ngSwitch]="rowData?.todo?.TaxAccount">
        <div
          *ngSwitchCase="'INACTIVE'"
          (click)="selectedClient = rowData?.client; showSkvGetStartedDialog = true"
          class="cursor-pointer underline">
          {{ t('getStarted') }}
        </div>
        <div
          *ngSwitchCase="'MISSING_PERMISSION'"
          (click)="selectedClient = rowData?.client; showPermissionInfoDialog = true"
          class="cursor-pointer underline">
          {{ t('notBeNotified') }}
        </div>
        <div
          *ngSwitchCase="'MISSING_PROXY'"
          class="text-bl-red-500 truncate"
          [pTooltip]="t('unableToRetrieveInformationFromSwedishTaxAgencyMessage')"
          tooltipPosition="bottom">
          <a
            href="https://www.skatteverket.se/foretag/etjansterochblanketter/allaetjanster/tjanster/ombudochbehorigheter"
            target="_blank"
            class="text-bl-red-500 truncate">
            {{ t('agentAuthMissing') }}
          </a>
        </div>
        <div
          *ngSwitchCase="'SERVER_ERROR'"
          class="text-bl-red-500 truncate"
          [pTooltip]="t('unableToRetrieveInformationFromSwedishTaxAgencyMessage')"
          tooltipPosition="bottom">
          <a
            href="https://www.skatteverket.se/foretag/etjansterochblanketter/allaetjanster/tjanster/ombudochbehorigheter"
            target="_blank"
            class="text-bl-red-500 truncate"
            >{{ t('technicalErrorOccurred') }}</a
          >
        </div>
        <div *ngSwitchDefault>
          <p
            (click)="openTaxAccount()"
            class="cursor-pointer hover:underline mb-0"
            [ngClass]="{
              'text-bl-red-500': +rowData?.todo?.TaxAccount < 0,
              'text-black': +rowData?.todo?.TaxAccount >= 0,
            }">
            {{ rowData?.todo?.TaxAccount | BLCurrency: false }} kr
          </p>
        </div>
      </ng-container>
    </ng-template>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="program" let-rowData="rowData">
      <div class="flex justify-center">
        <label (click)="openBlappInNewTab(rowData.client)" class="cursor-pointer mr-4">
          <img src="assets/blapp-subscription-type-icons/sub_type4.png" [alt]="t('startBlApp')" class="inline w-6" />
        </label>
        <label (click)="callAgent(rowData.client)" class="cursor-pointer" *appHideIfCountries="'NL'">
          <img [src]="'BL' | getIcon: !isLoggedInWithAllInclusiveService" [alt]="t('startProgram')" class="inline" />
        </label>
      </div>
    </ng-template>
    <ng-template tableTemplate="emptyMessage">
      {{ loading ? t('updatingListPleaseWait') : t('noClientsWithPublicKeyWasFound') }}
    </ng-template>
    <ng-template tableTemplate="summary" let-data>
      <div class="relative">
        {{ t('numberOfRows') }}: {{ data.length }}
        <div class="absolute export-button-wrapper">
          <p-button
            icon="pi pi-download"
            class="export-button"
            size="small"
            [label]="t('exportList')"
            (click)="table.exportCSV()"
            [title]="t('downloadListToImportInExcel')"></p-button>
        </div>
      </div>
    </ng-template>

    <ng-template #defaultClientInfoCell let-rowData let-content="content">
      <a
        (click)="showExistingClient(rowData.client)"
        class="text-black cursor-pointer"
        [ngClass]="{ '!text-bl-red-500': !rowData.todo.success }">
        {{ content }}
      </a>
    </ng-template>
    <ng-template #agentCallCell let-rowData let-content="content" let-task="task">
      <p
        (click)="isLoggedInWithAllInclusiveService ? callAgent(rowData.client, task) : undefined"
        [ngClass]="isLoggedInWithAllInclusiveService ? 'hover:underline cursor-pointer' : 'cursor-text'"
        class="text-black mb-0">
        {{ content }}
      </p>
    </ng-template>
    <ng-template #blappCallCell let-rowData let-content="content">
      <p
        (click)="isLoggedInWithAllInclusiveService ? callBlapp() : undefined"
        [ngClass]="isLoggedInWithAllInclusiveService ? 'hover:underline cursor-pointer' : 'cursor-text'"
        class="text-black mb-0">
        {{ content }}
      </p>
    </ng-template>
  </app-table>

  <a href="#" id="agentCaller"></a>

  <p-dialog
    [header]="t('allResponsibleUsers')"
    [(visible)]="showAllUserWarningDialog"
    [closable]="false"
    [draggable]="false">
    <p>{{ t('showAllResponsibleUsersMessage') }}</p>
    <div class="flex w-full justify-end mt-10">
      <bl-frontend-button
        variant="secondary"
        [text]="'shared.no' | transloco"
        (click)="allUserWarningCancel()"
        class="mr-2">
      </bl-frontend-button>
      <bl-frontend-button [text]="'shared.yes' | transloco" (click)="allUserWarningConfirm()"></bl-frontend-button>
    </div>
  </p-dialog>

  <p-dialog
    [header]="t('getStartedWithTaxAccountTitle')"
    [(visible)]="showSkvGetStartedDialog"
    [draggable]="false"
    contentStyleClass="pb-2-important">
    <p [innerHTML]="t('taxAccountMessage1') | safeHtml"></p>
    <p (click)="onSkvDialogTagClick($event)" [innerHTML]="t('taxAccountMessage2') | safeHtml"></p>
    <p [innerHTML]="t('taxAccountMessage3') | safeHtml"></p>
    <p [innerHTML]="t('taxAccountReadMoreLink') | safeHtml"></p>
    <div class="flex w-full justify-end my-3">
      <bl-frontend-button
        [text]="'shared.close' | transloco"
        (click)="showSkvGetStartedDialog = false"></bl-frontend-button>
    </div>
  </p-dialog>

  <p-dialog
    [header]="t('taxAccountNotNotified')"
    [(visible)]="showPermissionInfoDialog"
    [draggable]="false"
    contentStyleClass="pb-2-important">
    <p (click)="onSkvDialogTagClick($event)" [innerHTML]="t('taxAccountNotShownInfoMessage') | safeHtml"></p>
    <div class="flex w-full justify-end my-3">
      <bl-frontend-button
        [text]="'shared.close' | transloco"
        (click)="showPermissionInfoDialog = false"></bl-frontend-button>
    </div>
  </p-dialog>
</ng-container>
