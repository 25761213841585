<main>
  <app-tabs (tabChange)="onTabChange()">
    <app-tab tabTitle="Importera företag till Byråstöd">
      <div style="overflow: auto; height: 350px">
        <ng-container *ngIf="!loadingData; else loadingTpl">
          <app-import-missing-companies-to-byst
            [includeHidden]="includeHidden"
            (updated)="showWorkingSpinner = false; updated = true"
            (updating)="showWorkingSpinner = true"></app-import-missing-companies-to-byst>
        </ng-container>
      </div>
      <ng-container *appHideIfCountries="'NL'">
        <p-checkbox
          *ngIf="!loadingData && hasAccessToShowHiddenDatabases | async"
          class="mt-5 mb-0"
          [binary]="true"
          [(ngModel)]="includeHidden"
          label="Visa även företagsdatabaser som är dolda i listan över Företag i molnet i BL Administration"></p-checkbox>
      </ng-container>
    </app-tab>
    <app-tab tabTitle="Exportera företag till molnet" *appHideIfCountries="'NL'">
      <div style="overflow: auto; height: 350px">
        <ng-container *ngIf="!loadingData; else loadingTpl">
          <app-export-companies-to-cloud (updated)="updated = true"></app-export-companies-to-cloud>
        </ng-container>
      </div>
    </app-tab>
    <app-tab tabTitle="Koppla ihop befintliga klienter och molnföretag">
      <div style="overflow: auto; height: 350px">
        <ng-container *ngIf="!loadingData; else loadingTpl">
          <app-sync-companies-between-byst-cloud
            (updating)="showWorkingSpinner = true"
            (updated)="showWorkingSpinner = false; updated = true"></app-sync-companies-between-byst-cloud>
        </ng-container>
      </div>
    </app-tab>
  </app-tabs>

  <div>
    <div class="inline align-bottom mr-2" *ngIf="showWorkingSpinner">
      <p-progressSpinner [style]="{ width: '23px', height: '23px' }" strokeWidth="6"></p-progressSpinner>
    </div>

    <div class="flex justify-end mt-6">
      <bl-frontend-button
        class="mr-2"
        text="Stäng"
        variant="secondary"
        (click)="close({ updated })"></bl-frontend-button>
      <bl-frontend-button
        text="Lägg till"
        variant="primary"
        *ngIf="!hideAddButton"
        [disabled]="loadingData || showWorkingSpinner"
        (click)="onAddButton()"></bl-frontend-button>
    </div>
  </div>
</main>

<ng-template #loadingTpl>
  <div class="text-center mt-16">
    <img src="assets/ajax-loader.gif" alt="Arbetar, var god vänta..." class="mb-4 inline" /><br />
    Hämtar information om era molndatabaser. <br />
    Det kan ta några minuter så vänligen vänta...
  </div>
</ng-template>
