import { createFeature, createReducer, on } from '@ngrx/store';
import { NameDescriptionType } from '../types';
import { TodoSelectableStates, TodoSelectableTypes } from '../types/todo.types';
import { TodoStatesActions } from './todo-states.actions';

export interface TodoStates {
  states: NameDescriptionType[];
  types: NameDescriptionType[];
}

export const initialTodoStates: TodoStates = {
  states: [...TodoSelectableStates],
  types: [...TodoSelectableTypes],
};

export const todoStatesFeature = createFeature({
  name: 'todoStates',
  reducer: createReducer(
    initialTodoStates,
    on(TodoStatesActions.updateStatesDescriptions, (state, { states }) => ({ ...state, states: [...states] })),
    on(TodoStatesActions.updateTypesDescriptions, (state, { types }) => ({ ...state, types: [...types] })),
  ),
});
