import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SimpleUserType } from '@app/core/state/types';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { UserAvatarComponent } from '@app/shared/components/user-avatar/user-avatar.component';
import { Observable } from 'rxjs';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { TranslocoModule } from '@jsverse/transloco';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';

@Component({
  selector: 'app-team-warnings',
  template: `
    <ng-container *ngIf="usersWithoutTeam$ | async as usersWithoutTeam">
      <ng-container *transloco="let t; prefix: this.scope">
        <div class="mt-8 alert alert-danger" *ngIf="usersWithoutTeam?.length > 0">
          <p class="pb-3 font-bold">{{ t('theseEmployeesDoesNotBelongToAnyTeam') }}</p>
          <app-user-avatar [users]="usersWithoutTeam" [maxVisible]="0"></app-user-avatar>
        </div>
      </ng-container>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UserAvatarComponent, TranslocoModule],
  providers: [getTranslationProvider('TeamWarningsComponent')],
})
export class TeamWarningsComponent extends TranslatableComponent {
  usersWithoutTeam$: Observable<SimpleUserType[]>;

  constructor() {
    super();
    this.usersWithoutTeam$ = this.store.select(UserSelectors.activeUsersWithoutTeamsAsSimpleUser);
  }
}
