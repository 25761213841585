import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HashMap, Translation, TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  map,
  MonoTypeOperatorFunction,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { LanguageType } from '../entity/locale';
import { inject } from '../misc/testableInject';
import { AppState } from '../state/appState';
import { TranslatableString } from './TranslatableString';
import { Translatable, TranslatableStringType } from './types';
import { mapTranslationToTranslatableString, translate } from './utils';
import { i18nActions } from '../state/i18n/i18n.actions';

@Directive()
export abstract class TranslatableComponent<TranslationType = HashMap<TranslatableStringType>>
  implements OnInit, OnDestroy, Translatable<TranslationType>
{
  protected store: Store<AppState>;
  protected scope: string;
  protected route: ActivatedRoute;
  protected router: Router;
  protected translationService: TranslocoService;
  private translationLoadedSub: BehaviorSubject<Translation>;
  private translationsInitialized = false;
  private onDestroySub: Subject<void>;

  constructor() {
    this.store = inject(Store);
    this.route = inject(ActivatedRoute);
    this.router = inject(Router);
    this.translationService = inject(TranslocoService);
    this.onDestroySub = new Subject();
    this.translationLoadedSub = new BehaviorSubject(null);
    const scope = inject(TRANSLOCO_SCOPE) as unknown as { scope: string; alias: string }[];
    this.scope = scope[0]?.alias;
  }

  ngOnInit(): void {
    this.translationService.langChanges$
      .pipe(
        switchMap(() => this.translationService.selectTranslation(this.scope)),
        takeUntil(this.onDestroySub),
      )
      .subscribe({
        next: (translations) => {
          this.translationsInitialized = true;
          this.translationLoadedSub.next(translations);
          this.onTranslationsLoaded();
          // if (Object.keys(translations)?.length) {
          //   this.store.dispatch(i18nActions.translationsUpdated({ name: this.scope, translations }));
          // }
        },
        error: (err: unknown) => {
          // eslint-disable-next-line no-console
          console.error(`TranslatableComponent error for ${this.scope}`, err);
        },
      });
  }

  ngOnDestroy(): void {
    this.onDestroySub?.next();
    this.onDestroySub?.complete();
  }

  /**
   * Use this in your dialog with any subscriptions that you want to automatically unsubscribe from when the dialog closes
   * @returns MonoTypeOperatorFunction<T>
   */
  protected takeUntilDestroyed = <V>(): MonoTypeOperatorFunction<V> => takeUntil(this.onDestroySub);

  protected getLanguageChanges = (): Observable<string> => this.translationService.langChanges$;

  getActiveLanguage = (): LanguageType => this.translationService.getActiveLang() as LanguageType;

  setActiveLanguage = (lang: LanguageType): void => {
    this.store.dispatch(i18nActions.languageChange({ languageCode: lang }));
  };

  translate = (
    key: string,
    options: {
      params?: HashMap;
      useComponentScope?: boolean;
    } = { useComponentScope: true },
  ): TranslatableStringType => {
    const { scope, translationService, translationsInitialized } = this;
    const translated = translate(key, { scope, translationService, translationsInitialized }, options);
    return new TranslatableString(translated) as TranslatableStringType;
  };

  get translationsLoaded$(): Observable<TranslationType> {
    return this.translationLoadedSub.pipe(
      map(mapTranslationToTranslatableString<TranslationType>),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onTranslationsLoaded(): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onTranslationChangeMapTo = <T>(mapTo: (translations: Translation) => T): Observable<T> => {
    return this.translationsLoaded$.pipe(map(mapTo));
  };
}
