import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { getTranslationProvider } from '../core/i18n/getTranslationProvider';
import { TranslatableFormDialogBaseComponent } from '../core/i18n/translatable-form-dialog-base.component';
import { TranslatableStringType } from '../core/i18n/types';
import { BlFrontendButtonComponent } from '../core/wrappers/bl-frontend-button.component';
import { DialogData, DialogResult } from '../shared/components/form-dialog-base/form-dialog-base.component';

interface RemoveTodoDialogResult extends DialogResult {
  confirm: boolean;
}

interface RemoveTodoDialogTranslations {
  removeTodoEntry: TranslatableStringType;
}

type DialogConfigTranslationType = Record<keyof RemoveTodoDialogTranslations, TranslatableStringType>;

const TRANSLATION_SCOPE = 'removeTodoDialogComponent';

@Component({
  selector: 'app-remove-todo-dialog',
  template: `<ng-container *transloco="let t; prefix: scope">
    <p>{{ t('entryWillBePermanentRemoved') }}</p>
    <p>{{ t('wantToContinue') }}</p>
    <div class="flex w-full justify-end mt-10">
      <bl-frontend-button
        variant="secondary"
        [text]="'shared.no' | transloco"
        (click)="close({ confirm: null })"
        class="mr-2">
      </bl-frontend-button>
      <bl-frontend-button [text]="'shared.yes' | transloco" (click)="close({ confirm: true })"></bl-frontend-button>
    </div>
  </ng-container>`,
  providers: [getTranslationProvider(TRANSLATION_SCOPE)],
  standalone: true,
  imports: [CommonModule, TranslocoModule, BlFrontendButtonComponent],
})
export class RemoveTodoDialogComponent extends TranslatableFormDialogBaseComponent<
  DialogData,
  RemoveTodoDialogResult,
  unknown
> {
  protected static override getTranslationScope(): string {
    return TRANSLATION_SCOPE;
  }

  protected static override getDialogConfig(
    translation: DialogConfigTranslationType,
  ): Omit<DynamicDialogConfig, 'data'> {
    return {
      header: translation.removeTodoEntry,
      width: '400px',
      closeOnEscape: false,
      closable: false,
      draggable: false,
    };
  }
}
