import { Pipe, PipeTransform } from '@angular/core';
import { AmlRisk } from '@app/core/state/types/aml.types';

@Pipe({
  name: 'amlRiskDescription',
  standalone: true,
})
export class AmlRiskDescriptionPipe implements PipeTransform {
  transform(value: AmlRisk | null): string {
    switch (value) {
      case AmlRisk.LOW:
        return 'Låg risk';
      case AmlRisk.NORMAL:
        return 'Normal risk';
      case AmlRisk.HIGH:
        return 'Hög risk';
      default:
        return '';
    }
  }
}
