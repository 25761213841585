<ng-container *ngIf="permissionState$ | async as state">
  <div
    [pTooltip]="disabled && disabledTooltip"
    tooltipPosition="bottom"
    tooltipStyleClass="tooltip-extended-width"
    class="w-full">
    <p-checkbox
      [binary]="true"
      [disabled]="disabled"
      [ngModel]="state[VisibleInNotification].active"
      (onChange)="updateNotificationPermissionState('active', !!$event?.checked)"
      label="Visa klienten i fliken Aviseringar för:"></p-checkbox>

    <div class="ml-8 mt-2">
      <p-dropdown
        [options]="permissionGroupOptions"
        [disabled]="disabled || !state[VisibleInNotification].active"
        [ngModel]="state[VisibleInNotification].group"
        (ngModelChange)="updateNotificationPermissionState('group', $event)"
        optionLabel="name"
        optionValue="value"
        placeholder="Ingen"
        [autoOptionFocus]="false"
        [autoDisplayFirst]="false">
      </p-dropdown>
    </div>
    <div class="ml-8 mt-2">
      <app-multiselect-dropdown
        #visibleInNotificationMultiselect
        *ngIf="state[VisibleInNotification].group === 'ONLY_SELECTED_USERS'"
        [breakpoint]="12"
        [options]="availableUsers$ | async"
        [disabled]="disabled || !state[VisibleInNotification].active"
        [selected]="state[VisibleInNotification].users"
        (selectedChange)="updateNotificationPermissionState('users', $event, false)"
        (onHide)="onMultiselectHide(VisibleInNotification)"
        optionLabel="name"
        optionValue="id"
        placeholder="Välj användare"
        appendTo="body">
      </app-multiselect-dropdown>
    </div>

    <ng-container *appHideIfCountries="'NL'">
      <p-checkbox
        [binary]="true"
        [disabled]="disabled || !state[VisibleInNotification].active"
        [ngModel]="state[ShowTaxAccountBalance].active"
        (onChange)="updateTaxAccountPermissionState('active', !!$event?.checked)"
        label="Visa skattekontosaldo för:"
        class="mt-5"></p-checkbox>

      <div class="ml-8 mt-2">
        <p-dropdown
          [options]="permissionGroupOptions"
          [disabled]="disabled || !state[ShowTaxAccountBalance].active"
          [ngModel]="state[ShowTaxAccountBalance].group"
          (ngModelChange)="updateTaxAccountPermissionState('group', $event)"
          optionLabel="name"
          optionValue="value"
          placeholder="Ingen"
          [autoOptionFocus]="false"
          [autoDisplayFirst]="false">
        </p-dropdown>
      </div>
      <div class="ml-8 mt-2">
        <app-multiselect-dropdown
          #showTaxAccountMultiselect
          *ngIf="state[ShowTaxAccountBalance].group === 'ONLY_SELECTED_USERS'"
          [breakpoint]="12"
          [options]="availableUsers$ | async"
          [disabled]="disabled || !state[ShowTaxAccountBalance].active"
          [selected]="state[ShowTaxAccountBalance].users"
          (selectedChange)="updateTaxAccountPermissionState('users', $event, false)"
          (onHide)="onMultiselectHide(ShowTaxAccountBalance)"
          optionLabel="name"
          optionValue="id"
          placeholder="Välj användare"></app-multiselect-dropdown>
      </div>
    </ng-container>
  </div>
</ng-container>
