import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconButtonProps } from '@bl/components';
import { BlFrontendBaseComponent } from './bl-frontend-base.component';

@Component({
  selector: 'bl-frontend-iconbutton',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class BlFrontendIconButtonComponent extends BlFrontendBaseComponent<IconButtonProps> {
  @Input() name: IconButtonProps['name'];
  @Input() variant: IconButtonProps['variant'] = 'regular';
  @Input() animation: IconButtonProps['animation'] = 'none';
  @Input() density: IconButtonProps['density'] = 'regular';
  @Input() brand: IconButtonProps['brand'] = 'firmsupport';
  @Input() disabled: IconButtonProps['disabled'] = false;

  protected override getComponentName(): string {
    return 'IconButton';
  }

  protected override getProps(): IconButtonProps {
    return {
      name: this.name,
      variant: this.variant,
      animation: this.animation,
      density: this.density,
      brand: this.brand,
      disabled: this.disabled,
    };
  }
}
