import { createAction, createActionGroup, props } from '@ngrx/store';

export const NoOperationAction = createAction('No Operation');

export const CoreActions = createActionGroup({
  source: 'Navigation',
  events: {
    'Navigation Start': props<{ url: string }>(),
  },
});
