/* eslint-disable complexity */
import { HashMap, Translation, TranslocoService } from '@jsverse/transloco';
import { TranslatableStringType } from './types';
import { TranslatableString } from './TranslatableString';

const getKeyWithScope = (scope: string, key: string, useComponentScope = true): string =>
  useComponentScope ? `${scope}.${key ?? ''}` : key ?? '';

const getMissingTranslationText = (scope: string, key: string): string => `${scope ? `${scope}.${key}` : key}`;

export const translate = (
  key: string,
  dependencies: { scope: string; translationService: TranslocoService; translationsInitialized: boolean },
  options: {
    params?: HashMap;
    useComponentScope?: boolean;
  } = { useComponentScope: true },
): string => {
  const { params, useComponentScope } = options;
  const { scope, translationService, translationsInitialized } = dependencies;
  const keyWithScope = getKeyWithScope(scope, key, useComponentScope);

  if (useComponentScope && !translationsInitialized) {
    return keyWithScope;
  }

  const translation = translationService.translate(keyWithScope, params);

  if (!translation || translation === `${scope}.${key}`) {
    const msg = getMissingTranslationText(scope, key);
    return msg;
  }

  return translation;
};

export const mapTranslationToTranslatableString = <T = HashMap<TranslatableStringType>>(obj: Translation): T => {
  if (!obj) {
    return null;
  }

  return Object.entries(obj || {}).reduce((acc, [key, value]) => {
    if (typeof value === 'string') {
      return { ...acc, [key]: new TranslatableString(value) as TranslatableStringType };
    }
    if (typeof value === 'object') {
      return { ...acc, [key]: mapTranslationToTranslatableString(value) };
    }
    return { ...acc, [key]: value };
  }, {} as T);
};
