<div class="h-full flex flex-col">
  <app-navbar
    (newNote)="showNewNoteDialog()"
    (settings)="showSettingsDialog()"
    (logout)="logout()"></app-navbar>

  <router-outlet></router-outlet>
</div>

<p-toast position="top-right">
  <ng-template let-message pTemplate="message">
    <span *ngIf="message.severity === 'info'" class="p-toast-message-icon pi pi-info-circle"></span>
    <span *ngIf="message.severity === 'warn'" class="p-toast-message-icon pi pi-exclamation-triangle"></span>
    <span *ngIf="message.severity === 'error'" class="p-toast-message-icon pi pi-times-circle"></span>
    <div class="p-toast-message-text">
      <div class="p-toast-summary">
        <p class="w-96 text-pretty">{{ message.summary }}</p>
      </div>
      <div class="p-toast-detail">
        <p class="w-96 text-pretty">{{ message.detail }}</p>
      </div>
      <div *ngIf="message.data?.button?.click" class="mt-2 flex justify-end">
        <button *ngIf="message.severity === 'info'" class="btn btn-info" (click)="handleToastButtonClick(message)">
          {{ message.data.button.text ?? 'Öppna' }}
        </button>
        <button *ngIf="message.severity === 'warn'" class="btn btn-warning" (click)="handleToastButtonClick(message)">
          {{ message.data.button.text ?? 'Öppna' }}
        </button>
        <button *ngIf="message.severity === 'error'" class="btn btn-danger" (click)="handleToastButtonClick(message)">
          {{ message.data.button.text ?? 'Öppna' }}
        </button>
      </div>
    </div>
  </ng-template>
</p-toast>
