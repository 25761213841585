<div>
  <app-tabs #tabs>
    <ng-container *transloco="let t; prefix: this.scope">
      <app-tab [tabTitle]="t('firmData')" tabId="tabFirmData">
        <app-firm-data [firmData$]="firmData$ | async" (updateFirmEvent)="updateFirmData($event)"> </app-firm-data>
      </app-tab>
      <app-tab [tabTitle]="t('firmUser')" tabId="tabFirmUsers">
        <app-firm-users></app-firm-users>
      </app-tab>
      <app-tab [tabTitle]="t('taskTemplates')" tabId="tabTaskTemplates" *appHideIfCountries="'NL'">
        <app-firm-task-templates></app-firm-task-templates>
      </app-tab>
      <ng-container *ngIf="firmAssignmentData$ | async as firmSettings">
        <app-tab [tabTitle]="t('assignmentSettings')" tabId="tabAssignmentSettings" *appHideIfCountries="'NL'">
          <app-firm-assignment-settings
            [firmAssignmentData$]="firmSettings"
            (updateFirmAssignmentData)="updateFirmAssignmentData($event)"
            (resetFirmAssignmentData)="resetFirmAssignmentData()">
          </app-firm-assignment-settings>
        </app-tab>
      </ng-container>
      <app-tab
        [tabTitle]="t('bulkHandling')"
        [tabTooltip]="t('bulkHandlingTooltip')"
        tabId="tabBulkHandling"
        [disabled]="!hasRightsToChange()"
        (activeChange)="bulkHandlingTabActive = $event"
        *appHideIfCountries="'NL'">
        <app-bulk-main [tabActive]="bulkHandlingTabActive"></app-bulk-main>
      </app-tab>

      <app-tab
        [tabTitle]="t('teamManagement')"
        [tabTooltip]="t('teamManagementTooltip')"
        tabId="tabTeams"
        [disabled]="!hasRightsToChange()"
        (activeChange)="teamTabActive = $event">
        <app-teams *ngIf="teamTabActive"></app-teams>
      </app-tab>

      <app-tab [tabTitle]="t('systemSettings')" tabId="tabFirmSystemSettings">
        <app-firm-system-settings></app-firm-system-settings>
      </app-tab>

      <app-tab [tabTitle]="t('import')" tabId="tabFileImport" (activeChange)="fileImportTabActive = $event"
        ><app-file-import [tabActive]="fileImportTabActive"></app-file-import
      ></app-tab>

      <ng-container *appHideIfCountries="'NL'">
        <app-tab *appAdminPrivilege [tabTitle]="t('firmAdminTools')" tabId="tabFirmAdminTools">
          <app-firm-reset [closeCallback]="resetCallback"></app-firm-reset>
        </app-tab>
      </ng-container>
    </ng-container>
  </app-tabs>
</div>
