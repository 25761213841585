<app-please-wait [hidden]="hidePleaseWait"></app-please-wait>

<form [formGroup]="form">
  <p class="text-base font-normal" *ngIf="!clientHasCloudApiKey">
    {{ config.data.client?.name }} saknar molndatabasnyckel, vilket krävs för att aktivera Rådgivarverktyget.
  </p>
  <p *ngIf="clientHasCloudApiKey">
    <ng-container *ngIf="!clientHasAdvisoryTool; else deActivateText">
      <ng-container *ngIf="isClientSameAsFirm; else regularText">
        För att du ska ha möjlighet att upptäcka fördelarna med rådgivarverktyget kan du här aktivera tjänsten för din
        egen byrå – helt kostnadsfritt och utan tidsbegränsning.
      </ng-container>
      <ng-template #regularText>
        Avtalet löper tillsvidare och betalning sker månadsvis med 79 kr per månad och företag. Avtalet fortgår så länge
        tjänsten inte avaktiveras. Ingen bindningstid eller uppsägningstid.
        <div class="mt-4">
          <bl-frontend-checkbox
            formControlName="agreement"
            name="agreement"
            value="agreement"
            text="Jag har läst och godkänner ovanstående villkor." 
            />
        </div>
      </ng-template>
    </ng-container>
    <ng-template #deActivateText>Vill du avsluta Rådgivarverktyget för {{ config.data.client?.name }}?</ng-template>
  </p>
  <div class="flex justify-end mt-8 space-x-1">
    <bl-frontend-button type="button" [text]="'Avbryt'" variant="secondary" (click)="close({})" />
    <bl-frontend-button
      type="button"
      *ngIf="deactivateEnabled"
      [text]="'Avaktivera'"
      (click)="deactivateAdivsoryTool()" />
    <bl-frontend-button
      type="button"
      *ngIf="activateEnabled"
      [text]="'Aktivera'"
      [disabled]="!form.value.agreement"
      (click)="activateAdvisoryTool()" />
    <bl-frontend-button
      type="button"
      *ngIf="!clientHasAdvisoryTool && !clientHasCloudApiKey"
      [text]="'Ange molndatabasnyckel'"
      (click)="close({openClientCard: true})" />
  </div>
</form>
