import { createActionGroup, props } from '@ngrx/store';
import { NameDescriptionType } from '../types';

export const TodoStatesActions = createActionGroup({
  source: 'TodoStates',
  events: {
    'Update states descriptions': props<{ states: NameDescriptionType[] }>(),
    'Update types descriptions': props<{ types: NameDescriptionType[] }>(),
  },
});
