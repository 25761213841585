import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';

@Component({
  selector: 'app-client-archive-client-dialog',
  template: `
    <ng-container *transloco="let t; prefix: scope">
      <p-dialog
        [header]="t('archiveClientWithName', { name })"
        [modal]="true"
        [dismissableMask]="true"
        [visible]="visible"
        (visibleChange)="onVisibleChange($event)"
        showEffect="fade"
        [style]="{ width: '400px' }"
        [draggable]="false">
        <p>{{ t('archiveClientText1') }}</p>
        <p>{{ t('archiveClientText2') }}</p>
        <div class="flex justify-end mt-3 space-x-2">
          <bl-frontend-button
            variant="secondary"
            [text]="'shared.no' | transloco"
            (click)="onCancelClicked()"></bl-frontend-button>
          <bl-frontend-button [text]="'shared.yes' | transloco" (click)="onConfirmClicked()"></bl-frontend-button>
        </div>
      </p-dialog>
    </ng-container>
  `,
  providers: [getTranslationProvider('ArchiveClientDialogComponent')],
})
export class ArchiveClientDialogComponent extends TranslatableComponent {
  @Input() visible = false;
  @Input() name: string;

  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() confirmed = new EventEmitter();
  @Output() cancelled = new EventEmitter();

  public onVisibleChange(visible: boolean) {
    this.visibleChange.emit(visible);
  }

  public onConfirmClicked() {
    this.confirmed.emit();
  }

  public onCancelClicked() {
    this.cancelled.emit();
  }
}
