import { createFeature, createReducer, on } from '@ngrx/store';
import { FeatureType } from '../types';
import { FeatureActions } from './feature.actions';

export interface FeatureState {
  initialized: boolean;
  features: FeatureType;
}

export const initialFeaturesState: FeatureState = {
  initialized: false,
  features: {
    messaging: false,
    showMyFirm: false,
    i18nSupport: false,
  },
};

export const featuresFeature = createFeature({
  name: 'features',
  reducer: createReducer(
    initialFeaturesState,
    on(FeatureActions.featuresUpdated, (state, { features }) => ({
      ...state,
      initialized: true,
      features: { ...state.features, ...features },
    })),
  ),
});
