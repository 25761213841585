import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isAdmin } from '@app/core/misc/isAdmin';
import { withFirstTeamIds } from '@app/core/misc/rxjs-operators';
import { AllInclusiveService } from '@app/core/services/all-inclusive.service';
import { NoAssignedTeamDialog } from '@app/shared/components/no-assigned-team/no-assigned-team.dialog';
import { openDialog } from '@app/shared/misc/openDialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Jwt } from '../../misc/jwt';
import { BlAccountAuthService } from '../../services/bl-account-auth.service';
import { UserService } from '../../services/user.service';
import { UserSettingsStorage } from '../../storage/user.settings.storage';
import { AppState } from '../appState';
import { NoOperationAction } from '../core/core.actions';
import { ToastActions } from '../toast/toast.actions';
import { SimpleUserType, UserType } from '../types';
import { UserActions } from '../users/users.actions';
import { UserSelectors } from '../users/users.selectors';
import { AuthActions } from './auth.actions';
import { AuthSelectors } from './auth.selectors';
import { i18nActions } from '../i18n/i18n.actions';

@Injectable()
export class AuthEffects {
  private uss = new UserSettingsStorage();

  initLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initLogin),
      tap(() => this.blAccountAuthService.login()),
      map(() => AuthActions.loginSpinnerStart()),
    ),
  );

  waitForLogin$ = createEffect(() =>
    this.blAccountAuthService.authState.pipe(
      // eslint-disable-next-line no-undefined
      filter((token) => token !== undefined),
      map((token: string | null) => {
        if (token && Jwt.isValidToken(token)) {
          return AuthActions.loginSuccessful({ token });
        }
        return AuthActions.loginSpinnerStopp();
      }),
    ),
  );

  storeJwtTokenOnSuccessfulLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccessful),
      tap(({ token }) => this.storeJwtAndUserInfo(token)),
      map(() => AuthActions.afterLoginSuccessful()),
    ),
  );

  routeToStartOnSuccessfulLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.afterLoginSuccessful),
      withLatestFrom(this.store.select(AuthSelectors.selectRedirectUrl)),
      tap(([, redirectUrl]) => this.router.navigate([redirectUrl ?? 'start'])), // go to start page if success
      map(() => AuthActions.clearRedirectUrl()),
    ),
  );

  loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccessful, AuthActions.reloadCurrentAuthUser),
      exhaustMap(() =>
        this.userService.loadCurrentUserType().pipe(
          map((user: UserType) => AuthActions.loadCurrentUserSuccessful({ user })),
          catchError((error: unknown) => of(AuthActions.loadCurrentUserFailed({ error }))),
        ),
      ),
    ),
  );

  reloadAuthUserIfNotExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.reloadCurrentAuthUserIfNotLoaded),
      concatLatestFrom(() => this.store.select(AuthSelectors.selectAuthUser)),
      filter(([, authUser]) => !authUser),
      map(() => AuthActions.reloadCurrentAuthUser()),
    ),
  );

  reset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.resetAuthStateAndStorage),
        tap(() => this.uss.clearSessionStorage()),
        tap(() => this.userService.logout()),
      ),
    { dispatch: false },
  );

  setActiveLanguageFromCurrentUserLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadCurrentUserSuccessful),
      map(({ user }) => user.languageCode),
      map((languageCode) => i18nActions.languageChange({ languageCode })),
    ),
  );

  persistSelectedLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(i18nActions.languageChanged),
      concatLatestFrom(() => this.store.select(AuthSelectors.selectAuthUser)),
      map(([action, user]) => ({ ...user, languageCode: action.languageCode })),
      switchMap((userWithUpdatedLanguage) =>
        this.userService.saveUserType(userWithUpdatedLanguage).pipe(
          map(() => UserActions.loadAllUsers()), // reloads the users in case they go to the firmusers and update a user
          catchError(() => of(NoOperationAction)),
        ),
      ),
    ),
  );

  checkUsersWithouTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadCurrentUserSuccessful),
      filter(({ user }) => isAdmin(user)),
      switchMap(() => withFirstTeamIds(this.store)),
      filter(({ ids }) => ids?.length > 1),
      concatLatestFrom(() => this.store.select(UserSelectors.activeUsersWithoutTeamsAsSimpleUser)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([_, users]) => Boolean(users?.length)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([_, users]) => this.getUsersAsString(users)),
      map((detail) =>
        ToastActions.showWarnMessage({
          summary: 'Dessa medarbetare finns inte med i något team:',
          detail,
          sticky: true,
        }),
      ),
    ),
  );

  checkUserWithouTeams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loadCurrentUserSuccessful),
        filter(({ user }) => !user.teams?.length),
        switchMap(() => withFirstTeamIds(this.store)),
        filter(({ ids }) => ids?.length > 1),
        tap(() => openDialog(this.dialogService, NoAssignedTeamDialog, {})),
      ),
    { dispatch: false },
  );

  private storeJwtAndUserInfo = (jwt: string) => {
    const tokenPayload = Jwt.decodeToken(jwt);

    if (!this.isSameFirmAsLastLogin(tokenPayload) || !this.isSameUserAsLastLogin(tokenPayload)) {
      this.uss.clearSettings();
    }

    this.uss.saveSetting('jwt', jwt);
    this.uss.saveSetting(UserSettingsStorage.LOGGED_IN_SERVICE, tokenPayload.service);
    this.uss.saveSetting(UserSettingsStorage.LOGGED_IN_FIRM_NAME, tokenPayload.dataOwner.name);

    this.allInclusiveService.reloadLoggedInService();
  };

  private isSameFirmAsLastLogin = (tokenPayload: { dataOwner: { name: string } }) =>
    Boolean(tokenPayload) &&
    tokenPayload.dataOwner.name === this.uss.loadSetting(UserSettingsStorage.LOGGED_IN_FIRM_NAME, '');

  private isSameUserAsLastLogin = (tokenPayload: { name: string }) =>
    Boolean(tokenPayload) && tokenPayload.name === this.uss.loadSetting(UserSettingsStorage.LOGGED_IN_USER_NAME, '');

  private getUsersAsString = (users: SimpleUserType[]) => users.map((u) => `${u.initials} - ${u.name}`).join(', ');

  // eslint-disable-next-line no-useless-constructor, max-params
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private blAccountAuthService: BlAccountAuthService,
    private userService: UserService,
    private dialogService: DialogService,
    private allInclusiveService: AllInclusiveService,
  ) {}
}
