<ng-container *transloco="let t; prefix: this.scope">
  <p-table
    [columns]="columns$ | async"
    [value]="tasks$ | async"
    [(selection)]="selectedTasks"
    (selectionChange)="refilterTasks(); onChange($event); onTouched()"
    [scrollable]="true"
    [sortField]="sortField$ | async"
    [globalFilterFields]="filterFields$ | async"
    scrollHeight="346px"
    dataKey="id"
    #table>
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left mr-5">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="table.filterGlobal($any($event.target).value, 'contains')"
            [placeholder]="t('search')" />
        </span>
        <p-checkbox
          [binary]="true"
          [(ngModel)]="showOnlySelectedRows"
          (ngModelChange)="refilterTasks($any($event))"
          [label]="t('showOnlySelectedRows')"></p-checkbox>
      </div>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <div class="my-3">{{ t('emptyRows') }}</div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3.4em">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th class="font-normal truncate" *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-task let-columns="columns">
      <tr>
        <td>
          <p-tableCheckbox [value]="task"></p-tableCheckbox>
        </td>
        <td class="truncate">
          {{ task.assignmentName }}
        </td>
        <td class="truncate">
          {{ task.type }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="relative">{{ t('numberOfSelectedTasks') }}: {{ selectedTasks?.length }}</div>
    </ng-template>
  </p-table>
</ng-container>
