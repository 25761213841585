import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableFormDialogBaseComponent } from 'src/app/core/i18n/translatable-form-dialog-base.component';
import { DialogData, DialogResult } from 'src/app/shared/components/form-dialog-base/form-dialog-base.component';

interface ConfirmResult extends DialogResult {
  confirm: boolean;
}

@Component({
  selector: 'app-firm-users-confirm-dialog',
  template: `<ng-container *transloco="let t; prefix: this.scope">
      <p [innerHTML]="t('inactivateUserMessage') | safeHtml"></p>
      <p>{{ t('wantToContinue') }}</p>
    </ng-container>
    <div class="flex justify-end space-x-2 mt-4">
      <bl-frontend-button
        variant="secondary"
        (click)="close(null)"
        [text]="'shared.no' | transloco"></bl-frontend-button>
      <bl-frontend-button (click)="close({ confirm: true })" [text]="'shared.yes' | transloco"></bl-frontend-button>
    </div> `,
  providers: [getTranslationProvider('firmUsersConfirmDialogComponent')],
})
export class FirmUsersConfirmDialogComponent extends TranslatableFormDialogBaseComponent<DialogData, ConfirmResult> {
  protected static override getTranslationScope(): string {
    return 'firmUsersConfirmDialogComponent';
  }

  static override getDialogConfig(translations: Record<string, string>): Omit<DynamicDialogConfig<DialogData>, 'data'> {
    return {
      header: translations.inactivateUserTitle,
      width: '500px',
    };
  }
}
