import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';

@Component({
  selector: 'app-please-wait',
  styles: [
    `
      .pane {
        padding: 1em;
      }
      .centered {
        position: absolute;
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `,
  ],
  template: `<ng-container *transloco="let t; prefix: scope">
    <div class="centered p-5 bg-white border border-bl-grey-400 shadow-md text-center">
      <img src="assets/ajax-loader.gif" [alt]="t('pleaseWait')" class="inline" /><br />{{ t('pleaseWait') }}
    </div></ng-container
  >`,
  standalone: true,
  imports: [TranslocoModule],
  providers: [getTranslationProvider('PleaseWaitComponent')],
})
export class PleaseWaitComponent extends TranslatableComponent {}
