<ng-container *transloco="let t; prefix: scope">
  <p-table
    [columns]="columns$ | async"
    [value]="users$ | async"
    [(selection)]="selectedUsers"
    (selectionChange)="refilterUsers(); onChange($event); onTouched()"
    [scrollable]="true"
    [sortField]="sortField$ | async"
    [globalFilterFields]="filterFields$ | async"
    scrollHeight="346px"
    dataKey="id"
    #table>
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left mr-5">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="table.filterGlobal($any($event.target).value, 'contains')"
            [placeholder]="t('search')" />
        </span>
        <p-checkbox
          [binary]="true"
          [(ngModel)]="showOnlySelectedRows"
          (ngModelChange)="refilterUsers($any($event))"
          [label]="t('showOnlySelectedRows')"></p-checkbox>
      </div>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <div class="my-3">Inga träffar</div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" />
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3.4em">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th class="font-normal truncate" *ngFor="let col of columns" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user let-columns="columns">
      <tr>
        <ng-container *ngIf="isAdmin(user)">
          <td>
            <p-checkbox [disabled]="true" [ngModel]="true" [binary]="true"></p-checkbox>
          </td>
          <td class="truncate text-bl-grey-300">
            {{ user.name }}
          </td>
          <td class="truncate text-bl-grey-300">
            {{ getRoleLabel(user) }}
          </td>
        </ng-container>
        <ng-container *ngIf="!isAdmin(user)">
          <td>
            <p-tableCheckbox [value]="user"></p-tableCheckbox>
          </td>
          <td class="truncate">
            {{ user.name }}
          </td>
          <td class="truncate">
            {{ getRoleLabel(user) }}
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="relative">{{ t('numberOfSelectedEmployees') }}: {{ selectedUsers?.length }}</div>
    </ng-template>
  </p-table>
</ng-container>
