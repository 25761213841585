import { Component } from '@angular/core';
import { TeamType } from '@app/core/state/types';
import { TranslocoModule } from '@jsverse/transloco';
import { DialogModule } from 'primeng/dialog';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableFormDialogBaseComponent } from 'src/app/core/i18n/translatable-form-dialog-base.component';
import { BlFrontendButtonComponent } from 'src/app/core/wrappers/bl-frontend-button.component';
import { DialogData, DialogResult } from 'src/app/shared/components/form-dialog-base/form-dialog-base.component';

interface ConfirmDialogResult extends DialogResult {
  confirm: boolean;
}

interface ConfirmDialogData extends DialogData {
  team: TeamType;
}

@Component({
  selector: 'app-confirm-delete-team-dialog',
  template: `
    <p *transloco="let t; prefix: scope">{{ t('confirmationText', { teamName }) }}</p>
    <p-footer>
      <div *transloco="let t; prefix: 'shared'" class="flex justify-end space-x-2 mt-3">
        <bl-frontend-button [text]="t('cancel')" variant="secondary" (click)="onCancelClick()"></bl-frontend-button>
        <bl-frontend-button [text]="t('yesContinue')" (click)="onConfirmClick()"></bl-frontend-button>
      </div>
    </p-footer>
  `,
  standalone: true,
  imports: [DialogModule, TranslocoModule, BlFrontendButtonComponent],
  providers: [getTranslationProvider('confirmDeleteTeamDialogComponent')],
})
export class ConfirmDeleteTeamDialogComponent extends TranslatableFormDialogBaseComponent<
  ConfirmDialogData,
  ConfirmDialogResult
> {
  get teamName() {
    return this.config.data.team?.name;
  }

  onConfirmClick() {
    this.close({ confirm: true });
  }

  onCancelClick() {
    this.close(null);
  }

  protected static override getTranslationScope(): string {
    return 'confirmDeleteTeamDialogComponent';
  }

  protected static override getDialogConfig(translation: Record<string, string>) {
    return {
      header: translation.removeTeam,
    };
  }
}
