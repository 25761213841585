import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { first, map, switchMap } from 'rxjs/operators';
import { i18nActions } from './i18n.actions';

@Injectable()
export class I18nEffects {
  private actions$: Actions = inject(Actions);
  private translocoService: TranslocoService = inject(TranslocoService);

  get selectedTranslation$() {
    return this.translocoService.selectTranslation('shared');
  }

  languageChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(i18nActions.languageChange),
      map(({ languageCode }) => {
        this.translocoService.setActiveLang(languageCode);
        return i18nActions.languageChanged({ languageCode });
      }),
    ),
  );

  updateTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(i18nActions.languageChanged),
      switchMap(({ languageCode }) => this.translocoService.selectTranslation(languageCode).pipe(first(Boolean))),
      map((translations) => i18nActions.translationsUpdated({ translations })),
    ),
  );
}
