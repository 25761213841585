<div class="py-6">
  <span class="font-semibold text-xl">
    <span>{{ form.controls.clientName.value }}:</span>
    <p>{{ form.controls.taskType.value }} {{ form.controls.spanLabel.value }}</p>
  </span>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="flex flex-row items-center justify-between w-full">
    <div class="flex flex-col mr-2">
      <label for="status">Status</label>
      <app-form-activity-states-selector
        [selectableStates]="form.controls.selectableStates.value"
        formControlName="state"
        inputId="status"
        [errors]="form.controls.state.errors"
      ></app-form-activity-states-selector>
    </div>
    <div class="flex flex-col mr-2">
      <label class="truncate" for="datestatus">Datum vid statusändring</label>
      <app-calendar
        inputId="datestatus"
        formControlName="statusChangeDate"
        [invalid]="form.controls.statusChangeDate.invalid"
        [title]="form.controls.statusChangeDate.errors?.statusChangeDate?.message ?? ''"
      ></app-calendar>
    </div>
    <div class="flex flex-col mr-2">
      <label class="truncate" for="users">Utförande handläggare</label>
      <app-form-user-selector
        formControlName="users"
        inputId="users"
        [breakpoint]="12"
        [isInvalid]="form.controls.users.errors?.message"
        [isMultiSelect]="true"
        [title]="form.controls.users.errors?.message ?? ''"
      ></app-form-user-selector>
    </div>
    <div class="flex flex-col">
      <label for="datedeadline">Deadline</label>
      <app-calendar
        appendTo="body"
        inputId="datedeadline"
        formControlName="deadline"
        [invalid]="form.controls.deadline.invalid"
        [title]="form.controls.deadline.errors?.deadline?.message ?? ''"
      ></app-calendar>
    </div>
  </div>

  <p class="!truncate !mt-5">Om tjänsten ({{ form.controls.taskType.value }})</p>
  <textarea class="form-control" style="resize: none" rows="3" cols="56" maxlength="2000" readonly formControlName="taskComment">
  </textarea>

  <p class="!mt-5">Kommentar</p>
  <textarea class="form-control" style="resize: none" rows="3" cols="56" maxlength="2000" formControlName="comment">
  </textarea>

  <p class="!mt-5">Statushistorik</p>
  <app-table
    scrollHeight="180px"
    [columns]="columns"
    [data]="form.controls.stateChangeEvents.value"
    [searchField]="false"
    [columnSelector]="false"
    [paginator]="false"
  >
  </app-table>

  <div class="flex justify-between w-full mt-8">
    <div>
      <button type="button" class="btn btn-primary" (click)="openTaskDialog()" title="Inställningar tjänsten">
        <i class="fas fa-cog"></i>
      </button>
    </div>
    <div class="flex justify-between w-1/5">
      <button type="submit" class="btn btn-success" [disabled]="form.invalid">Spara</button>
      <button type="button" class="btn btn-warning" (click)="close(null)">Stäng</button>
    </div>
  </div>
</form>
