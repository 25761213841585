<ng-container *ngIf="isSwedishFirm$ | async as isSwedishFirm">
  <div>
    <bl-frontend-button text="Lägg till räkenskapsår" icon="plus-circle" (click)="toggleAddFinancialYear()">
    </bl-frontend-button>
  </div>
  <br *ngIf="showAddFinancialYear" />
  <div
    *ngIf="showAddFinancialYear"
    class="flex table-bordered p-3 justify-between"
    [ngClass]="{ 'w-1/2': !isSwedishFirm.value }">
    <div class="mr-3 flex flex-col justify-between">
      <p>Startdatum</p>
      <app-calendar
        [(selectedDate)]="financialYearModel.span.start"
        defaultDate=""
        appendTo="body"
        styleClass=""></app-calendar>
    </div>
    <div class="mr-3 flex flex-col justify-between">
      <p>Slutdatum</p>
      <app-calendar
        [(selectedDate)]="financialYearModel.span.end"
        defaultDate=""
        appendTo="body"
        styleClass=""></app-calendar>
    </div>
    <ng-container *ngIf="isSwedishFirm.value">
      <div class="mr-3 flex flex-col justify-between">
        <p>Inlämning<br />som stort<br />företag</p>
        <p-checkbox [binary]="true" [(ngModel)]="financialYearModel.largeCompany"></p-checkbox>
      </div>
      <div class="mr-3 flex flex-col justify-between">
        <p>Haft<br />EU-handel</p>
        <p-checkbox [binary]="true" [(ngModel)]="financialYearModel.euCommerce"></p-checkbox>
      </div>
      <div class="mr-3 flex flex-col justify-between">
        <p>Elektronisk<br />inl. av<br />moms</p>
        <p-checkbox [binary]="true" [(ngModel)]="financialYearModel.electronicVat"></p-checkbox>
      </div>
      <div class="mr-3 flex flex-col justify-between">
        <p>Per.sammanst.<br />lämnas<br />elektroniskt</p>
        <p-checkbox [binary]="true" [(ngModel)]="financialYearModel.electronicCompilation"></p-checkbox>
      </div>
      <div class="mr-3 flex flex-col justify-between">
        <p>Inkomstdekl.<br />Byråanstånd</p>
        <p-checkbox
          [binary]="true"
          [(ngModel)]="financialYearModel.firmTaxRespite"
          (ngModelChange)="checkIfTaxRespiteIsPossible($event)"></p-checkbox>
      </div>
      <div class="mr-3 flex flex-col justify-between">
        <p>Inkomstdekl.<br />lämnas<br />elektroniskt</p>
        <p-checkbox [binary]="true" [(ngModel)]="financialYearModel.electronicTax"></p-checkbox>
      </div>
    </ng-container>

    <div class="flex flex-col justify-end">
      <bl-frontend-button
        text="Spara"
        (click)="saveFinancialYear()"
        [disabled]="client.archived || client.id === -1"></bl-frontend-button>
      <bl-frontend-button
        text="Töm"
        (click)="reset()"
        variant="secondary"
        class="mt-3"
        *ngIf="isSwedishFirm.value"></bl-frontend-button>
    </div>
  </div>
  <br />

  <div [ngClass]="{ 'w-1/2': !isSwedishFirm.value }">
    <app-table
      [columns]="columns"
      [data]="clientFinancialYears"
      [paginator]="false"
      [searchField]="false"
      [columnSelector]="false"
      (cellClick)="onRowSelect($event)"
      rowClass="cursor-pointer hover:underline"
      [customHeader]="isSwedishFirm.value ? sweHeaderConfig : otherHeaderConfig"
      [useFixedStyle]="true">
      <ng-template tableTemplate="bodyCell" tableBodyCellField="span.start" let-fieldValue
        >{{ fieldValue }}
      </ng-template>
      <ng-template tableTemplate="bodyCell" tableBodyCellField="span.end" let-fieldValue>{{ fieldValue }} </ng-template>
      <ng-template tableTemplate="bodyCell" tableBodyCellField="default" let-fieldValue let-financialYear="rowData">
        <span
          [style.display]="fieldValue ? 'block' : 'none'"
          class="fa fa-check"
          style="text-align: center; color: green">
        </span>
      </ng-template>
      <ng-template tableTemplate="bodyCell" tableBodyCellField="action" let-financialYear="rowData">
        <span
          *ngIf="!client.archived"
          (click)="deleteFinancialYear(financialYear.id)"
          class="far fa-trash-alt text-center">
        </span>
      </ng-template>
      <ng-template tableTemplate="emptyMessage">Inget räkenskapsår skapat ännu...</ng-template>
    </app-table>
  </div>
</ng-container>
