<ng-container *transloco="let t; prefix: scope">
  <app-table
    #table
    storageKey="collaborationPackagesTable"
    [columns]="columns$ | async"
    [data]="list$ | async"
    [rowsPerPage]="defaultNumberOfRows"
    sortField="name"
    [exportFilename]="t('collaborationPackage')">
    <app-table-filter>
      <app-table-filter-item [label]="t('selectionOfCompanyDatabases')">
        <p-dropdown
          [options]="filterOptions$ | async"
          [(ngModel)]="filter.status"
          (ngModelChange)="refilterCollaborationPackageList()"
          optionValue="value"
          styleClass="min-w-24"></p-dropdown>
      </app-table-filter-item>
      <app-table-filter-item [label]="t('loginsInBla')" *appHideIfCountries="'NL'">
        <p-dropdown
          [options]="loginsInBlaFilterOptions$ | async"
          [(ngModel)]="filter.activity"
          (ngModelChange)="refilterCollaborationPackageList()"
          optionValue="value"
          styleClass="min-w-24"></p-dropdown>
      </app-table-filter-item>
      <app-table-filter-item [label]="t('responsibleUser')">
        <app-user-selector
          [(userId)]="selectedClientResponsibleUserId"
          styleClass="min-w-48"
          [disabled]="loading$ | async"></app-user-selector>
      </app-table-filter-item>
      <app-table-filter-item>
        <app-table-refresh-button (onClick)="reloadList()"></app-table-refresh-button>
      </app-table-filter-item>
      <app-table-filter-item fill></app-table-filter-item>
      <app-table-filter-item containerClass="mr-2" *ngIf="hiddenDatabaseMessage$ | async as infoMessage">
        <bl-frontend-alert
          severity="info"
          [content]="infoMessage"
          [useUnsafeHtml]="true"
          (click)="onInfoLinkClickedOpenFirmDataTabInDialog($event)">
        </bl-frontend-alert>
      </app-table-filter-item>
    </app-table-filter>

    <ng-template tableTemplate="bodyCell" tableBodyCellField="default" let-content>
      {{ content }}
    </ng-template>
    <ng-template tableTemplate="bodyCell" tableBodyCellField="action" let-rowData="rowData">
      <div class="flex items-center justify-center">
        <button type="button" class="btn btn-primary py-1" (click)="openActionSelectionDialog(rowData)">{{ 'shared.select' | transloco }}</button>
      </div>
    </ng-template>
    <ng-template tableTemplate="bodyCell" tableBodyCellField="subscriptionTypeId" let-rowData="rowData">
      <app-subscription-type-icon
        [typeId]="rowData?.subscription?.subscriptionTypeId"
        [cloudApiKey]="rowData?.cloudApiKey"></app-subscription-type-icon>
    </ng-template>
    <ng-template tableTemplate="bodyCell" tableBodyCellField="name" let-content let-rowData="rowData">
      <ng-container *ngIf="rowData.existsInFirmSupport; else onlyContent">
        <a (click)="onShowExistingClient(rowData.clientId)" class="cursor-pointer">{{ content }}</a>
      </ng-container>
      <ng-template #onlyContent>{{ content }}</ng-template>
    </ng-template>
    <ng-template tableTemplate="bodyCell" tableBodyCellField="addonModulesName" let-content>
      <div class="h-8 w-full" [pTooltip]="content" [tooltipOptions]="defaultColumnTooltipOptions">{{ content }}</div>
    </ng-template>
    <ng-template tableTemplate="bodyCell" tableBodyCellField="status" let-content>
      <div class="h-8 w-full" [pTooltip]="content" [tooltipOptions]="defaultColumnTooltipOptions">{{ content }}</div>
    </ng-template>
    <ng-template tableTemplate="emptyMessage">{{ statusText$ | async }}</ng-template>
    <ng-template tableTemplate="summary" let-data>
      <div class="relative">
        {{ t('numberOfRows') }}: {{ data.length }}
        <div class="absolute export-button-wrapper">
          <p-button
            icon="pi pi-download"
            class="export-button"
            size="small"
            [label]="t('exportList')"
            (click)="table.exportCSV()"
            [title]="t('downloadListToImportInExcel')"></p-button>
        </div>
      </div>
    </ng-template>
  </app-table>
</ng-container>
