import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TeamActions } from '@app/core/state/team/team.actions';
import { TeamsSelectors } from '@app/core/state/teams/teams.selectors';
import { TeamType, TeamTypes } from '@app/core/state/types';
import { map, Observable, OperatorFunction } from 'rxjs';
import { AddTeamDialogComponent } from './components/add-team-dialog/add-team-dialog.component';
import { EditTeamDialogComponent } from './components/edit-team-dialog/edit-team-dialog.component';
import { TeamRootTeamComponent } from './components/team-root-team/team-root-team.component';
import { TeamsGridComponent } from './components/teams-grid/teams-grid.component';
import { TeamWarningsComponent } from './components/team-warnings/team-warnings.component';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslocoModule } from '@jsverse/transloco';
import { HideIfCountriesDirective } from '@app/shared/directives/hide-if-countries.directive';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  standalone: true,
  imports: [
    CommonModule,
    AddTeamDialogComponent,
    EditTeamDialogComponent,
    TeamsGridComponent,
    TeamRootTeamComponent,
    TeamWarningsComponent,
    TranslocoModule,
    HideIfCountriesDirective,
  ],
  providers: [getTranslationProvider('TeamsComponent')],
})
export class TeamsComponent extends TranslatableComponent {
  teams$: Observable<TeamType[]>;
  clientTeamType: TeamTypes = TeamTypes.Client;
  taskTeamType: TeamTypes = TeamTypes.Task;

  constructor() {
    super();
    this.teams$ = this.store.select(TeamsSelectors.selectTeams).pipe(this.sortTeams());
  }

  onTeamClick(team: TeamType) {
    this.store.dispatch(TeamActions.showEditDialog({ team }));
  }

  onNewClientTeamClick() {
    this.store.dispatch(TeamActions.showAddDialog({ teamType: TeamTypes.Client }));
  }

  onNewTaskTeamClick() {
    this.store.dispatch(TeamActions.showAddDialog({ teamType: TeamTypes.Task }));
  }

  private sortTeams(): OperatorFunction<TeamType[], TeamType[]> {
    return map((teams) => [...(teams ?? [])].sort(this.sortTeamsItems));
  }

  private sortTeamsItems(t1: TeamType, t2: TeamType) {
    if (t1.typeId === TeamTypes.Root) {
      return -1;
    }

    if (t2.typeId === TeamTypes.Root) {
      return -1;
    }

    return t1.name.toLocaleLowerCase() < t2.name.toLocaleLowerCase() ? -1 : 1;
  }
}
