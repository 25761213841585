/* eslint-disable @typescript-eslint/no-explicit-any */
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import {
  DataType,
  FormDialogBaseComponent,
  ResultType,
} from '../components/form-dialog-base/form-dialog-base.component';
import { Type } from '@angular/core';
import { Observable, Subject, first, map, mergeMap } from 'rxjs';
import {
  TranslatableDataType,
  TranslatableFormDialogBaseComponent,
  TranslatableResultType,
} from '@core/i18n/translatable-form-dialog-base.component';
import { TranslocoService } from '@jsverse/transloco';

/**
 *
 * @param dialogService DialogService used to open a dialog containing the `componentType`
 * @param componentType The component type to expose as a dialog
 * @param config This is the data that is passed to the dialog as defined by the `componentType` and the config needed to display the dialog
 * @returns Observable with the return type defined as defined by the `componentType`
 */
export const openDialogWithConfig = <T extends FormDialogBaseComponent<any, any, any>>(
  dialogService: DialogService,
  componentType: Type<T>,
  config: DynamicDialogConfig<DataType<T>>,
): Observable<ResultType<T>> => dialogService.open(componentType, config).onClose.pipe(first());

/**
 *
 * @param dialogService DialogService used to open a dialog containing the `componentType`
 * @param componentType The component type to expose as a dialog
 * @param config This is the data that is passed to the dialog as defined by the `componentType` and the config needed to display the dialog
 * @returns Observable with the return type defined as defined by the `componentType`
 */
export const openTranslatableDialogWithConfig = <T extends TranslatableFormDialogBaseComponent<any, any, any>>(
  dialogService: DialogService,
  componentType: Type<T>,
  config: DynamicDialogConfig<TranslatableDataType<T>>,
): Observable<TranslatableResultType<T>> => dialogService.open(componentType, config).onClose.pipe(first());

/**
 *
 * @param dialogService DialogService used to open a dialog containing the `componentType`
 * @param componentType The component type to expose as a dialog
 * @param data This is the data that is passed to the dialog as defined by the `componentType`
 * @returns Observable with the return type defined as defined by the `componentType`
 */
export const openDialog = <T extends FormDialogBaseComponent<any, any, any>>(
  dialogService: DialogService,
  componentType: Type<T>,
  data: DataType<T>,
): Observable<ResultType<T>> => {
  // Uses overriden static function on Dialog to extract DynamicDialogConfig
  const config: DynamicDialogConfig<DataType<T>> = {
    ...(componentType as any)?.getDialogConfig?.(),
    data,
  };

  return openDialogWithConfig(dialogService, componentType, config);
};

/**
 *
 * @param config.deps.dialogService DialogService used to open a dialog containing the `componentType`
 * @param config.deps.translationService TranslocoService used to get the translations for the dialog
 * @param config.componentType The component type to expose as a dialog
 * @param config.data This is the data that is passed to the dialog as defined by the `componentType`
 * @returns Observable with the return type defined as defined by the `componentType`
 */
export const openTranslatableDialog = <T extends TranslatableFormDialogBaseComponent<any, any, any>>({
  deps,
  componentType,
  data,
}: {
  deps: { dialogService: DialogService; translationService: TranslocoService };
  componentType: Type<T>;
  data: TranslatableDataType<T>;
}): Observable<TranslatableResultType<T>> => {
  const { dialogService, translationService } = deps;
  const subject = new Subject<TranslatableResultType<T>>();
  const scope = (componentType as any)?.getTranslationScope();

  if (!scope) {
    throw new Error('Translation scope must be defined for translatable dialog');
  }

  translationService
    .selectTranslation(scope)
    .pipe(
      first(),
      map((translations): DynamicDialogConfig<TranslatableDataType<T>> => {
        // Uses overriden static function on Dialog to extract DynamicDialogConfig
        return {
          ...(componentType as any)?.getDialogConfig?.(translations),
          data,
        };
      }),
      mergeMap((config) => openTranslatableDialogWithConfig(dialogService, componentType, config)),
      first(),
    )
    .subscribe((dialogResult) => {
      subject.next(dialogResult);
      subject.complete();
    });

  return subject.asObservable();
};
