import { HashMap } from '@jsverse/transloco';
import { createActionGroup, props } from '@ngrx/store';
import { TranslatableStringType } from '../../i18n/types';
import { LanguageType } from '../../entity/locale';

export const i18nActions = createActionGroup({
  source: 'I18n',
  events: {
    'Language Change': props<{ languageCode: LanguageType }>(),
    'Language Changed': props<{ languageCode: LanguageType }>(),

    'Translations Updated': props<{ translations: HashMap<TranslatableStringType> }>(),
  },
});
