import { NameDescriptionType } from '../state/types';
import { TodoSelectableStates, TodoSelectableTypes } from '../state/types/todo.types';
import { Assignment } from './assignment';
import { Client } from './client';
import { User } from './user';

export class Todo {
  public id: number;
  public entryDate: string;
  public deadline: string;
  public text = '';
  public tmpText = '';
  public users: User[];
  public creator: User;
  public client: Client;
  public clientName = '';
  public clientOrgNbr = '';
  public clientCustNbr = '';
  public clientRespName = '';
  public state: NameDescriptionType;
  public statename = '';
  public assignment: Assignment;
  public assignmentname = '';
  public scope: string;
  public usernames = '';
  public selectableStates: NameDescriptionType[] = [...TodoSelectableStates];
  public selectableScopes: string[];
  public type = { description: '', name: '' };
  public selectableTypes = [...TodoSelectableTypes];
}
