import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { AppState } from '@app/core/state/appState';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MonoTypeOperatorFunction, Subject, takeUntil } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DialogData {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DialogResult {}
export type DialogForm<T> = {
  [K in keyof T]: AbstractControl<unknown>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DialogType = FormDialogBaseComponent<any, any, any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataType<T extends DialogType> = T extends FormDialogBaseComponent<infer Data, any, any> ? Data : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ResultType<T extends DialogType> =
  T extends FormDialogBaseComponent<any, infer Result, any> ? Result : never;

@Component({
  selector: 'app-dialog-wrapper',
  template: '',
  standalone: true,
  imports: [],
  providers: [DialogService],
})
export abstract class FormDialogBaseComponent<
    Data extends DialogData = DialogData,
    Result extends DialogResult = DialogResult,
    Form extends DialogForm<Form> = unknown,
  >
  implements OnInit, OnDestroy
{
  form: FormGroup<Form>;
  protected store: Store<AppState>;
  protected builder: FormBuilder;
  protected actions$: Actions;
  protected dialogService: DialogService;
  private onDestroySub: Subject<void>;

  constructor(
    protected ref: DynamicDialogRef,
    protected config: DynamicDialogConfig<Data>,
  ) {
    this.store = inject(Store);
    this.builder = inject(FormBuilder);
    this.actions$ = inject(Actions);
    this.dialogService = inject(DialogService);
    const props = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
    const hasOnInit = props.includes('ngOnInit') && typeof this.ngOnInit === 'function';
    const hasOnDestroy = props.includes('ngOnDestroy') && typeof this.ngOnDestroy === 'function';
    if (hasOnInit || hasOnDestroy) {
      throw new Error(
        'ngOnInit/ngOnDestroy should not be implemented in derived classes of FormDialogBaseComponent, override onInitialized or onDestroyed instead!',
      );
    }

    this.onDestroySub = new Subject();
  }

  ngOnInit(): void {
    this.onInitialized();
  }

  ngOnDestroy(): void {
    this.onDestroyed();
    this.onDestroySub?.next();
    this.onDestroySub?.complete();
  }

  /**
   * Use this in your dialog with any subscriptions that you want to automatically unsubscribe from when the dialog closes
   * @returns MonoTypeOperatorFunction<T>
   */
  takeUntilDestroyed = <T>(): MonoTypeOperatorFunction<T> => takeUntil(this.onDestroySub);

  /**
   * Called last in base class's ngOnInit
   */
  protected onInitialized = () => {};

  /**
   * Called first in base class's ngOnDestroy
   */
  protected onDestroyed = () => {};

  /**
   * Override the onBeforeClose when you need to preprocess things before closing the dialog with `close`
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onBeforeClose = (_result: Result) => {};

  /**
   * Override the onAfterClose when you need to postprocess things after closing the dialog with `close`
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onAfterClose = (_result: Result) => {};

  /**
   * Use the close function from your derived class when closing your dialog
   */
  protected close = (result: Result) => {
    this.onBeforeClose(result);
    this.ref.close(result);
    this.onAfterClose(result);
    this.form?.reset();
  };

  /**
   * Used by the FirmSupport/src/app/shared/misc/openDialog.ts -> openDialog function to extract DialogConfig for a specific Dialog instance
   * Must be overriden or use openDialogWithConfig instead.
   * Can't be used with dynamic- or instance data.
   * @returns Return the dialog configuration that you need to show your dialog
   */

  protected static getDialogConfig(): Omit<DynamicDialogConfig, 'data'> {
    return {};
  }
}
