import { isAdmin } from '@app/core/misc/isAdmin';
import { createSelector } from '@ngrx/store';
import { AppState } from '../appState';
import { i18nFeature, I18nState } from '../i18n/i18n.reducers';
import { toSimpleUserTypeTransformer } from '../transformers/transformers';
import { PrivilegeType, RoleLabelType, RoleType, UserType } from '../types';
import { UsersState } from './users.reducers';
import { TranslationConstants } from '../../i18n/constants';

type Custom = { id: number; translation: { scope: string; key: string } };

const users = (state: AppState) => state.users;
const { selectI18nState } = i18nFeature;

const activeUsersWithCustomUser = (custom: Custom, position: 'first' | 'last' = 'first') =>
  createSelector(users, selectI18nState, activeUsersWithCustomFactory([custom], position));

const activeUsersWithCustomUsers = (custom: Custom[], position: 'first' | 'last' = 'first') =>
  createSelector(users, selectI18nState, activeUsersWithCustomFactory(custom, position));

const translateToName = (i18nState: I18nState, custom: Custom) => {
  return i18nState[custom.translation.scope][custom.translation.key];
};

const activeUsersWithCustomFactory =
  (custom: Custom[], position: 'first' | 'last' = 'first') =>
  (state: UsersState, i18nState: I18nState) => {
    const { users, error } = state;

    if (error) {
      const errorUser: UserType = { ...initialUserType, id: -1, name: error };
      return [errorUser];
    }

    if (!users.length) {
      return []; // important so that the dropdown doesn't choose the custom user below before the users have been loaded
    }

    const customUsers: UserType[] = custom.map((c) => ({
      ...initialUserType,
      id: c.id,
      name: translateToName(i18nState, c),
    }));
    const activeUsers = users.filter((user) => user.active);
    return position === 'first' ? customUsers.concat(activeUsers) : activeUsers.concat(customUsers);
  };

const activeUsersWithNoResponsible = activeUsersWithCustomUser(
  { id: 0, translation: { scope: 'shared', key: 'noResponibleUser' } },
  'last',
);

const activeUsersWithMissingResponsible = activeUsersWithCustomUser(
  { id: -1, translation: { scope: 'shared', key: 'missingResponsibleUser' } },
  'last',
);

const activeUsersWithClientResponsible = activeUsersWithCustomUser(
  { id: -1, translation: { scope: 'constants', key: TranslationConstants.CLIENT_RESPONSIBLE } },
  'last',
);

const activeUsersWithClientResponsibleAndLoggedInUser = activeUsersWithCustomUsers(
  [
    { id: -1, translation: { scope: 'constants', key: TranslationConstants.CLIENT_RESPONSIBLE } },
    { id: -2, translation: { scope: 'constants', key: TranslationConstants.LOGGED_IN_USER } },
  ],
  'last',
);

const activeUsersWithChoose = activeUsersWithCustomUser(
  { id: 0, translation: { scope: 'constants', key: TranslationConstants.SELECT_WITH_HYPHENS } },
  'first',
);

const activeUsersWithAll = activeUsersWithCustomUser(
  { id: 0, translation: { scope: 'constants', key: TranslationConstants.ALL_WITH_HYPHENS } },
  'first',
);

const getUsers = (state: UsersState) => {
  const { users, error } = state;
  if (error) {
    const errorUser: UserType = { ...initialUserType, id: -1, name: error };
    return [errorUser];
  }

  return users.filter((user) => user.active);
};

const activeUsersWithoutAll = createSelector(users, getUsers);

const activeUsersWithoutAllAsSimpleUser = createSelector(users, (state) => {
  return getUsers(state).map((user) => toSimpleUserTypeTransformer.transform(user));
});

const activeUsersWithoutAllAndAdmin = createSelector(activeUsersWithoutAll, (users) =>
  users.filter((user) => !isAdmin(user)),
);

const allUsers = createSelector(users, (userState) => userState.users);

const activeAdmins = createSelector(allUsers, (users) => users.filter((u) => u.active && isAdmin(u)));

const activeAdminsAsSimpleUser = createSelector(activeAdmins, (users) =>
  users.map(toSimpleUserTypeTransformer.transform),
);

const activeUsersWithoutTeamsAsSimpleUser = createSelector(activeUsersWithoutAll, (activeUsers) => {
  return activeUsers
    .filter((user) => !isAdmin(user))
    .filter((user) => !user.teams || user.teams.length === 0)
    .map((user) => toSimpleUserTypeTransformer.transform(user));
});

export const initialUserType: UserType = {
  id: -1,
  name: '',
  active: false,
  email: '',
  firm: { name: '', corporateIdentity: '' },
  initials: '',
  role: RoleType.FIRM_USER,
  roleLabel: RoleLabelType.FIRM_USER,
  username: '',
  privilege: PrivilegeType.RW,
  partnerProgram: false,
  languageCode: 'sv',
};

export const UserSelectors = {
  allUsers,
  activeUsersWithNoResponsible,
  activeUsersWithMissingResponsible,
  activeUsersWithClientResponsible,
  activeUsersWithChoose,
  activeUsersWithAll,
  activeUsersWithoutAll,
  activeUsersWithoutAllAsSimpleUser,
  activeUsersWithoutAllAndAdmin,
  activeUsersWithClientResponsibleAndLoggedInUser,
  activeAdmins,
  activeAdminsAsSimpleUser,
  activeUsersWithoutTeamsAsSimpleUser,
};
