import { createFeature, createReducer, on } from '@ngrx/store';
import { TranslatableStringType } from '../../i18n/types';
import { mapTranslationToTranslatableString } from '../../i18n/utils';
import { i18nActions } from './i18n.actions';
import { HashMap } from '@jsverse/transloco';

export interface I18nState {
  shared: Record<string, TranslatableStringType>;
  constants: Record<string, TranslatableStringType>;
  errors: Record<string, TranslatableStringType>;
}

const initialState: I18nState = {
  shared: {},
  constants: {},
  errors: {},
};

const ROOT_TRANSLATION_KEYS = ['shared', 'constants', 'errors'];

const startsWithRootPrefixes = (str: string): boolean => ROOT_TRANSLATION_KEYS.some((prefix) => str.startsWith(prefix));

const filterRootTranslations = (translations: Record<string, string>) =>
  Object.entries(translations).reduce(
    (acc, [key, value]) => {
      if (startsWithRootPrefixes(key)) {
        const [prefix, keyWithoutPrefix] = key.split('.');
        acc[prefix][keyWithoutPrefix] = value;
      }
      return acc;
    },
    { shared: {}, constants: {}, errors: {} },
  );

const filterToStateTranslation = (translations: HashMap<TranslatableStringType>): I18nState =>
  mapTranslationToTranslatableString(filterRootTranslations(translations));

export const i18nFeature = createFeature({
  name: 'i18n',
  reducer: createReducer(
    initialState,
    on(i18nActions.translationsUpdated, (state, { translations }) => ({
      ...state,
      ...filterToStateTranslation(translations),
    })),
  ),
});
