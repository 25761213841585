import { createReducer, on } from '@ngrx/store';
import { AssignmentType } from '../types';
import { AssignmentActions } from './assignments.actions';

export interface AssignmentsState {
  assignments: AssignmentType[];
  error: unknown;
}

export const initialAssignmentsState: AssignmentsState = { assignments: [], error: null };

export const assignmentsReducer = createReducer(
  initialAssignmentsState,
  on(AssignmentActions.loadAllAssignments, () => initialAssignmentsState),
  on(AssignmentActions.loadAssignmentsSucceeded, (_state, { assignments }) => ({
    ...initialAssignmentsState,
    assignments,
  })),
  on(AssignmentActions.loadAssignmentsFailed, (_state, { error }) => ({ ...initialAssignmentsState, error })),
);
