import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { CheckboxProps } from '@bl/components';
import { BlFrontendBaseComponent } from './bl-frontend-base.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorFn } from '../state/angularTypes';

export interface CheckboxEvents extends Record<string, unknown> {
  change: Event;
}

@Component({
  selector: 'bl-frontend-checkbox',
  template: '<div></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => BlFrontendCheckboxComponent) }],
})
export class BlFrontendCheckboxComponent
  extends BlFrontendBaseComponent<CheckboxProps, CheckboxEvents>
  implements ControlValueAccessor
{
  disabled = false;
  @Input() text: CheckboxProps['text'];
  @Input() description: CheckboxProps['description'] = '';
  @Input() name: CheckboxProps['name'];
  @Input() value: CheckboxProps['value'];
  @Input() brand: CheckboxProps['brand'] = 'firmsupport';
  @Input() checked: CheckboxProps['checked'] = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor(
    protected override ref: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(ref);
  }

  onChange: ControlValueAccessorFn = () => {};
  onTouched: ControlValueAccessorFn = () => {};

  writeValue(value?: boolean | null): void {
    this.checked = value;
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(onChange: ControlValueAccessorFn) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: ControlValueAccessorFn) {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  protected override getComponentName(): string {
    return 'Checkbox';
  }

  protected override getProps(): CheckboxProps {
    return {
      text: this.text,
      description: this.description,
      name: this.name,
      value: this.value,
      brand: this.brand,
      checked: this.checked,
      onchange: (event: Event) => this.onCheckboxChange(event),
    };
  }

  private onCheckboxChange(event: Event) {
    const { checked } = event?.target as HTMLInputElement;
    this.onChange(checked);
    this.onTouched();
    this.change.emit(checked);
    this.changeDetectorRef.markForCheck();
  }
}
