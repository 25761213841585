import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state/appState';
import { CountryType } from '../entity/locale';
import { FirmSelectors } from '../state/firm/firm.selectors';
import { map } from 'rxjs';
import { asArray } from '../misc/as-array';
import { CanActivateFn, Router } from '@angular/router';
import { takeFirstTruthy } from '../misc/rxjs-operators';

export const countryGuard =
  (validCountryCodes: CountryType | CountryType[]): CanActivateFn =>
  () => {
    const store = inject(Store<AppState>);
    const router = inject(Router);

    return store.select(FirmSelectors.selectCountryCode).pipe(
      takeFirstTruthy(),
      map((countryCode) => {
        const isCountryValid = asArray(validCountryCodes).includes(countryCode);
        return isCountryValid ? true : router.parseUrl('/start');
      }),
    );
  };
