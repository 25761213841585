import { DialogService } from 'primeng/dynamicdialog';
import { first, mergeMap, Observable } from 'rxjs';
import { TodoDialogComponent, TodoDialogResult } from '@app/todo/todo.dialog';
import { openTranslatableDialog } from './openDialog';
import { NotesService } from '@app/core/services/notes.service';
import { initialTodoType } from '@app/core/state/types/todo.types';
import { SimpleClientType } from '@app/core/state/types';
import { TranslocoService } from '@jsverse/transloco';

export const showTodoDialog = ({
  deps,
  id,
  client,
}: {
  deps: {
    notesService: NotesService;
    dialogService: DialogService;
    translationService: TranslocoService;
  };
  id: number;
  client?: SimpleClientType;
}): Observable<TodoDialogResult> => {
  const { notesService, ...otherDependencies } = deps;

  if (id === -1) {
    return openTranslatableDialog({
      deps: otherDependencies,
      componentType: TodoDialogComponent,
      data: { todo: { ...initialTodoType, client: client ?? null } },
    });
  }

  return notesService.getById(id).pipe(
    first(),
    mergeMap((todo) =>
      openTranslatableDialog({
        deps: otherDependencies,
        componentType: TodoDialogComponent,
        data: { todo },
      }),
    ),
  );
};
