import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TeamsSelectors } from '@app/core/state/teams/teams.selectors';
import { SimpleUserType, TeamType } from '@app/core/state/types';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { BlFrontendBadgeComponent } from '@app/core/wrappers/bl-frontend-badge.component';
import { AdminAvatarComponent } from '@app/shared/components/admin-avatar/admin-avatar.component';
import { UserAvatarComponent } from '@app/shared/components/user-avatar/user-avatar.component';
import { TooltipModule } from 'primeng/tooltip';
import { Observable } from 'rxjs';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-team-root-team',
  template: `
    <ng-container *transloco="let t; prefix: this.scope">
      <p class="font-semibold text-lg mt-8 mb-1">{{ t('teamSettings') }}</p>
      <p class="mb-7 text-bl-grey-600">{{ t('overviewWhichCanEdit') }}</p>
      <div class="border border-bl-grey-200 rounded-lg mt-2 flex flex-col shadow-sm ">
        <div class="p-3 pt-5 border-bl-blue-200 flex items-baseline">
          <p class="text-base text-bl-grey-700 font-normal">{{ t('firmAdmin') }}</p>
          <i
            class="fal fa-question-circle fa-lg text-bl-blue-light-500 px-2 cursor-help"
            [pTooltip]="t('adminHasRightToEditAllTeam')"></i>
        </div>
        <div class="mx-3 mb-2 border-t border-bl-grey-200"></div>
        <div class="p-3">
          <app-admin-avatar [users]="admins$ | async"></app-admin-avatar>
        </div>
      </div>
      <div
        class="border border-bl-grey-200 rounded-lg mt-8 flex flex-col shadow-sm"
        [ngClass]="{ 'cursor-pointer  hover:shadow-lg': enabled, 'cursor-not-allowed': !enabled }"
        (click)="enabled ? teamClick.emit(rootTeam) : undefined">
        <div class="p-3 pt-5 border-bl-blue-200 flex justify-between">
          <div class="flex items-baseline">
            <p class="text-base text-bl-grey-700 font-normal" *transloco="let t2; prefix: 'constants'">
              {{ t2('ROOT_TEAM_NAME') }}
            </p>
            <i
              *ngIf="enabled"
              class="fal fa-question-circle fa-lg text-bl-blue-light-500 px-2 cursor-help"
              [pTooltip]="t('employeeInRootTeamHasAccessToSeeAllClientsTooltip1')"></i>
            <i
              *ngIf="!enabled"
              class="fal fa-question-circle fa-lg text-bl-blue-light-500 px-2 cursor-help"
              [pTooltip]="t('employeeInRootTeamHasAccessToSeeAllClientsTooltip2')"></i>
          </div>
          <div *ngIf="enabled">
            <i class="far fa-arrows-v fa-lg px-2 origin-center transform rotate-45 text-bl-grey-500"></i>
          </div>
        </div>
        <div class="mx-3 mb-2 border-t border-bl-grey-200"></div>
        <div class="p-3">
          <p>{{ t('employee') }}</p>
          <app-user-avatar [users]="users$ | async" [maxVisible]="15"></app-user-avatar>
        </div>
        <div class="p-3 pt-1 mb-5">
          <p>{{ t('clients') }}</p>
          <bl-frontend-badge [text]="rootTeam.clients.count?.toString()" variant="info"></bl-frontend-badge>
        </div>
      </div>
    </ng-container>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TooltipModule,
    AdminAvatarComponent,
    UserAvatarComponent,
    BlFrontendBadgeComponent,
    TranslocoModule,
  ],
  providers: [getTranslationProvider('TeamRootTeamComponent')],
})
export class TeamRootTeamComponent extends TranslatableComponent implements OnInit {
  @Output() teamClick: EventEmitter<TeamType>;

  rootTeam: TeamType;
  enabled = false;
  admins$: Observable<SimpleUserType[]>;
  users$: Observable<SimpleUserType[]>;

  constructor() {
    super();
    this.teamClick = new EventEmitter<TeamType>();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.admins$ = this.store.select(UserSelectors.activeAdminsAsSimpleUser);
    this.users$ = this.store.select(TeamsSelectors.selectRootUsersAsSimpleUser);
    this.store
      .select(TeamsSelectors.selectRootTeam)
      .pipe(this.takeUntilDestroyed())
      .subscribe((rootTeam) => {
        this.rootTeam = rootTeam;
      });
    this.store
      .select(TeamsSelectors.selectTeams)
      .pipe(this.takeUntilDestroyed())
      .subscribe((teams) => {
        this.enabled = teams?.length > 1;
      });
  }
}
