<form [formGroup]="form">
  <div>
    <div class="flex h-28">
      <div class="flex flex-col mr-1">
        <label for="span.start">Startdatum</label>
        <app-calendar
          [formControl]="form.controls.span.controls.start"
          [invalid]="form.controls.span.controls.start.errors?.start?.message"
          appendTo="body"
          inputId="span.start"></app-calendar>
      </div>
      <div class="flex flex-col ml-1">
        <label for="span.start">Slutdatum</label>
        <app-calendar
          [formControl]="form.controls.span.controls.end"
          [invalid]="form.controls.span.controls.end.errors?.end?.message"
          appendTo="body"
          inputId="span.end"></app-calendar>
      </div>
    </div>
    <ng-container *appHideIfCountries="'NL'">
      <div class="h-24">
        <p class="font-semibold">Arbetsgivar- och momsdeklaration</p>
        <div class="flex ml-1">
          <p-checkbox [formControl]="form.controls.largeCompany" [binary]="true" inputId="largeCompany"></p-checkbox>
          <label for="largeCompany" class="mb-0 ml-4">Inlämning som stort företag</label>
        </div>
      </div>
      <div class="h-24">
        <p class="font-semibold">Momsdeklaration</p>
        <div class="flex justify-between">
          <div class="flex flex-1 ml-1">
            <p-checkbox
              [formControl]="form.controls.electronicVat"
              [binary]="true"
              inputId="electronicVat"></p-checkbox>
            <label for="electronicVat" class="mb-0 ml-4">Lämnas elektroniskt</label>
          </div>
          <div class="flex flex-1 ml-1">
            <p-checkbox [formControl]="form.controls.euCommerce" [binary]="true" inputId="euCommerce"></p-checkbox>
            <label for="euCommerce" class="mb-0 ml-4">Haft EU-handel</label>
          </div>
        </div>
      </div>
      <div class="h-24">
        <p class="font-semibold">Periodisk sammanställning</p>
        <div class="flex ml-1">
          <p-checkbox
            [formControl]="form.controls.electronicCompilation"
            [binary]="true"
            inputId="electronicCompilation"></p-checkbox>
          <label for="electronicCompilation" class="mb-0 ml-4">Lämnas elektroniskt</label>
        </div>
      </div>
      <div class="h-24">
        <p class="font-semibold">Inkomstdeklaration</p>
        <div class="flex justify-between">
          <div class="flex flex-1 ml-1">
            <p-checkbox
              [formControl]="form.controls.firmTaxRespite"
              [binary]="true"
              inputId="firmTaxRespite"></p-checkbox>
            <label for="firmTaxRespite" class="mb-0 ml-4">Byråanstånd</label>
          </div>
          <div class="flex flex-1 ml-1">
            <p-checkbox
              [formControl]="form.controls.electronicTax"
              [binary]="true"
              inputId="electronicTax"></p-checkbox>
            <label for="electronicTax" class="mb-0 ml-4">Lämnas elektroniskt</label>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="flex items-end float-right">
    <div class="flex justify-end">
      <bl-frontend-button variant="secondary" (click)="close(null)" text="Stäng" class="mr-2"></bl-frontend-button>
      <bl-frontend-button [disabled]="form.invalid" (click)="onSubmit()" text="Spara"></bl-frontend-button>
    </div>
  </div>
</form>
