import { translate } from '@jsverse/transloco';

export interface IncludeFinishedLabelProps<T> {
  label: string;
  completeList: T[];
  predicate: (item: T) => boolean;
}

export function getIncludeFinishedLabel<T>({ label, completeList, predicate }: IncludeFinishedLabelProps<T>): string {
  const filteredListCount = completeList?.filter(predicate).length ?? 0;
  const pieces = translate('shared.pieces');
  return `${label} (${filteredListCount} ${pieces})`;
}
