import { ClientType, FinancialYearType } from '../types';
import { DialogData, DialogResult } from '@app/shared/components/form-dialog-base/form-dialog-base.component';

export enum ActionType {
  ONBOARD = 'onboard',
  ONBOARD_WITH_MINI = 'onboard_with_mini',
  CHANGE_PACKAGE_ON_NEW_SUBSCRIPTION = 'change_package_on_new_subscription',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  DELETE = 'delete',
}

export enum ClientActionType {
  SKIP = 'skip',
  ARCHIVE = 'archive',
  DELETE = 'delete',
}

export enum AppModuleType {
  BUNDLE = 'BUNDLE',
  MODULE = 'MODULE',
  INTEGRATION = 'INTEGRATION',
  UNKNOWN = 'UNKNOWN',
}

export interface Data extends DialogData, CollaborationPackageItem {}

export interface Result extends DialogResult {
  result: ActionType | null;
}

export interface ActionStrategyParams {
  cloudApiKey: string;
  clientId: number | null;
  name: string | null;
  email: string | null;
  years: number | null;
  socialSecurityNumber: string | null;
  clientAction: ClientActionType;
}

export interface ActionStrategy {
  type: ActionType;
  primaryButtonI18nKey: string;
  execute: (config: ActionStrategyParams) => void;
}

export type AppModule = {
  id: string;
  name: string;
  type: AppModuleType;
};

export type AppIntegration = {
  id: string | null;
  name: string;
  logotypeUrl: string | null;
};

export type AppSubscription = {
  subscriptionTypeId: number;
  modules: AppModule[];
  integrations: AppIntegration[];
};

export type CollaborationPackageResponse = {
  meta: {
    hasHiddenDatabases: boolean;
    numberOfCollaborationPackages: number;
    numberOfMissingCollaborationPackages: number;
  };
  data: CollaborationPackageItem[];
};

export type CollaborationPackageItem = {
  archived: boolean;
  archivedTo: string;
  archivedInFirmSupport: boolean;
  clientId: number | null;
  cloudApiKey: string;
  corporateIdentity: string;
  email: string | null;
  existsInFirmSupport: boolean;
  financialYear: FinancialYearType | null;
  firmCustomerNbr: number | null;
  hidden: boolean;
  payingByFirm: boolean;
  name: string;
  payingCustomerNbr: number | null;
  subscription: AppSubscription | null; // blapp package info
  systemNote: string | null;
  canUseOnboarding: boolean;
  canUseSimpleOnboarding: boolean;
  canChangePackage: boolean;
  lastLogin: string | null;
};

export type CollaborationPackageItemState = {
  email: string | null;
  client: ClientType | null;
  meta: CollaborationPackageResponse['meta'];
  error: unknown;
};

export type CollaborationPackageStatusFilter =
  | 'all'
  | 'allExceptArchived'
  | 'allWithoutSubscription'
  | 'allArchived'
  | 'allWithFirmsupport'
  | 'allWithoutFirmsupport'
  | 'allWithNewSubscription'
  | 'allWithNewProSubscription'
  | 'allWithNewEssentialSubscription'
  | 'allWithNewGoSubscription'
  | 'allWithNewMiniSubscription';

export type CollaborationPackageActivityFilter =
  | 'all'
  | 'noActivity'
  | 'activityCurrentWeek'
  | 'activityCurrentMonth'
  | 'activityCurrentYear';

export interface CollaborationPackageFilter {
  status: CollaborationPackageStatusFilter;
  activity: CollaborationPackageActivityFilter;
}

export interface CollaborationPackageParams {
  responsibleId: number;
}
