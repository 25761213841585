import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CollaborationPackageSelectors } from '@app/core/state/collaboration-packages/collaboration-packages.selectors';
import {
  ActionStrategy,
  ActionStrategyParams,
  ActionType,
  ClientActionType,
  Data,
  Result,
} from '@app/core/state/types/collaboration-package.types';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { BlFrontendRadiobuttonsComponent } from '@app/core/wrappers/bl-frontend-radiobuttons.component';
import { validateEmail } from '@app/shared/misc/email-validator';
import { RadioButtonProps } from '@bl/components';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { BehaviorSubject, filter } from 'rxjs';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableFormDialogBaseComponent } from 'src/app/core/i18n/translatable-form-dialog-base.component';
import { BlFrontendAlertComponent } from '../../../core/wrappers/bl-frontend-alert.component';
import { createStrategyFactory } from './action-strategy-factory';
import { archiveWarningMessage, showArchiveWarningIfRecentlyUsed } from './archive-database-helper';
import { initializeActionRadioButtons, initializeClientActionRadioButtons } from './radio-buttons-helper';
import { validateSocialSecurityNumber, validateYears } from './validation-helper';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { ActionSelectorDialogComponentTranslations } from './types';

const TRANSLATION_SCOPE = 'actionSelectorDialogComponent';

@Component({
  selector: 'app-action-selector-dialog',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './action-selector-dialog.component.html',
  providers: [getTranslationProvider(TRANSLATION_SCOPE)],
  imports: [
    CommonModule,
    FormsModule,
    BlFrontendRadiobuttonsComponent,
    BlFrontendButtonComponent,
    InputTextModule,
    CheckboxModule,
    BlFrontendAlertComponent,
    SafeHtmlPipe,
    TranslocoModule,
  ],
})
export class ActionSelectorDialogComponent extends TranslatableFormDialogBaseComponent<Data, Result> {
  radioButtonsItems: RadioButtonProps[];
  clientActionRadioButtonsItems: RadioButtonProps[];
  email: BehaviorSubject<string> = new BehaviorSubject<string>('');
  years: BehaviorSubject<number> = new BehaviorSubject<number>(7);
  socialSecurityNumber: BehaviorSubject<string> = new BehaviorSubject<string>('');
  clientAction: BehaviorSubject<ClientActionType> = new BehaviorSubject<ClientActionType>(ClientActionType.SKIP);
  confirmArchive = false;
  confirmDelete = false;
  confirmUnarchive = false;

  private strategyFactory = createStrategyFactory(this.store);
  private currentStrategy: ActionStrategy;

  get companyName(): string {
    return this.config.data.name;
  }

  get existsInFirmSupport(): boolean {
    return this.config.data.existsInFirmSupport;
  }

  get isOnboardSelected(): boolean {
    return this.currentStrategy.type === ActionType.ONBOARD;
  }

  get isOnboardAsMiniSelected(): boolean {
    return this.currentStrategy.type === ActionType.ONBOARD_WITH_MINI;
  }

  get isChangePackageSelected(): boolean {
    return this.currentStrategy.type === ActionType.CHANGE_PACKAGE_ON_NEW_SUBSCRIPTION;
  }

  get isArchivedSelected(): boolean {
    return this.currentStrategy.type === ActionType.ARCHIVE;
  }

  get isUnarchiveSelected(): boolean {
    return this.currentStrategy.type === ActionType.UNARCHIVE;
  }

  get isDeleteSelected(): boolean {
    return this.currentStrategy.type === ActionType.DELETE;
  }

  get primaryButtonI18nKey(): string {
    return this.currentStrategy.primaryButtonI18nKey;
  }

  get showArchiveWarningIfRecentlyUsed(): boolean {
    return showArchiveWarningIfRecentlyUsed(this.config.data.lastLogin);
  }

  get archiveWarningText(): string {
    return archiveWarningMessage(this.translate, this.config.data.lastLogin);
  }

  get isValid(): boolean {
    const validationMap = {
      [ActionType.ONBOARD]: () => validateEmail(this.email.value),
      [ActionType.ONBOARD_WITH_MINI]: () => validateSocialSecurityNumber(this.socialSecurityNumber.value),
      [ActionType.CHANGE_PACKAGE_ON_NEW_SUBSCRIPTION]: () => true,
      [ActionType.ARCHIVE]: () => validateYears(this.years.value) && this.confirmArchive,
      [ActionType.UNARCHIVE]: () =>
        validateSocialSecurityNumber(this.socialSecurityNumber.value) && this.confirmUnarchive,
      [ActionType.DELETE]: () => this.confirmDelete,
    };

    return validationMap[this.currentStrategy.type]?.() ?? false;
  }

  constructor(
    dynamicDialogRef: DynamicDialogRef,
    dynamicDialogConfig: DynamicDialogConfig<Data>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(dynamicDialogRef, dynamicDialogConfig);
  }

  protected static override getTranslationScope(): string {
    return TRANSLATION_SCOPE;
  }

  protected static override getDialogConfig(
    translation: ActionSelectorDialogComponentTranslations,
  ): Omit<DynamicDialogConfig<Data>, 'data'> {
    return {
      header: translation.actionSelectorHeadline,
      modal: true,
      dismissableMask: true,
      draggable: false,
      width: '530px',
    };
  }

  protected override onInitialized: () => void = () => {
    // initialize the radio buttons and gets the default strategy
    this.radioButtonsItems = initializeActionRadioButtons(this.config.data, this.translate);
    this.clientActionRadioButtonsItems = initializeClientActionRadioButtons(false, this.translate);
    this.currentStrategy = this.initializeStrategy();

    this.listenForEmailChangesInStore();
    this.listenForClientChangesInStore();
  };

  actionChanged(value: string): void {
    this.confirmArchive = false;
    this.confirmDelete = false;
    this.confirmUnarchive = false;

    // update the current strategy from the selected radio button value
    this.currentStrategy = this.strategyFactory(value as ActionType);
  }

  clientActionChanged(value: string): void {
    this.clientAction.next(value as ClientActionType);
  }

  next(): void {
    const config: ActionStrategyParams = {
      cloudApiKey: this.config.data.cloudApiKey,
      clientId: this.config.data.clientId,
      name: this.config.data.name,
      email: this.email.value,
      years: this.years.value,
      socialSecurityNumber: this.socialSecurityNumber.value,
      clientAction: this.clientAction.value,
    };

    this.currentStrategy.execute(config);
    this.close({ result: this.currentStrategy.type });
  }

  private initializeStrategy(): ActionStrategy {
    const selectedActionType = this.radioButtonsItems.find((item) => item.checked)?.value as ActionType;
    return this.strategyFactory(selectedActionType);
  }

  private listenForEmailChangesInStore(): void {
    this.store
      .select(CollaborationPackageSelectors.selectEmail)
      .pipe(this.takeUntilDestroyed())
      .subscribe({ next: (value) => this.email.next(value) });
  }

  private listenForClientChangesInStore(): void {
    this.store
      .select(CollaborationPackageSelectors.selectClient)
      .pipe(
        filter((client) => !!client),
        this.takeUntilDestroyed(),
      )
      .subscribe({
        next: (client) => {
          this.clientActionRadioButtonsItems = initializeClientActionRadioButtons(
            Boolean(client?.deleteable),
            this.translate,
          );
          this.changeDetectorRef.markForCheck();
        },
      });
  }
}
