import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClientService } from '@app/core/services/clients.service';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { CollaborationPackageActions } from '@app/core/state/collaboration-packages/collaboration-packages.actions';
import { CollaborationPackageSelectors } from '@app/core/state/collaboration-packages/collaboration-packages.selectors';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { ListActions } from '@app/core/state/list/list.actions';
import { ListSelectors } from '@app/core/state/list/list.selectors';
import {
  AppModule,
  AppModuleType,
  AppSubscription,
  CollaborationPackageActivityFilter,
  CollaborationPackageFilter,
  CollaborationPackageItem,
  CollaborationPackageStatusFilter,
} from '@app/core/state/types/collaboration-package.types';
import { UserSettingsStorage } from '@app/core/storage/user.settings.storage';
import { BlFrontendAlertComponent } from '@app/core/wrappers/bl-frontend-alert.component';
import { FirmSettingsDialog } from '@app/firm-settings/firm.settings.dialog';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { HideIfCountriesDirective } from '@app/shared/directives/hide-if-countries.directive';
import { getLocalIsoDateWithTime } from '@app/shared/misc/dates';
import { openTranslatableDialog } from '@app/shared/misc/openDialog';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { TranslocoModule } from '@jsverse/transloco';
import { TooltipOptions } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, combineLatest, first, map } from 'rxjs';
import { getTranslationProvider } from '../core/i18n/getTranslationProvider';
import { TranslatableComponent } from '../core/i18n/TranslatableComponent';
import { translateColumnHeaders } from '../shared/components/table/misc/translate-column-header';
import { CollaborationPackageListTranslation, TableCloudDbItem } from './collaboration-packages.types';
import { ActionSelectorDialogComponent } from './components/action-selector-dialog/action-selector-dialog.component';
import { SubscriptionTypeIconComponent } from './components/subscripton-type-icon/subscription-type-icon.component';

@Component({
  templateUrl: './collaboration-packages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    BystSharedModule,
    BlFrontendAlertComponent,
    SubscriptionTypeIconComponent,
    FormsModule,
    HideIfCountriesDirective,
    TranslocoModule,
  ],
  providers: [getTranslationProvider('CollaborationPackagesComponent')],
  styles: [
    `
      .max-55em {
        max-width: 55em;
      }
    `,
  ],
})
export class CollaborationPackagesComponent
  extends TranslatableComponent<CollaborationPackageListTranslation>
  implements OnInit, OnDestroy
{
  filter: CollaborationPackageFilter = { status: 'allExceptArchived', activity: 'all' };
  defaultColumns: TableColumn[] = [
    { field: 'action', header: 'managePackages', selectable: false, sortable: false, width: '130px' },
    { field: 'subscriptionTypeId', header: 'collaborationPackage', width: '100px' },
    { field: 'subscriptionBundleName', header: 'package', width: '130px' },
    { field: 'addonModulesName', header: 'addonModules', width: '170px' },
    { field: 'name', header: 'name' },
    { field: 'corporateIdentity', header: 'orgNumber', width: '150px' },
    { field: 'responsibleUser.name', header: 'responsibleUser' },
    {
      field: 'financialYearText',
      header: 'financialYear',
      visible: false,
    },
    { field: 'systemNote', header: 'systemNote', hiddenForCountries: ['NL'] },
    { field: 'lastLogin', header: 'lastBlaLoginDate', visible: true, hiddenForCountries: ['NL'] },
    { field: 'email', header: 'email', visible: false },
    { field: 'status', header: 'status' },
    { field: 'paying', header: 'paying', width: '100px' },
    { field: 'cloudApiKey', header: 'cloudDatabaseKey' },
  ];

  columns$: Observable<TableColumn[]>;
  defaultNumberOfRows = 50;
  selectedClientResponsibleUserId: number | undefined = 0;
  defaultColumnTooltipOptions: TooltipOptions = { tooltipPosition: 'top', tooltipStyleClass: 'tooltip-extended-width' };

  filterOptions$: Observable<{ label: string; value: string }[]>;
  loginsInBlaFilterOptions$: Observable<{ label: string; value: string }[]>;
  list$: Observable<TableCloudDbItem[]>;
  loading$: Observable<boolean>;
  statusText$: Observable<string>;
  hiddenDatabaseMessage$: Observable<string>;

  private isBundle = (module: AppModule) => module.type?.toUpperCase() === AppModuleType.BUNDLE;
  private findBundleName = (subscription: AppSubscription | null) =>
    subscription?.modules?.find(this.isBundle)?.name ?? '';

  constructor(
    private dialogService: DialogService,
    private clientService: ClientService,
  ) {
    super();

    this.columns$ = this.onTranslationChangeMapTo(translateColumnHeaders(this.defaultColumns));
    this.filterOptions$ = this.onTranslationChangeMapTo(this.translateFilterOptions);
    this.loginsInBlaFilterOptions$ = this.onTranslationChangeMapTo(this.translateBlaLoginFilterOptions);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.loadSettings();
    this.loading$ = this.store.select(ListSelectors.isLoading);
    this.statusText$ = this.store.select(ListSelectors.getStatusText);
    this.list$ = this.getListObservable();
    this.hiddenDatabaseMessage$ = this.getHiddenDatabaseMessageAsObservable();
    this.reloadList();
  }

  override ngOnDestroy(): void {
    this.saveSettings();
    super.ngOnDestroy();
  }

  openActionSelectionDialog(data: CollaborationPackageItem) {
    const { clientId, cloudApiKey } = data;

    this.store.dispatch(CollaborationPackageActions.clearState({ excludeMeta: true }));
    this.store.dispatch(CollaborationPackageActions.loadEmailForClient({ clientId, cloudApiKey }));
    this.store.dispatch(CollaborationPackageActions.loadClient({ clientId }));

    openTranslatableDialog({
      deps: { dialogService: this.dialogService, translationService: this.translationService },
      componentType: ActionSelectorDialogComponent,
      data,
    });
  }

  reloadList() {
    this.store.dispatch(
      CollaborationPackageActions.load({
        filter: { ...this.filter },
        params: { responsibleId: this.selectedClientResponsibleUserId },
      }),
    );
  }

  refilterCollaborationPackageList() {
    this.store.dispatch(
      ListActions.filterList({
        filter: { ...this.filter },
      }),
    );
  }

  onShowExistingClient(clientId: number) {
    showClientDialog({
      clientService: this.clientService,
      dialogService: this.dialogService,
      id: clientId,
      store: this.store,
    })
      .pipe(first())
      .subscribe();
  }

  onInfoLinkClickedOpenFirmDataTabInDialog(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target as HTMLElement;
    if (target.tagName !== 'A') {
      return;
    }
    openTranslatableDialog({
      deps: { dialogService: this.dialogService, translationService: this.translationService },
      componentType: FirmSettingsDialog,
      data: { tabIdToOpen: 'tabFirmData' },
    });
  }

  private loadSettings() {
    const uss = new UserSettingsStorage();

    this.defaultNumberOfRows = uss.loadSettingAsNumber(
      UserSettingsStorage.COLLABORATION_PACKAGES_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.filter = {
      status: uss.loadSetting(
        UserSettingsStorage.COLLABORATION_PACKAGES_STATUS_FILTER,
        'allExceptArchived',
      ) as CollaborationPackageStatusFilter,
      activity: uss.loadSetting(
        UserSettingsStorage.COLLABORATION_PACKAGES_ARCHIVED_FILTER,
        'all',
      ) as CollaborationPackageActivityFilter,
    };
    this.selectedClientResponsibleUserId = uss.loadSettingAsNumber(
      UserSettingsStorage.COLLABORATION_PACKAGES_RESPONSIBLE_ID,
      0,
    );
  }

  private saveSettings() {
    const uss = new UserSettingsStorage();

    uss.saveSetting(UserSettingsStorage.COLLABORATION_PACKAGES_NUMBER_OF_ROWS, `${this.defaultNumberOfRows}`);
    uss.saveSetting(UserSettingsStorage.COLLABORATION_PACKAGES_STATUS_FILTER, this.filter.status);
    uss.saveSetting(UserSettingsStorage.COLLABORATION_PACKAGES_ARCHIVED_FILTER, this.filter.activity);
    uss.saveSetting(
      UserSettingsStorage.COLLABORATION_PACKAGES_RESPONSIBLE_ID,
      `${this.selectedClientResponsibleUserId}`,
    );
  }

  private getListObservable(): Observable<TableCloudDbItem[]> {
    return combineLatest([
      this.store.select(CollaborationPackageSelectors.selectFilteredItems),
      this.translationsLoaded$,
    ]).pipe(
      map(([items, translations]) =>
        items.map((item) => ({
          ...item,
          status: this.createStatusText(item, translations),
          financialYearText: this.convertFinancialYearToString(item),
          paying: this.createPayingText(item, translations),
          subscriptionTypeId: item.subscription?.subscriptionTypeId,
          subscriptionBundleName: this.findBundleName(item.subscription),
          addonModulesName: this.createAddonModulesText(item.subscription),
          lastLogin: item.lastLogin ? getLocalIsoDateWithTime(item.lastLogin) : '',
        })),
      ),
    );
  }

  private getHiddenDatabaseMessageAsObservable(): Observable<string> {
    const hasHiddenDatabases$ = this.store.select(CollaborationPackageSelectors.selectHasHiddenDatabases);

    const isFirmUser$ = this.store.select(AuthSelectors.selectIsFirmUser);

    const messageByRole$ = combineLatest([isFirmUser$, this.translationsLoaded$]).pipe(
      map(([isFirmUser, translations]) =>
        isFirmUser
          ? this.getUserMessageAboutHiddenDatabases(translations)
          : this.getAdminMessageAboutHiddenDatabases(translations),
      ),
    );

    const shouldShowMessage$ = this.store.select(FirmSelectors.selectFirm).pipe(
      map((firm) => firm?.properties?.show_hidden_databases_for_all_users ?? 'false'),
      map((setting) => setting === 'true'),
      map((showHiddenDatabaseSetting) => !showHiddenDatabaseSetting),
    );

    return combineLatest([hasHiddenDatabases$, shouldShowMessage$, messageByRole$]).pipe(
      map(([hasHiddenDatabases, showMessage, message]) => (hasHiddenDatabases && showMessage ? message : '')),
    );
  }

  private createStatusText(item: CollaborationPackageItem, translations: CollaborationPackageListTranslation): string {
    const hiddenText = item.hidden ? translations.hidden : '';
    const firmSupportText = item.existsInFirmSupport
      ? translations.existsInFirmSupport
      : translations.notExistsInFirmSupport;
    const archivedInFirmSupportText = item.archivedInFirmSupport ? translations.archivedInFirmSupport : '';
    const archivedText = item.archived ? translations.archivedCompanyDatabase : '';

    return [hiddenText, firmSupportText, archivedText, archivedInFirmSupportText].filter(Boolean).join(', ');
  }

  private convertFinancialYearToString(item: CollaborationPackageItem): string {
    if (!item.financialYear) {
      return '';
    }
    const { start, end } = item.financialYear.span;
    return `${start} - ${end}`;
  }

  private createPayingText(item: CollaborationPackageItem, translations: CollaborationPackageListTranslation): string {
    return item.payingByFirm ? translations.firm : translations.otherPart;
  }

  private createAddonModulesText(subscription: AppSubscription | null): string {
    if (!subscription) {
      return '';
    }

    const addonModules = subscription.modules?.filter((module) => !this.isBundle(module)) ?? [];
    const integrations = subscription.integrations ?? [];
    const addonModuleNames = addonModules.map((module) => module.name);
    const integrationNames = integrations.map((integration) => integration.name);

    return this.distinctArray(addonModuleNames.concat(integrationNames))
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  }

  private distinctArray(array: string[]): string[] {
    return Array.from(new Set(array));
  }

  private translateFilterOptions(translation: CollaborationPackageListTranslation): { label: string; value: string }[] {
    const keys = [
      'all',
      'allExceptArchived',
      'allWithoutSubscription',
      'allArchived',
      'allWithFirmsupport',
      'allWithoutFirmsupport',
      'allWithNewSubscription',
      'allWithNewProSubscription',
      'allWithNewEssentialSubscription',
      'allWithNewGoSubscription',
      'allWithNewMiniSubscription',
    ];

    return keys.map((key) => ({ label: translation[key], value: key }));
  }

  private translateBlaLoginFilterOptions(
    translation: CollaborationPackageListTranslation,
  ): { label: string; value: string }[] {
    const keys = ['all', 'noActivity', 'activityCurrentWeek', 'activityCurrentMonth', 'activityCurrentYear'];

    return keys.map((key) => ({ label: translation[key], value: key }));
  }

  private getAdminMessageAboutHiddenDatabases = (translation: CollaborationPackageListTranslation): string => `
    <div class="pt-3 font-bold">${translation.adminMessageAboutHiddenDatabases}</div>`;

  private getUserMessageAboutHiddenDatabases = (translations: CollaborationPackageListTranslation): string =>
    `<div class="pt-3 font-bold">${translations.userMessageAboutHiddenDatabases}</div>`;
}
