<app-please-wait [hidden]="hidePleaseWait"></app-please-wait>
<ng-container *transloco="let t; prefix: scope">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="flex w-full">
      <div class="flex flex-col mr-1 w-full">
        <label for="type">{{ t('type') }}</label>
        <p-dropdown
          id="type"
          [options]="types$ | async"
          formControlName="type"
          optionLabel="description"
          dataKey="name"
          appendTo="body"
          styleClass="w-full"></p-dropdown>
      </div>
      <div class="flex flex-col mr-1 w-full">
        <label for="assignment">{{ t('assignments') }}</label>
        <p-dropdown
          id="assignment"
          [options]="assignments$ | async"
          formControlName="assignment"
          optionLabel="name"
          dataKey="id"
          appendTo="body"
          styleClass="w-full"></p-dropdown>
      </div>
      <div class="flex flex-col w-full">
        <label for="entryDate">{{ t('entryDate') }}</label>
        <app-calendar
          id="entryDate"
          formControlName="entryDate"
          [selectedDate]="form.controls.entryDate.value"
          [invalid]="form.controls.entryDate.enabled && !form.controls.entryDate.valid"
          [title]="form.controls.entryDate.errors?.entryDate.message"
          appendTo="body"
          styleClass="w-full"></app-calendar>
      </div>
    </div>
    <div class="flex flex-col w-2/3 mt-3">
      <label for="client">{{ t('client') }}</label>
      <p-dropdown
        id="client"
        [options]="clients$ | async"
        formControlName="client"
        optionLabel="name"
        dataKey="id"
        [filter]="true"
        filterBy="name"
        [placeholder]="'constants.SELECT_WITH_HYPHENS' | transloco"
        appendTo="body"
        styleClass="w-full"
        [class]="form.errors?.requiredFields ? 'ng-invalid ng-dirty' : ''"
        [title]="form.errors?.requiredFields?.message"></p-dropdown>
    </div>
    <div class="flex flex-col w-full mt-3">
      <label for="text">{{ t('noteText') }}</label>
      <textarea id="text" formControlName="text" pInputTextarea style="resize: none" rows="3" cols="35"></textarea>
    </div>
    <div class="flex mt-5">
      <p-checkbox [binary]="true" formControlName="todoChecked" [label]="t('postATodoPost')"> </p-checkbox>
    </div>
    <div class="flex w-full mt-4">
      <div class="flex flex-col mr-1 w-full">
        <label for="users">{{ t('executionUser') }}</label>
        <app-user-multi-selector
          id="users"
          scrollHeight="240px"
          [selector]="selector"
          formControlName="userIds"
          [class]="form.controls.todoChecked.value && form.errors?.requiredFields ? 'ng-invalid ng-dirty' : ''"
          [title]="form.errors?.requiredFields?.message"></app-user-multi-selector>
      </div>
      <div class="flex flex-col mr-1 w-full">
        <label for="deadline">{{ t('deadline') }}</label>
        <app-calendar
          id="deadline"
          formControlName="deadline"
          [selectedDate]="form.controls.deadline.value"
          [invalid]="form.controls.deadline.enabled && !form.controls.deadline.valid"
          [title]="form.controls.deadline.errors?.deadline.message"
          appendTo="body"
          styleClass="w-full"></app-calendar>
      </div>
      <div class="flex flex-col w-full">
        <label for="state">{{ t('status') }}</label>
        <app-todo-status-selector id="state" formControlName="state" styleClass="w-full"></app-todo-status-selector>
      </div>
    </div>
    <div class="flex w-full justify-end mt-10">
      <bl-frontend-button variant="secondary" [text]="'shared.close' | transloco" (click)="close({ todo: null })" class="mr-2">
      </bl-frontend-button>
      <bl-frontend-button [disabled]="!form.valid" type="submit" [text]="'shared.save' | transloco" [title]="formErrors">
      </bl-frontend-button>
    </div>
  </form>
</ng-container>
