import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { MemoizedSelector } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SimpleUserType, UserType } from 'src/app/core/state/types';
import { AppState } from '@app/core/state/appState';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { User } from '../../../core/entity/user';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { TranslocoModule } from '@jsverse/transloco';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableStringType } from 'src/app/core/i18n/types';

interface UserMultiSelectorComponentTranslation {
  nrSelectedUsers: string;
  chooseWithHyphen: TranslatableStringType;
}

@Component({
  selector: 'app-user-multi-selector',
  template: ` <ng-container *transloco="let t; prefix: this.scope">
    <p-multiSelect
      [showHeader]="false"
      [options]="usersItems$ | async"
      (onChange)="emitSelectedUser()"
      [maxSelectedLabels]="1"
      [selectedItemsLabel]="i18n.nrSelectedUsers"
      [(ngModel)]="_selectedUsers"
      [disabled]="disabled"
      appendTo="body"
      styleClass="w-full"
      [scrollHeight]="scrollHeight"
      [placeholder]="placeholder || t('chooseWithHyphen')"
      [class]="class"
      [title]="title"
      [inputId]="inputId"
      [class]="invalid ? 'ng-invalid ng-dirty' : ''"
      [emptyMessage]="emptyMessage"
      [autoOptionFocus]="false">
    </p-multiSelect
  ></ng-container>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserMultiSelectorComponent,
    },
    getTranslationProvider('UserMultiSelectorComponent'),
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, MultiSelectModule, TranslocoModule],
})
export class UserMultiSelectorComponent extends TranslatableComponent implements OnInit, ControlValueAccessor {
  @Output() selectedUserEmitter = new EventEmitter<User[]>();
  @Input() scrollHeight: string;
  @Input() breakpoint = 16;
  @Input() selector: MemoizedSelector<AppState, UserType[] | SimpleUserType[]> = UserSelectors.activeUsersWithoutAll;
  @Input() disabled = false;
  @Input() placeholder: string;
  @Input() class: string;
  @Input() title: string;
  @Input() inputId: string;
  @Input() invalid: boolean;
  @Input() emptyMessage: string;
  @Input()
  set selectedUsers(users: User[]) {
    if (!users) {
      return;
    }

    this._selectedUsers = users.map((user) => user.id);
  }

  public usersBS: BehaviorSubject<UserType[]>;
  public usersItems$: Observable<SelectItem[]>;
  public _selectedUsers: number[];
  public i18n: UserMultiSelectorComponentTranslation;

  constructor() {
    super();
    this.usersBS = new BehaviorSubject([]);
    this.i18n = {} as UserMultiSelectorComponentTranslation;
  }

  protected override onTranslationsLoaded(): void {
    this.i18n = {
      nrSelectedUsers: this.translate('nrSelectedUsers').withParams({ count: '{0}' }), // workaround to get the template ({xx} users selected) to work
      chooseWithHyphen: this.translate('chooseWithHyphen'),
    };
  }

  onChange: ControlValueAccessorFn = () => {};
  onTouched: ControlValueAccessorFn = () => {};

  writeValue(selectedUsers: number[]): void {
    this._selectedUsers = selectedUsers;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  override ngOnInit() {
    super.ngOnInit();

    this.store.select(this.selector).pipe(this.takeUntilDestroyed()).subscribe(this.usersBS);

    this.usersItems$ = this.usersBS.asObservable().pipe(
      map((users) => users.map(({ id, name }) => ({ label: name, value: id }))),
      tap((users) => {
        if (!this.scrollHeight) {
          this.scrollHeight = getScrollHeightString(users.length, this.breakpoint);
        }
      }),
    );
  }

  emitSelectedUser() {
    const usersToEmit = this.usersBS.value
      .filter((user) => this._selectedUsers.includes(user.id))
      .map((user) => User.from({ id: user.id, name: user.name }));

    this.onChange(this._selectedUsers);
    this.onTouched();
    this.selectedUserEmitter.emit(usersToEmit);
  }
}
