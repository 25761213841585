import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { DropdownModule } from 'primeng/dropdown';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, Observable } from 'rxjs';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { AppState } from 'src/app/core/state/appState';
import { todoStatesFeature } from 'src/app/core/state/todo-states/todo-states.reducers';
import { NameDescriptionType } from 'src/app/core/state/types';

@Component({
  selector: 'app-todo-status-selector',
  template: `
    <p-dropdown
      [options]="selectableStates$ | async"
      [ngModel]="selectedStatus"
      (onChange)="onDropdownChange($event.value)"
      optionLabel="description"
      optionValue="name"
      appendTo="body"
      [disabled]="disabled"
      [styleClass]="styleClass"
      [autoOptionFocus]="false"
      [autoDisplayFirst]="false">
    </p-dropdown>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule],
  providers: [
    getTranslationProvider('todoStatusSelectorComponent'),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TodoStatusSelectorComponent),
      multi: true,
    },
  ],
})
export class TodoStatusSelectorComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() styleClass: string;
  @Input()
  get selectedStatus(): NameDescriptionType['name'] {
    return this.selectedStatusSubject.value;
  }
  set selectedStatus(value: NameDescriptionType) {
    this.selectedStatusSubject.next(value?.name);
  }

  @Output() selectedStatusChange = new EventEmitter<NameDescriptionType['name']>();

  selectableStates$: Observable<NameDescriptionType[]>;
  private selectedStatusSubject = new BehaviorSubject<NameDescriptionType['name']>(null);

  constructor(
    private store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.selectableStates$ = this.store.select(todoStatesFeature.selectStates).pipe(filter(Boolean));

    combineLatest([this.selectableStates$, this.selectedStatusSubject])
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe(([states, changed]) => {
        const status = this.findOrFirstStatusState(states, changed);
        this.onChange(status);
      });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: NameDescriptionType): void {
    this.selectedStatusSubject.next(value?.name);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  onDropdownChange(value: NameDescriptionType['name']): void {
    this.selectedStatusSubject.next(value);
    this.selectedStatusChange.emit(value);
  }

  private findOrFirstStatusState(
    states: NameDescriptionType[],
    item: NameDescriptionType['name'],
  ): NameDescriptionType {
    return !item ? states[0] : states.find((state) => state.name === item);
  }
}
