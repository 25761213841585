import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { ClientType, FirmType } from '@app/core/state/types';
import { Observable } from 'rxjs';
import { filter, first, skipWhile } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DialogData,
  DialogResult,
  FormDialogBaseComponent,
} from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ClientActions } from '@app/core/state/clients/clients.actions';
import { PleaseWaitComponent } from '@app/shared/components/please-wait/please-wait.component';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { BlFrontendCheckboxComponent } from '@app/core/wrappers/bl-frontend-checkbox.component';

interface Data extends DialogData {
  client: ClientType;
}

interface Result extends DialogResult {
  client?: ClientType;
  openClientCard?: boolean;
}

interface Form {
  agreement: FormControl<boolean | null>;
}

@Component({
  selector: 'app-advisory-tool-activate-client-dialog',
  templateUrl: './advisory-tool-activate-client.dialog.html',
  styles: [
    `
      :host ::ng-deep .bl-core-text-description-label {
        @apply ml-1.5 max-w-prose text-base pt-2;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PleaseWaitComponent,
    BlFrontendButtonComponent,
    BlFrontendCheckboxComponent,
  ],
})
export class AdvisoryToolActivateClientDialog extends FormDialogBaseComponent<Data, Result, Form> implements OnInit {
  firm$: Observable<FirmType>;
  isClientSameAsFirm: boolean;
  clientHasCloudApiKey: boolean;
  clientHasAdvisoryTool: boolean;
  hidePleaseWait = false;

  get activateEnabled() {
    return !this.clientHasAdvisoryTool && this.clientHasCloudApiKey;
  }

  get deactivateEnabled() {
    return this.clientHasAdvisoryTool;
  }

  activateAdvisoryTool() {
    if (!this.activateEnabled) {
      return;
    }

    this.hidePleaseWait = false;
    const client = { ...this.config.data.client, advisoryTool: true };
    this.store.dispatch(
      ClientActions.activateAdvisoryReport({ client, predicate: this.getClientListPredicate(client.id) }),
    );
  }

  deactivateAdivsoryTool() {
    if (!this.deactivateEnabled) {
      return;
    }

    this.hidePleaseWait = false;
    const client = { ...this.config.data.client, advisoryTool: false };
    this.store.dispatch(
      ClientActions.deactivateAdvisoryReport({ client, predicate: this.getClientListPredicate(client.id) }),
    );
  }

  protected override onInitialized = () => {
    this.store
      .select(FirmSelectors.selectFirm)
      .pipe(
        filter((f) => Boolean(f)),
        first(),
      )
      .subscribe((firm) => {
        const { client } = this.config.data;
        this.isClientSameAsFirm = client?.corporateIdentity === firm?.corporateIdentity;
        this.clientHasCloudApiKey = Boolean(client?.cloudApiKey);
        this.clientHasAdvisoryTool = Boolean(client?.advisoryTool);
        this.config.header = this.getHeaderText();
        this.initForm();
        this.hidePleaseWait = true;
      });

    this.subscribeToActivationChanges();
    this.subscribeToDeactivationChanges();
  };

  protected static override getDialogConfig = (): Omit<DynamicDialogConfig<Data>, 'data'> => ({
    width: '400px',
    closable: false,
    draggable: false,
    modal: true,
  });

  private initForm = () => {
    this.form = this.builder.group<Form>({
      agreement: new FormControl(this.isClientSameAsFirm ? true : null),
    });
  };

  private getHeaderText = () => {
    if (this.isClientSameAsFirm) {
      return `Kostnadsfri aktivering av rådgivarverktyget för den egna byrån`;
    }

    if (this.config.data.client.advisoryTool) {
      return `Avaktivering av Rådgivarverktyg`;
    }

    return `Aktivering av Rådgivarverktyg`;
  };

  private getClientListPredicate(clientId: number) {
    return (client: ClientType) => client.id === clientId;
  }

  private subscribeToActivationChanges = () => {
    this.store
      .select(ClientSelectors.clientStateActivating)
      .pipe(
        skipWhile((value) => !value),
        skipWhile((value) => value),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        const client = { ...this.config.data.client, advisoryTool: true };
        this.close({ client });
      });
  };

  private subscribeToDeactivationChanges = () => {
    this.store
      .select(ClientSelectors.clientStateDeactivating)
      .pipe(
        skipWhile((value) => !value),
        skipWhile((value) => value),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        const client = { ...this.config.data.client, advisoryTool: false };
        this.close({ client });
      });
  };
}
