import { Injectable } from '@angular/core';
import { Translation, TranslocoInterceptor } from '@jsverse/transloco';
import { LOCAL_STORAGE_SHOW_KEYS_KEY } from './constants';

@Injectable()
export class DefaultInterceptor implements TranslocoInterceptor {
  preSaveTranslation(translation: Translation): Translation {
    const showKeys = localStorage.getItem(LOCAL_STORAGE_SHOW_KEYS_KEY) || false;
    if (showKeys) {
      return {};
    }
    return translation;
  }

  preSaveTranslationKey(_: string, value: string): string {
    return value;
  }
}
