import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, filter, switchMap, timer } from 'rxjs';
import { IConfigCatClient, getClient, PollingMode, User } from 'configcat-js';
import { environment } from 'src/environments/environment';
import { concatLatestFrom } from '@ngrx/effects';
import { FirmSelectors } from '../state/firm/firm.selectors';
import { FeatureType, FirmType, UserType } from '../state/types';
import { AuthSelectors } from '../state/auth/auth.selectors';
import { AppState } from '../state/appState';
import { FeatureActions } from '../state/feature/feature.actions';
import { initialFeaturesState } from '../state/feature/feature.reducer';

const CACHE_TIME_TO_LIVE_SECONDS = 60;
const GET_FEATURES_POLL_INTERVAL = (CACHE_TIME_TO_LIVE_SECONDS / 3) * 1000;

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private featuresSub: BehaviorSubject<FeatureType>;
  private availableFeatures: FeatureType;
  private configCatClient: IConfigCatClient;

  constructor(store: Store<AppState>) {
    this.availableFeatures = { ...initialFeaturesState.features };
    this.featuresSub = new BehaviorSubject<FeatureType>(null);
    this.configCatClient = getClient(environment.sdkKey, PollingMode.LazyLoad, {
      cacheTimeToLiveSeconds: CACHE_TIME_TO_LIVE_SECONDS,
    });

    store
      .select(FirmSelectors.selectFirm)
      .pipe(
        concatLatestFrom(() => store.select(AuthSelectors.selectAuthUser)),
        filter(([firm, user]) => Boolean(firm) && Boolean(user)),
        switchMap(([firm, user]) =>
          timer(0, GET_FEATURES_POLL_INTERVAL).pipe(switchMap(() => this.getFeatureValues(firm, user))),
        ),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      )
      .subscribe((newFeatures) => {
        const features: FeatureType = { ...this.featuresSub.value, ...newFeatures };

        this.featuresSub.next(features);
        store.dispatch(FeatureActions.featuresUpdated({ features }));
      });
  }

  public init = () => {}; // To make sure this service is constructed by Angulars DI

  private getFeatureValues = async (firm: FirmType, user: UserType): Promise<FeatureType> => {
    const { id: firmId } = firm;
    const { privilege, role, email, id: userId } = user;
    const configCatUser = new User(String(userId), email, null, { firmId: String(firmId), privilege, role });
    const promises = Object.keys(this.availableFeatures).map((feature) =>
      this.configCatClient
        .getValueAsync(feature, this.availableFeatures[feature], configCatUser)
        .then((value) => ({ [feature]: value })),
    );

    const featureValues = await Promise.all(promises);
    const features = featureValues.reduce((acc, feature) => ({ ...acc, ...feature }), {} as FeatureType);
    return features as FeatureType;
  };
}
