import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdvisoryToolActivateClientDialog } from '@app/advisory-tool/components/advisory-tool-activate-client/advisory-tool-activate-client.dialog';
import { ApplicationLinksService } from '@app/core/services/application-links.service';
import { FinancialYearService } from '@app/core/services/financialyear.service';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { ListActions } from '@app/core/state/list/list.actions';
import { ListSelectors } from '@app/core/state/list/list.selectors';
import { TeamsSelectors } from '@app/core/state/teams/teams.selectors';
import { toClientTransformer, toClientTypeTransformer } from '@app/core/state/transformers/transformers';
import { MissingCloudApiKeyDialog } from '@app/shared/components/missing-cloud-api-key/missing-cloud-api-key.dialog';
import { ActivateSustainabilityReportingDialog } from '@app/shared/components/new-client/components/activate-sustainability-reporting-dialog/activate-sustainability-reporting-dialog';
import { DeactivateSustainabilityReportingDialog } from '@app/shared/components/new-client/components/deactivate-sustainability-reporting-dialog/deactivate-sustainability-reporting-dialog';
import {
  ApplicationLink,
  ApplicationLinksGroup,
  ApplicationType,
} from '@app/shared/misc/application-link-builders/types';
import { openDialog } from '@app/shared/misc/openDialog';
import { showClientDialog } from '@app/shared/misc/showClientDialog';
import { Actions, ofType } from '@ngrx/effects';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { Client } from '../core/entity/client';
import { getTranslationProvider } from '../core/i18n/getTranslationProvider';
import { TranslatableComponent } from '../core/i18n/TranslatableComponent';
import { ClientService } from '../core/services/clients.service';
import { ClientActions } from '../core/state/clients/clients.actions';
import { ClientType, TeamType } from '../core/state/types';
import { UserSettingsStorage } from '../core/storage/user.settings.storage';
import { TableColumn } from '../shared/components/table/interfaces/table-column';
import { translateColumnHeaders } from '../shared/components/table/misc/translate-column-header';
import { ArchiveOrDeleteClientDialogComponent } from './archive-or-delete-client-dialog/archive-or-delete-client.dialog';
import { CompareCloudCompaniesDialogComponent } from './compare-cloud-companies/compare-cloud-companies-dialog.component';
import { NewClientGuideService } from './new-client-guide-dialog/new-client-guide.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client.list.component.html',
  providers: [
    DialogService,
    FinancialYearService,
    NewClientGuideService,
    getTranslationProvider('ClientListComponent'),
  ],
})
export class ClientListComponent extends TranslatableComponent implements OnInit, OnDestroy {
  public clients$: Observable<ClientType[]>;

  public loading$: Observable<boolean>;
  public statusText$: Observable<string>;
  public includeArchivedLabel$: Observable<string>;
  public model: Client;
  public pendingDeletionClient?: Client;
  public displayArchiveClientDialog = false;
  public includeArchivedClients = false;
  public selectedClientResponsibleUserId: number | undefined;
  public defaultNumberOfRows = 50;
  public tabToOpen: string;
  public columns$: Observable<TableColumn[]>;

  private defaultColumns: TableColumn[] = [
    { field: 'customerNumber', header: 'customerNumber' },
    { field: 'corporateIdentity', header: 'orgNumber' },
    { field: 'name', header: 'name' },
    { field: 'phone', header: 'phone' },
    { field: 'email', header: 'email' },
    { field: 'city', header: 'city' },
    { field: 'responsibleUser', header: 'responsibleUser' },
    { field: 'advisoryTool', header: 'advisoryTool', visible: false, hiddenForCountries: ['NL'] },
    { field: 'cellphone', header: 'mobile', visible: false },
    { field: 'address', header: 'address', visible: false },
    { field: 'zipCode', header: 'zipCode', visible: false },
    { field: 'residence', header: 'residence', visible: false },
    { field: 'signatory', header: 'signatory', visible: false },
    { field: 'firmType', header: 'companyType', visible: false },
    { field: 'latestFinancialYear', header: 'financialYear', visible: false },
    { field: 'country', header: 'country', visible: false },
    { field: 'webpage', header: 'webpage', visible: false },
    { field: 'bank', header: 'bank', visible: false },
    { field: 'businessArea', header: 'businessArea', visible: false },
    { field: 'registrationDate', header: 'registrationDate', visible: false },
    { field: 'accountant', header: 'accountant', visible: false },
    { field: 'cloudApiKey', header: 'cloudDatabaseKey', width: '10%' },
    {
      field: 'external-app-bla',
      header: 'BL Adm',
      visible: false,
      sortable: false,
      width: '50px',
      hiddenForCountries: ['NL'],
    },
    {
      field: 'external-app-bls-current',
      header: 'BL Skatt',
      visible: false,
      sortable: false,
      width: '50px',
      hiddenForCountries: ['NL'],
    },
    {
      field: 'external-app-bls-prev',
      header: 'BL Skatt föregående',
      visible: false,
      sortable: false,
      width: '50px',
      hiddenForCountries: ['NL'],
    },
    {
      field: 'external-app-blb-current',
      header: 'BL Bokslut',
      visible: false,
      sortable: false,
      width: '50px',
      hiddenForCountries: ['NL'],
    },
    {
      field: 'external-app-blb-prev',
      header: 'BL Bokslut föregående',
      visible: false,
      sortable: false,
      width: '50px',
      hiddenForCountries: ['NL'],
    },
    { field: 'action', header: '', width: '40px', sortable: false, selectable: false },
    { field: 'sustainabilityReporting', header: 'sustainabilityReporting', visible: false, hiddenForCountries: ['NL'] },
  ];

  private columns = new BehaviorSubject<TableColumn[]>([...this.defaultColumns]);
  private externalAppColumns: string[] = [];
  private uss: UserSettingsStorage;

  // eslint-disable-next-line max-params
  constructor(
    private clientService: ClientService,
    private applicationLinksService: ApplicationLinksService,
    private actions$: Actions,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private newClientGuideService: NewClientGuideService,
  ) {
    super();
    this.externalAppColumns = this.columns.value.filter((c) => c.field.startsWith('external-app-')).map((c) => c.field);

    this.newClientGuideService
      .openClientGuideDialogWhenRouteDataContainsGuideInfo(this.activatedRoute.data)
      .subscribe({ next: () => this.reloadClients() });

    this.columns$ = combineLatest([this.translationsLoaded$, this.columns]).pipe(
      map(([translations, columns]) => translateColumnHeaders(columns)(translations)),
    );
  }

  override ngOnInit() {
    this.loadSettings();
    super.ngOnInit();
    const groups = this.applicationLinksService.getApplicationLinksGroupsWithTasks();
    const linkMap = this.mapGroupsToRecord(groups);
    this.statusText$ = this.store.select(ListSelectors.getStatusText);
    this.includeArchivedLabel$ = this.store.select(ClientSelectors.includeArchivedLabelSelector);
    this.initClients(linkMap);
    this.loading$ = this.store.select(ListSelectors.isLoading);
    this.actions$
      .pipe(ofType(ClientActions.archiveClient, ClientActions.deleteClient), this.takeUntilDestroyed())
      .subscribe(() => {
        this.resetVariables();
      });

    this.store
      .select(TeamsSelectors.selectTeamSelector)
      .pipe(this.takeUntilDestroyed())
      .subscribe(({ ids }) => {
        const visible = ids?.length > 1;
        const teamsColumn: TableColumn = { field: 'teamsString', header: 'team', visible };
        this.columns.next([teamsColumn, ...this.defaultColumns]);
      });
  }

  override ngOnDestroy() {
    this.uss.saveSetting(UserSettingsStorage.CLIENT_SHOW_ARCHIVED, `${this.includeArchivedClients}`);
    this.uss.saveSetting(UserSettingsStorage.CLIENT_RESPONSIBLE_ID, `${this.selectedClientResponsibleUserId}`);
    super.ngOnDestroy();
  }

  reloadClients() {
    this.store.dispatch(
      ClientActions.loadClientList({
        filter: {
          includeArchivedClients: this.includeArchivedClients,
          responsibleUserId: this.selectedClientResponsibleUserId,
        },
      }),
    );
  }

  refilterClients() {
    this.store.dispatch(
      ListActions.filterList({
        filter: {
          includeArchivedClients: this.includeArchivedClients,
          responsibleUserId: this.selectedClientResponsibleUserId,
        },
      }),
    );
  }

  onCellClick({ column, data }: { column: TableColumn; data: ClientType }) {
    this.model = toClientTransformer.transform(data);

    if (column.field === 'advisoryTool') {
      this.handleActiveAdvisoryToolClick(toClientTypeTransformer.transform(this.model));
      return;
    }

    if (column.field === 'sustainabilityReporting') {
      this.handleSustainabilityReportingClick(toClientTypeTransformer.transform(this.model));
      return;
    }

    if (this.externalAppColumns.includes(column.field)) {
      this.externalAppClick({ column, data });
      return;
    }

    if (column.field === 'action') {
      return;
    }

    this.openClientCard();
  }

  showNewClient() {
    this.model = new Client('');
    this.openClientCard();
  }

  tryDeleteClient(clientToDelete: Client) {
    this.clientService
      .getClientById(clientToDelete.id)
      .pipe(
        map((client) => toClientTypeTransformer.transform(client)),
        mergeMap((client) => {
          const { deleteable } = client;
          if (!deleteable) {
            return of({ client });
          }

          return openDialog(this.dialogService, ArchiveOrDeleteClientDialogComponent, { client });
        }),
      )
      .subscribe((result) => {
        if (!result?.client) {
          return;
        }

        if (!result.client.deleteable) {
          this.pendingDeletionClient = toClientTransformer.transform(result.client);
          this.displayArchiveClientDialog = true;
        }
      });
  }

  reactivateClient(clientToReactivate: Client) {
    const client = toClientTypeTransformer.transform(clientToReactivate);
    client.archived = false;
    this.store.dispatch(ClientActions.reactivateClient({ client, predicate: this.getClientListPredicate(client.id) }));
  }

  onArchiveClient() {
    if (!this.pendingDeletionClient) {
      return;
    }

    const client = toClientTypeTransformer.transform(this.pendingDeletionClient);
    client.archived = true;
    this.store.dispatch(ClientActions.archiveClient({ client, predicate: this.getClientListPredicate(client.id) }));
  }

  onCancel() {
    this.resetVariables();
  }

  openClientCard(tabToOpen: string = null) {
    showClientDialog({
      clientService: this.clientService,
      dialogService: this.dialogService,
      id: this.model.id,
      store: this.store,
      tabToOpen,
    })
      .pipe(
        first(),
        map((result) => result?.client?.id),
        filter((clientId) => !isNaN(clientId) && clientId !== -1),
      )
      .subscribe(() => {
        this.reloadClients();
      });
  }

  showCompareCloudCompaniesDialog() {
    openDialog(this.dialogService, CompareCloudCompaniesDialogComponent, {}).subscribe((result) => {
      if (!result?.updated) {
        return;
      }

      this.reloadClients();
    });
  }

  private resetVariables() {
    this.displayArchiveClientDialog = false;
    this.pendingDeletionClient = null;
  }

  private loadSettings() {
    this.uss = new UserSettingsStorage();

    this.defaultNumberOfRows = this.uss.loadSettingAsNumber(
      UserSettingsStorage.CLIENT_NUMBER_OF_ROWS,
      this.defaultNumberOfRows,
    );
    this.includeArchivedClients = this.uss.loadSettingAsBoolean(UserSettingsStorage.CLIENT_SHOW_ARCHIVED, true);
    this.selectedClientResponsibleUserId = this.uss.loadSettingAsNumber(UserSettingsStorage.CLIENT_RESPONSIBLE_ID, 0);
  }

  private callExternalApp(client: ClientType, { type, year }: ApplicationLink) {
    const agentCallerElement = <HTMLAnchorElement>document.getElementById('externalAppCaller');

    this.clientService.getAgentUrl(client.id).subscribe(({ agentCall }) => {
      let href = agentCall;
      const financialYear = `financialYear=${year}0101-${year}1231&`;
      if (type === ApplicationType.BLSkatt) {
        href = href.replace('blinfoa://', `blinfos${year}://${financialYear}`);
        href = href.replace('&loginId=', '&task=UPPI&loginId=');
      }

      if (type === ApplicationType.BLBokslut) {
        href = href.replace('blinfoa://', `blinfob${year}://${financialYear}`);
        href = href.replace('&loginId=', '&task=ARSR&loginId=');
      }

      agentCallerElement.href = href;
      agentCallerElement.click();
      return false;
    });
  }

  private mapGroupsToRecord(groups: ApplicationLinksGroup[]): Record<string, ApplicationLink> {
    const blaGroup = groups.find((g) => g.type === ApplicationType.BL);
    const blsGroup = groups.find((g) => g.type === ApplicationType.BLSkatt);
    const blbGroup = groups.find((g) => g.type === ApplicationType.BLBokslut);

    return {
      'external-app-bla': blaGroup.links[0],
      'external-app-bls-current': blsGroup.links[0],
      'external-app-bls-prev': blsGroup.links[1],
      'external-app-blb-current': blbGroup.links[0],
      'external-app-blb-prev': blbGroup.links[1],
    };
  }

  private getClientListPredicate(clientId: number) {
    return (client: ClientType) => client.id === clientId;
  }

  private initClients(linkMap: Record<string, ApplicationLink>) {
    this.clients$ = this.store.select(ClientSelectors.allFilteredClients).pipe(
      map(({ filteredClients, rootTeam }) =>
        filteredClients.map((client) => {
          const latestFinancialYear = client.financialYears[client.financialYears.length - 1];
          return {
            ...client,
            responsibleUser: client.responsible?.name ?? '',
            latestFinancialYear:
              client.financialYears.length !== 0
                ? `${latestFinancialYear.span.start.replace(/-/g, '')}-${latestFinancialYear.span.end.replace(
                    /-/g,
                    '',
                  )}`
                : '',
            firmType: client.type.description ?? '',
            ...linkMap,
            teamsString: this.getTeamsString(client, rootTeam),
          };
        }),
      ),
    );
  }

  private getTeamsString = (client: ClientType, rootTeam?: TeamType) =>
    client.teams
      ?.filter((t) => t.id !== rootTeam?.id)
      .map((t) => t.name)
      .join(', ');

  private externalAppClick = ({ column, data }: { column: TableColumn; data: ClientType }) => {
    if (column.field === 'external-app-bla') {
      return;
    }
    this.callExternalApp(data, data[column.field]);
  };

  private handleActiveAdvisoryToolClick = (client: ClientType) => {
    openDialog(this.dialogService, AdvisoryToolActivateClientDialog, { client }).subscribe((result) => {
      if (!result) {
        return;
      }

      if (result.openClientCard) {
        this.openClientCard('cloudServices');
      }
    });
  };

  private handleSustainabilityReportingClick = (client: ClientType) => {
    if (!client.cloudApiKey) {
      openDialog(this.dialogService, MissingCloudApiKeyDialog, {
        client,
        featureToActivate: 'hållbarhetsredovisning',
      })
        .pipe(filter((result) => Boolean(result?.client)))
        .subscribe(() => this.openClientCard('cloudServices'));
      return;
    }

    if (client.sustainabilityReporting) {
      openDialog(this.dialogService, DeactivateSustainabilityReportingDialog, { client })
        .pipe(
          filter((result) => Boolean(result?.client)),
          first(),
        )
        .subscribe(() => this.reloadClients());
      return;
    }

    openDialog(this.dialogService, ActivateSustainabilityReportingDialog, { client })
      .pipe(
        filter((result) => Boolean(result?.client)),
        first(),
      )
      .subscribe(() => this.reloadClients());
  };
}
