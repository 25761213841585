import { Component, OnInit } from '@angular/core';
import { Action } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, take, first } from 'rxjs/operators';
import { AuthSelectors } from 'src/app/core/state/auth/auth.selectors';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { RoleType, UserType } from 'src/app/core/state/types';
import { UserActions } from 'src/app/core/state/users/users.actions';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { AuthRoleSelectorEvent } from 'src/app/shared/components/auth-role-selector/auth-role-selector.component';
import { TableColumn } from 'src/app/shared/components/table/interfaces/table-column';
import { FilterConfig, filterType } from './firm-users.filter';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableStringType } from 'src/app/core/i18n/types';
import { openTranslatableDialog } from 'src/app/shared/misc/openDialog';
import { FirmUsersConfirmDialogComponent } from './firm-users.confirm.component';
import { DialogService } from 'primeng/dynamicdialog';

interface FirmUsersComponentTranslations {
  firmAdminMissing: TranslatableStringType;
  leastOneFirmAdminMustExist: TranslatableStringType;
  inactivateUserTitle: TranslatableStringType;
  inactivateUserErrorMessage: TranslatableStringType;
  name: TranslatableStringType;
  initials: TranslatableStringType;
  email: TranslatableStringType;
  role: TranslatableStringType;
  active: TranslatableStringType;
  search: TranslatableStringType;
  activateUser: TranslatableStringType;
  inactivateUser: TranslatableStringType;
  showOnlyActiveUsers: TranslatableStringType;
}

@Component({
  selector: 'app-firm-users',
  templateUrl: './firm-users.component.html',
  providers: [getTranslationProvider('FirmUsersComponent')],
})
export class FirmUsersComponent extends TranslatableComponent<FirmUsersComponentTranslations> implements OnInit {
  public filter$: Observable<FilterConfig>;
  public filteredUsers$: Observable<UserType[]>;
  public columns: TableColumn[] = [];

  private filterBS: BehaviorSubject<FilterConfig>;
  private currentAuthUser: UserType;
  private userToDeactivate: UserType | null;
  private i18n: FirmUsersComponentTranslations;

  constructor(private dialogService: DialogService) {
    super();
    this.userToDeactivate = null;
    this.filterBS = new BehaviorSubject<FilterConfig>({ includeInactive: false, searchString: '' });
    this.filter$ = this.filterBS.asObservable();
  }

  override ngOnInit() {
    super.ngOnInit();

    const allUsers$ = this.store.select(UserSelectors.allUsers);
    this.filteredUsers$ = combineLatest([allUsers$, this.filter$]).pipe(
      map(([allUsers, filter]) => filterType(allUsers, filter)),
    );

    this.subscribeToAuthUser();
  }

  protected override onTranslationsLoaded(): void {
    this.i18n = {
      firmAdminMissing: this.translate('firmAdminMissing'),
      leastOneFirmAdminMustExist: this.translate('leastOneFirmAdminMustExist'),
      inactivateUserTitle: this.translate('inactivateUserTitle'),
      inactivateUserErrorMessage: this.translate('inactivateUserErrorMessage'),
      name: this.translate('name'),
      initials: this.translate('initials'),
      email: this.translate('email'),
      role: this.translate('role'),
      active: this.translate('active'),
      search: this.translate('search'),
      activateUser: this.translate('activateUser'),
      inactivateUser: this.translate('inactivateUser'),
      showOnlyActiveUsers: this.translate('showOnlyActiveUsers'),
    };
  }

  hasRightsToChangeInitials(user: UserType) {
    return this.hasRightsToChange() || this.currentAuthUser?.id === user.id;
  }

  hasRightsToChange() {
    return this.currentAuthUser?.role === RoleType.FIRM_ADMIN;
  }

  search(searchString: string) {
    this.filterBS.next({ ...this.filterBS.getValue(), searchString });
  }

  toggleIncludeInactiveUsers() {
    const currentFilter = this.filterBS.getValue();
    const toggledIncludeInactiveUser = !currentFilter.includeInactive;
    this.filterBS.next({ ...currentFilter, includeInactive: toggledIncludeInactiveUser });
  }

  activateUser(user: UserType) {
    this.saveUserType({ ...user, active: true });
  }

  deactivateUser(user: UserType) {
    this.userToDeactivate = { ...user, active: false };
    this.openConfirmDialog();
  }

  updateInitials(user: UserType, event: Event) {
    const newInitials = (event.target as HTMLInputElement).value;
    this.saveUserType({ ...user, initials: newInitials });
  }

  updateRole(event: AuthRoleSelectorEvent) {
    const user = { ...event.originalUser, role: event.value };
    this.saveUserType(user, event.cancel);
  }

  private openConfirmDialog() {
    openTranslatableDialog({
      deps: { dialogService: this.dialogService, translationService: this.translationService },
      componentType: FirmUsersConfirmDialogComponent,
      data: {},
    })
      .pipe(first())
      .subscribe((result) => {
        this.onConfirmDialogClosed(result?.confirm ?? false);
      });
  }

  private onConfirmDialogClosed(active: boolean) {
    if (!active) {
      this.userToDeactivate = null;
      return;
    }

    this.userToDeactivate.active = false;
    this.saveUserType(this.userToDeactivate);
  }

  private saveUserType(user: UserType, cancelFn = () => {}) {
    const isActiveAndAdmin = (user: UserType) => user.active && user.role === RoleType.FIRM_ADMIN;
    const isAtLeastOneAdmin = (users: UserType[]) => users.some(isActiveAndAdmin);

    this.store
      .select(UserSelectors.allUsers)
      .pipe(
        map((users: UserType[]) => users.filter((u) => u.id !== user.id)),
        map((users: UserType[]) => users.concat([user])),
        map((users: UserType[]) => {
          if (isAtLeastOneAdmin(users)) {
            return UserActions.saveUser({ user, errorHandler: this.handleSaveError(user.name) });
          }
          cancelFn();
          return this.createMissingAdminMessageAction();
        }),
        take(1),
      )
      .subscribe((action: Action) => this.store.dispatch(action));
  }

  private subscribeToAuthUser() {
    const authUser$ = this.store.select(AuthSelectors.selectAuthUser);
    const translationsLoaded$ = this.translationsLoaded$.pipe(first(Boolean));

    return combineLatest([authUser$, translationsLoaded$])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([authUser, translations]) => {
        this.currentAuthUser = authUser;
        this.columns = this.getVisibleColumns(this.createColumns(translations));
      });
  }

  private createColumns(translations: FirmUsersComponentTranslations) {
    return [
      { field: 'name', header: translations.name },
      { field: 'initials', header: translations.initials, sortable: false, width: '12%' },
      { field: 'email', header: translations.email },
      { field: 'roleLabel', header: translations.role, width: '16%' },
      { field: 'active', header: translations.active, sortable: false, width: '6%' },
      { field: 'action', header: '', sortable: false, width: '5%', visible: false },
    ];
  }

  private getVisibleColumns(columns: TableColumn[]) {
    return columns.map((col) => {
      if (col.field !== 'action') {
        return col;
      }
      return { ...col, visible: this.hasRightsToChange() };
    });
  }

  private handleSaveError(fullName: string) {
    return catchError((err: unknown) => {
      if ((err as { ok: boolean })?.ok) {
        return EMPTY;
      }

      if ((err as { status: number })?.status === 422) {
        this.store.dispatch(
          this.createInactivateUserErrorMessageAction(fullName, err as { error: { message: string } }),
        );
        return EMPTY;
      }

      return throwError(() => err);
    });
  }

  private createMissingAdminMessageAction() {
    return ToastActions.showErrorMessage({
      summary: this.i18n.firmAdminMissing,
      detail: this.i18n.leastOneFirmAdminMustExist,
    });
  }

  private createInactivateUserErrorMessageAction(fullName: string, err: { error: { message: string } }) {
    return ToastActions.showErrorMessage({
      summary: this.i18n.inactivateUserTitle.withParams({ fullName }),
      detail: err?.error?.message ?? this.i18n.inactivateUserErrorMessage,
      sticky: true,
    });
  }
}
