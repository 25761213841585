import { Injectable } from '@angular/core';
import { TRANSLOCO_LOADER, TranslocoLoader } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  getTranslation(lang: string) {
    return import(`../../../assets/i18n/${lang}.json`).then((res) => res.default);
  }
}

export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader };
