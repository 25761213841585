import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleUserType } from '@app/core/state/types';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-user-avatar',
  template: `
    <div class="flex -space-x-1 overflow-hidden">
      <span
        class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-bl-blue-light-600 ring-2 ring-white shadow-lg"
        *ngFor="let user of visibleUsers">
        <span class="text-sm font-medium leading-none text-white" pTooltip="{{ user.name }}" tooltipPosition="top">{{
          user.initials
        }}</span>
      </span>
      <span
        class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-bl-blue-light-200 ring-2 ring-white shadow-lg"
        *ngIf="notVisibleUsersCount > 0">
        <span class="text-sm font-medium leading-none text-bl-blue-500">+{{ notVisibleUsersCount }}</span>
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TooltipModule],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UserAvatarComponent), multi: true }],
})
export class UserAvatarComponent implements OnInit, ControlValueAccessor {
  @Input()
  set users(users: SimpleUserType[]) {
    this.internalUsers = users;
    this.updateVisibleUsersProps();
  }

  get users(): SimpleUserType[] {
    return this.internalUsers;
  }

  @Input() maxVisible = 5; // 0 = show all

  visibleUsers: SimpleUserType[] = [];
  notVisibleUsersCount = 0;

  private internalUsers: SimpleUserType[] = [];

  // eslint-disable-next-line no-useless-constructor
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateVisibleUsersProps();
  }

  writeValue(obj: any): void {
    this.users = obj;
    this.updateVisibleUsersProps();
  }

  registerOnChange(): void {}
  registerOnTouched(): void {}

  private updateVisibleUsersProps() {
    if (!this.users) {
      return;
    }

    this.visibleUsers = this.maxVisible > 0 ? this.users.slice(0, this.maxVisible) : [...this.users];
    this.notVisibleUsersCount = this.users.length - this.visibleUsers.length;
    this.changeDetectorRef.markForCheck();
  }
}
