import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppState } from '@app/core/state/appState';
import { FeatureSelectors } from '@app/core/state/feature/feature.selectors';
import { FeatureType } from '@app/core/state/types';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter } from 'rxjs';

@Directive({
  selector: '[appFeatureToggle]',
  standalone: true,
})
export class FeatureToggleDirective implements OnChanges {
  @Input() appFeatureToggle: string;
  private features: BehaviorSubject<FeatureType>;

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<unknown>,
    private store: Store<AppState>,
  ) {
    this.features = new BehaviorSubject<FeatureType>(null);
    this.store
      .select(FeatureSelectors.selectFeatures)
      .pipe(filter((features) => Boolean(features)))
      .subscribe((features) => {
        this.features.next(features);
        this.ngOnChanges();
      });
  }

  ngOnChanges(): void {
    if (!this.appFeatureToggle) {
      return;
    }

    if (this.isFeatureToggled(this.appFeatureToggle)) {
      this.view.clear();
      this.view.createEmbeddedView(this.template);
      return;
    }

    this.view.clear();
  }

  private isFeatureToggled(feature: string): boolean {
    const reverseLogic = feature.startsWith('!');
    const featureName = reverseLogic ? feature.slice(1) : feature;
    const toggled = Boolean(this.features.value?.[featureName]);
    return (reverseLogic && !toggled) || (!reverseLogic && toggled);
  }
}
