<ng-container *ngIf="currentTeam$ | async as currentTeam">
  <p-dialog
    [header]="isRootTeam(currentTeam) ? rootTeamName : currentTeam.name"
    [modal]="true"
    [dismissableMask]="true"
    [visible]="visible$ | async"
    [style]="{ width: '900px' }"
    [draggable]="false"
    [closable]="false"
    showEffect="fade"
    (visibleChange)="onCancelClick()"
    (onHide)="onHideDialog()">
    <app-tabs #tabs>
      <ng-container *transloco="let t; prefix: scope">
        <app-tab [tabTitle]="t('overview')">
          <ng-container *ngTemplateOutlet="overview; context: { $implicit: currentTeam }"></ng-container>
        </app-tab>
        <app-tab [tabTitle]="t('editEmployees')">
          <ng-container *ngTemplateOutlet="userList"></ng-container>
        </app-tab>
        <app-tab [tabTitle]="t('editClients')" *ngIf="isClientBased(currentTeam)">
          <ng-container *ngTemplateOutlet="clientList"></ng-container>
        </app-tab>
        <app-tab [tabTitle]="t('editTasks')" *ngIf="isTaskBased(currentTeam)">
          <ng-container *ngTemplateOutlet="taskList"></ng-container>
        </app-tab>
      </ng-container>
    </app-tabs>

    <p-footer class="flex justify-between">
      <ng-container *transloco="let t; prefix: scope">
        <bl-frontend-button
          [text]="t('removeTeam')"
          variant="secondary-negative"
          *ngIf="!currentTeam.readonly"
          (click)="onDeleteTeamClick(currentTeam)"></bl-frontend-button>
      </ng-container>

      <div class="w-full">
        <bl-frontend-button [text]="'shared.cancel' | transloco" variant="secondary" (click)="onCancelClick()"></bl-frontend-button>
        <bl-frontend-button [text]="'shared.save' | transloco" variant="primary" (click)="onConfirmClick()"></bl-frontend-button>
      </div>
    </p-footer>
  </p-dialog>
</ng-container>

<ng-template #overview let-team>
  <div [formGroup]="form" class="max-h-490px overflow-auto" *transloco="let t; prefix: scope">
    <ng-container *ngIf="!isRootTeam(team)">
      <label class="block text-bl-grey" for="name">{{ t('name') }}</label>
      <input
        pInputText
        type="text"
        name="name"
        formControlName="name"
        class="w-full"
        [placeholder]="t('enterTeamName')" />
    </ng-container>

    <label class="block text-bl-grey mt-5">{{ t('employee') }}</label>
    <app-user-avatar formControlName="users" [maxVisible]="0"></app-user-avatar>

    <div *ngIf="isClientBased(team) || isRootTeam(team)">
      <app-team-badges [options]="clientsFormControlValue" optionLabel="name" [label]="t('clients')"></app-team-badges>
    </div>
    <div *ngIf="isTaskBased(team)">
      <app-team-badges [options]="tasksFormControlValue" optionLabel="type" [label]="t('tasks')"></app-team-badges>
    </div>
  </div>
</ng-template>

<ng-template #userList>
  <div [formGroup]="form">
    <app-team-user-list formControlName="users"></app-team-user-list>
  </div>
</ng-template>

<ng-template #clientList>
  <div [formGroup]="form">
    <app-team-client-list formControlName="clients"></app-team-client-list>
  </div>
</ng-template>

<ng-template #taskList>
  <div [formGroup]="form">
    <app-team-task-list formControlName="tasks"></app-team-task-list>
  </div>
</ng-template>
