import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { validateDateFn } from '@app/core/entity/validation';
import { AppState } from '@app/core/state/appState';
import { AssignmentSelectors } from '@app/core/state/assignments/assignments.selectors';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { TodoActions } from '@app/core/state/todo/todo.actions';
import { AssignmentType, NameDescriptionType, SimpleClientType, SimpleUserType } from '@app/core/state/types';
import { TodoSelectableStates, TodoType } from '@app/core/state/types/todo.types';
import { UserSelectors } from '@app/core/state/users/users.selectors';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { CalendarComponent } from '@app/shared/components/calendar/calendar.component';
import { DialogData, DialogResult } from '@app/shared/components/form-dialog-base/form-dialog-base.component';
import { PleaseWaitComponent } from '@app/shared/components/please-wait/please-wait.component';
import { UserMultiSelectorComponent } from '@app/shared/components/user-multi-selector/user-multi-selector.component';
import { TodayDate } from '@app/shared/misc/today.date';
import { TranslocoModule } from '@jsverse/transloco';
import { ofType } from '@ngrx/effects';
import { MemoizedSelector } from '@ngrx/store';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { getTranslationProvider } from '../core/i18n/getTranslationProvider';
import { TranslatableFormDialogBaseComponent } from '../core/i18n/translatable-form-dialog-base.component';
import { TranslatableStringType } from '../core/i18n/types';
import { todoStatesFeature } from '../core/state/todo-states/todo-states.reducers';
import { BlFrontendTooltipComponent } from '../core/wrappers/bl-frontend-tooltip.component';
import { TodoStatusSelectorComponent } from '../shared/components/todo-status-selector/todo-status-selector.component';

const TRANSLATION_SCOPE = 'todoDialogComponent';

export interface TodoDialogData extends DialogData {
  todo: TodoType;
}

export interface TodoDialogResult extends DialogResult {
  todo: TodoType;
}

interface TodoDialogForm {
  assignment: FormControl<AssignmentType>;
  client: FormControl<SimpleClientType>;
  creator: FormControl<SimpleUserType>;
  deadline: FormControl<string>;
  entryDate: FormControl<string>;
  state: FormControl<NameDescriptionType>;
  text: FormControl<string>;
  todoChecked: FormControl<boolean>;
  type: FormControl<NameDescriptionType>;
  userIds: FormControl<number[]>;
}

interface TodoDialogTranslations {
  createTodoEntry: TranslatableStringType;
}

@Component({
  selector: 'app-todo-dialog',
  templateUrl: './todo.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [getTranslationProvider(TRANSLATION_SCOPE)],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    CalendarComponent,
    InputTextareaModule,
    CheckboxModule,
    UserMultiSelectorComponent,
    PleaseWaitComponent,
    BlFrontendButtonComponent,
    BlFrontendTooltipComponent,
    TranslocoModule,
    TodoStatusSelectorComponent,
  ],
})
export class TodoDialogComponent extends TranslatableFormDialogBaseComponent<
  TodoDialogData,
  TodoDialogResult,
  TodoDialogForm
> {
  public types$: Observable<NameDescriptionType[]>;
  public assignments$: Observable<AssignmentType[]>;
  public clients$: Observable<SimpleClientType[]>;
  public usersBS: BehaviorSubject<SimpleUserType[]>;
  public selector: MemoizedSelector<AppState, SimpleUserType[]>;
  public hidePleaseWait = true;
  private currentUserBS: BehaviorSubject<SimpleUserType>;

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const allUsers = this.usersBS.value;
    const { userIds, ...formValues } = this.form.value;
    const users = userIds?.map((id: number) => allUsers.find((u) => u.id === id)).filter(Boolean);
    const todo = { ...this.config.data.todo, ...formValues, users };
    this.hidePleaseWait = false;
    this.store.dispatch(TodoActions.saveTodo({ todo }));
  }

  get formErrors() {
    if (!this.form.errors) {
      return '';
    }

    return this.translate('formErrorMessage');
  }

  protected override onInitialized = () => {
    this.currentUserBS = new BehaviorSubject({} as SimpleUserType);
    this.usersBS = new BehaviorSubject([]);
    this.selector = UserSelectors.activeUsersWithoutAllAsSimpleUser;
    this.assignments$ = this.store.select(AssignmentSelectors.assignmentsWithChoose);
    this.clients$ = this.store.select(ClientSelectors.allSimpleClients);
    this.types$ = this.store.select(todoStatesFeature.selectTypes);
    this.store
      .select(UserSelectors.activeUsersWithoutAllAsSimpleUser)
      .pipe(this.takeUntilDestroyed())
      .subscribe(this.usersBS);
    this.store
      .select(AuthSelectors.selectAuthAsSimpleUser)
      .pipe(
        filter((u) => Boolean(u)),
        this.takeUntilDestroyed(),
      )
      .subscribe(this.currentUserBS);

    this.actions$.pipe(ofType(TodoActions.saveTodoSucceeded, TodoActions.saveTodoFailed)).subscribe((action) => {
      this.hidePleaseWait = true;
      if (action.type === TodoActions.saveTodoSucceeded.type) {
        this.close({ todo: action.todo });
        return;
      }

      this.close({ todo: null });
    });
    this.initForm();
    this.form.controls.todoChecked.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((checked) => {
      this.onTodoCheckedChanged(checked);
    });
  };

  protected static override getTranslationScope(): string {
    return TRANSLATION_SCOPE;
  }

  protected static override getDialogConfig(
    translation: Record<keyof TodoDialogTranslations, TranslatableStringType>,
  ): Omit<DynamicDialogConfig<TodoDialogData>, 'data'> {
    return {
      header: translation.createTodoEntry,
      width: '630px',
      closeOnEscape: false,
      closable: false,
      modal: false,
      draggable: true,
    };
  }

  private onTodoCheckedChanged(checked: boolean) {
    const controls = [this.form.controls.userIds, this.form.controls.deadline, this.form.controls.state];
    controls.forEach((c) => (checked ? c.enable() : c.disable()));
  }

  private initForm() {
    const { assignment, client, deadline, selectableTypes, text, type } = this.config.data.todo;

    const todoChecked = this.getTodoChecked();
    const creator = this.getCreator();
    const state = this.getState();
    const entryDate = this.getEntryDate();
    const userIds = this.getUserIds();

    this.form = this.builder.group(
      {
        assignment: new FormControl(assignment),
        client: new FormControl(client),
        creator: new FormControl(creator),
        deadline: new FormControl({ value: deadline, disabled: !todoChecked }, [
          validateDateFn({ key: 'deadline', canBeEmpty: true }),
        ]),
        entryDate: new FormControl({ value: entryDate, disabled: false }, [validateDateFn({ key: 'entryDate' })]),
        state: new FormControl({ value: state, disabled: !todoChecked }),
        text: new FormControl(text),
        todoChecked: new FormControl(todoChecked),
        type: new FormControl(type?.name ? type : selectableTypes[0]),
        userIds: new FormControl({ value: userIds, disabled: !todoChecked }),
      },
      { validators: this.validateRequiredFields() },
    );
  }

  private getTodoChecked(): boolean {
    const { users } = this.config.data.todo;
    return Boolean(users?.length);
  }

  private getCreator(): SimpleUserType {
    const { creator } = this.config.data.todo;
    return creator ?? this.currentUserBS.value;
  }

  private getState(): NameDescriptionType {
    const { state } = this.config.data.todo;
    return state ?? TodoSelectableStates[0];
  }

  private getEntryDate(): string {
    const { entryDate } = this.config.data.todo;
    return entryDate ?? new TodayDate().getTodayAsString();
  }

  private getUserIds(): number[] {
    const { users } = this.config.data.todo;
    return users?.length ? users.map((u) => u.id) : [this.getCreator().id];
  }

  private validateRequiredFields(): ValidatorFn {
    return (form: FormGroup<TodoDialogForm>): ValidationErrors | null => {
      const todoChecked = form.controls.todoChecked.value;
      const users = form.controls.userIds.value;
      const client = form.controls.client.value;

      if (Boolean(client) || (todoChecked && Boolean(users?.length))) {
        return null;
      }

      return {
        requiredFields: {
          message: this.translate('requiredFieldsErrorMessage'),
        },
      };
    };
  }
}
