<ng-container *transloco="let t; prefix: this.scope">
  <main class="flex w-full h-full">
    <section class="relative w-1/2">
      <div class="flex mb-2">
        <h4 class="mr-4">{{ firmData.corporateIdentity }}</h4>
        <h4>{{ firmData.name }}</h4>
      </div>
      <form [formGroup]="firmDataForm" (ngSubmit)="updateFirmData()">
        <label class="text-bl-grey" for="firm_adress">{{ t('address') }}</label>
        <input
          type="text"
          pInputText
          formControlName="address"
          name="firm_adress"
          class="w-full"
          [placeholder]="t('enterAddress')" />
        <div class="flex mt-3">
          <div>
            <label class="!block text-bl-grey" for="firm_zipcode">{{ t('zipCode') }}</label>
            <input
              pInputText
              id="zipcode"
              formControlName="zipcode"
              type="text"
              name="firm_zipcode"
              class="mr-2"
              [placeholder]="t('enterZipCode')" />
          </div>
          <div class="ml-2">
            <label class="!block text-bl-grey" for="firm_city">{{ t('city') }}</label>
            <input
              pInputText
              type="text"
              id="city"
              formControlName="city"
              name="firm_city"
              class="w-full"
              [placeholder]="t('enterCity')" />
          </div>
        </div>
        <div class="mt-3">
          <label class="text-bl-grey" for="firm_email">{{ t('email') }}</label>
          <input
            id="email"
            pInputText
            formControlName="email"
            class="w-full"
            type="text"
            name="firm_email"
            [placeholder]="t('enterEmail')" />
        </div>
        <div class="mt-3">
          <label class="leading-tight text-bl-grey" for="firm_phone">{{ t('phone') }}</label>
          <input
            pInputText
            id="phone"
            formControlName="phone"
            class="w-full"
            type="text"
            name="firm_phone"
            [placeholder]="t('enterPhone')" />
        </div>
        <div class="mt-3" formGroupName="properties">
          <h5 class="leading-tight text-bl-grey">{{ t('other') }}</h5>
          <p-checkbox
            *appHideIfCountries="'NL'"
            [binary]="true"
            formControlName="include_activities_for_archived_clients"
            [label]="t('includeActivitiesForArchivedClients')"></p-checkbox>
          <p-checkbox
            class="mt-3"
            [binary]="true"
            formControlName="show_hidden_databases_for_all_users"
            [label]="t('showHiddenDatabasesForAllUsers')"></p-checkbox>
        </div>
        <div class="mt-5">
          <bl-frontend-button type="submit" [disabled]="firmDataForm.pristine" [text]="t('saveFirmDetails')"></bl-frontend-button>
        </div>
      </form>
    </section>
  </main>
</ng-container>
