import { Injectable } from '@angular/core';
import { concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { TranslatableEffect } from '../../i18n/TranslatableEffects';
import { AssignmentService } from '../../services/assignment.service';
import { AssignmentType } from '../types';
import { AssignmentActions } from './assignments.actions';
import { AssignmentSelectors } from './assignments.selectors';
import { i18nActions } from '../i18n/i18n.actions';

@Injectable()
export class AssignmentEffects extends TranslatableEffect {
  constructor(private assignmentService: AssignmentService) {
    super('assignments');
  }

  loadAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentActions.loadAllAssignments),
      switchMap(() =>
        this.assignmentService.getAssignmentTypes().pipe(
          map((assignments) => AssignmentActions.translateAssignmentNames({ assignments })),
          catchError((error: unknown) => of(AssignmentActions.loadAssignmentsFailed({ error }))),
        ),
      ),
    ),
  );

  languageChangedStartTranslation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(i18nActions.languageChanged),
      concatLatestFrom(() => this.store.select(AssignmentSelectors.selectAssignments)),
      map(([, assignments]) => AssignmentActions.translateAssignmentNames({ assignments })),
    ),
  );

  translateAssignmentNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentActions.translateAssignmentNames),
      switchMap(({ assignments }) =>
        this.selectedTranslation$.pipe(
          first(Boolean),
          map((translations) => [translations, assignments]),
        ),
      ),
      map(([translations, assignments]: [Record<string, string>, AssignmentType[]]) =>
        AssignmentActions.loadAssignmentsSucceeded({
          assignments: this.translateAssignmentNames(assignments, translations),
        }),
      ),
    ),
  );

  private translateAssignmentNames(
    assignments: AssignmentType[],
    translations: Record<string, string>,
  ): AssignmentType[] {
    return assignments.map((assignment) => ({
      ...assignment,
      name: translations[assignment.label] || assignment.name,
    }));
  }
}
