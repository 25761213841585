<ng-container *transloco="let t; prefix: scope">
  <div class="flex">
    <div class="w-2/3">
      <p class="font-semibold">{{ t('linkToTermsOfAgreement') }}</p>
      <a [href]="termsOfAgreementUrl" target="_blank" style="color: black"
        ><i class="fa fa-caret-right" aria-hidden="true"></i> {{ t('termsOfAgreement') }}</a
      >
      <br /><br />
      <p class="font-semibold">{{ t('linkToDownloadChrome') }}</p>
      <a
        href="https://www.google.com/intl/sv/chrome/browser/desktop/index.html"
        target="_blank"
        rel="noopener"
        style="color: black"
        ><i class="fa fa-caret-right" aria-hidden="true"></i> Chrome</a
      >
      <br /><br />
      <p class="font-semibold">{{ t('linkToRemoteSupportTool') }}</p>
      <a href="https://download.blinfo.se/v2/public/fjarrsupport.exe" style="color: black"
        ><i class="fa fa-caret-right" aria-hidden="true"></i> {{ t('remoteSupport') }}
      </a>
      <br /><br />
      <p class="mb-0 font-semibold">{{ t('clearStoredSettings') }}</p>
      <p class="small">{{ t('clearedSettingsMessage') }}</p>
      <div class="cursor-pointer hover:underline" style="color: black" (click)="clearStorageAndLogout()">
        <i class="fa fa-caret-right" aria-hidden="true"></i> {{ t('clear') }}
      </div>
      <app-application-files [mode]="'system'" *appHideIfCountries="'NL'"></app-application-files>
    </div>
    <div class="border-bl-grey-200 border-l-2 mx-8"></div>
    <div class="w-1/3">
      <app-due-compliance-activation-box></app-due-compliance-activation-box>
    </div>
  </div>
</ng-container>
