import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-task-comment-icon',
  template: `<i *ngIf="title" class="fas fa-info-circle fa-md" [style]="style" [title]="title" aria-hidden="true"></i>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCommentIconComponent {
  @Input() title: string;
  @Input() componentStyle: string;

  private defaultComponentStyle = 'color: blue; padding-left: 5px; padding-right: 5px';

  // eslint-disable-next-line no-useless-constructor
  constructor(private sanitizer: DomSanitizer) {}

  get style() {
    const style = this.componentStyle ? this.componentStyle : this.defaultComponentStyle;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
