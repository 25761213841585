// export const LOCAL_STORAGE_SELECTED_LANGUAGE_KEY = 'i18n.language';
export const LOCAL_STORAGE_SHOW_KEYS_KEY = 'i18n.show-keys';

export enum TranslationConstants {
  ALL_WITH_HYPHENS = 'ALL_WITH_HYPHENS',
  SELECT_WITH_HYPHENS = 'SELECT_WITH_HYPHENS',
  ROOT_TEAM_NAME = 'ROOT_TEAM_NAME',
  INCLUDE_UNFINISHED_LABEL = 'INCLUDE_UNFINISHED_LABEL',
  INCLUDE_FINISHED_LABEL = 'INCLUDE_FINISHED_LABEL',
  ONLY_SHOW_ROWS_WITH_CONTENT_LABEL = 'ONLY_SHOW_ROWS_WITH_CONTENT_LABEL',
  CLIENT_RESPONSIBLE = 'CLIENT_RESPONSIBLE',
  INCLUDE_ARCHIVED_CLIENTS_LABEL = 'INCLUDE_ARCHIVED_CLIENTS_LABEL',
  LOGGED_IN_USER = 'LOGGED_IN_USER',
}
