<ng-container *transloco="let t; prefix: this.scope">
  <div
    class="flex py-4"
    style="background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #0d3952">
    <div class="flex items-center text-white ml-2">
      <img src="/assets/BL-LogoMark.png" alt="Bjorn Lunden" />
      <span class="uppercase text-white text-base font-bold ml-4" style="letter-spacing: 0.2em">{{
        t('firmSupport')
      }}</span>
    </div>
    <div *ngIf="loggedInUser" class="flex flex-1 items-center justify-evenly">
      <div class="flex">
        <div
          class="flex items-center text-white font-medium rounded cursor-pointer px-4 py-2 menuDropdownButton"
          style="background-color: rgba(255, 255, 255, 0.1)"
          (click)="newNote.emit(true)">
          <span>{{ t('createNewNote') }}</span>
          <i class="fal fa-light fa-calendar-plus fa-lg ml-2"></i>
        </div>
      </div>

      <bl-frontend-button
        [text]="t('getStartedWithAml')"
        class="self-center"
        brand="blapp"
        (click)="onDueComplianceClick()">
      </bl-frontend-button>
    </div>
    <div *ngIf="loggedInUser" class="flex items-center text-white">
      <span *ngIf="loggedInUser" class="text-white text-xl font-medium mr-6">{{ loggedInUserFirm }}</span>

      <ng-container *appHideIfCountries="'NL'">
        <div *appPartnerProgramAccess>
          <div
            class="flex items-center text-white font-medium rounded cursor-pointer px-4 py-2 mr-6 menuDropdownButton relative"
            style="background-color: rgba(255, 255, 255, 0.1)"
            (click)="navigateToPartnerProgram()">
            <i class="fal fa-light fa-heart mr-2"></i>
            <span>{{ t('partnerProgram') }}</span>
            <div
              *ngIf="(missingPartnerPackages$ | async) > 0"
              class="flex absolute pin-r pin-t -top-2 -right-2 rounded-full text-white text-xs bg-bl-orange-500 h-5 w-5 justify-center items-center">
              {{ missingPartnerPackages$ | async }}
            </div>
          </div>
        </div>
      </ng-container>

      <div
        class="flex items-center text-white font-medium rounded cursor-pointer px-4 py-2 mr-6 menuDropdownButton"
        style="background-color: rgba(255, 255, 255, 0.1)"
        (click)="settings.emit(true)">
        <i class="fal fa-light fa-cog mr-2"></i>
        <span>{{ t('firmSettings') }}</span>
      </div>

      <div class="mr-6">
        <p-menu #helpMenu [model]="helpItems" [popup]="true"> </p-menu>
        <button
          pButton
          type="button"
          class="menuDropdownButton"
          icon="fal fa-light fa-chevron-down"
          iconPos="right"
          [label]="t('help')"
          style="background-color: rgba(255, 255, 255, 0.1); border: none"
          (click)="helpMenu.toggle($event)"></button>
      </div>

      <div class="mr-6">
        <p-menu #profileMenu id="profileMenu" [model]="profileItems" [popup]="true" (onShow)="onProfileMenuShow()">
          <ng-template pTemplate="item" let-item>
            <a class="pl-4 py-3 p-menuitem-link" [ngClass]="item.childStyleClass" tabindex="-1">
              <span class="p-menuitem-text">{{ item.label }}</span>
              <i [ngClass]="item.icon" class="ml-2"></i>
            </a>
          </ng-template>
        </p-menu>
        <button
          pButton
          type="button"
          icon="fa fa-lg fa-solid fa-user-circle"
          class="menuDropdownButton"
          style="background-color: rgba(255, 255, 255, 0.1); border: none"
          (click)="profileMenu.toggle($event)">
          <span class="text-white font-medium mx-2">{{ loggedInUser.name }}</span>
          <i class="fal fa-light fa-chevron-down"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>
