<p-dialog
  [header]="dialogHeaderText$ | async"
  [modal]="true"
  [dismissableMask]="true"
  [visible]="visible$ | async"
  [style]="{ width: '730px' }"
  [draggable]="false"
  [closable]="false"
  showEffect="fade"
  (visibleChange)="onCancelClick()"
  (onHide)="onHideDialog()">
  <ng-container [ngTemplateOutlet]="stepTemplate$ | async"></ng-container>

  <p-footer>
    <ng-container [ngTemplateOutlet]="footerStepTemplate$ | async"></ng-container>
  </p-footer>
</p-dialog>

<ng-template #step1>
  <ng-container *transloco="let t; prefix: this.scope">
    <div [formGroup]="form">
      <label class="block text-bl-grey" for="name">{{ t('name') }}</label>
      <input
        pInputText
        type="text"
        name="name"
        formControlName="name"
        class="w-full"
        [placeholder]="t('enterTeamName')" />

      <label class="block text-bl-grey mt-5" for="name"
        >{{ t('chooseEmployees') }}
        <i
          class="fal fa-question-circle fa-lg text-bl-blue-light-500 px-2 cursor-help"
          [pTooltip]="t('adminIsNotShownInList')"></i>
      </label>
      <app-user-multi-selector
        formControlName="users"
        [selector]="usersWithoutAdmin"
        [emptyMessage]="t('noEmployeesFound')"></app-user-multi-selector>
    </div>
  </ng-container>
</ng-template>

<ng-template #step2Clients>
  <div [formGroup]="form">
    <app-team-client-list formControlName="clients"></app-team-client-list>
  </div>
</ng-template>

<ng-template #step2Tasks>
  <div [formGroup]="form">
    <app-team-task-list formControlName="tasks"></app-team-task-list>
  </div>
</ng-template>

<ng-template #footerStep1>
  <bl-frontend-button
    (click)="onCancelClick()"
    variant="secondary"
    [text]="'shared.cancel' | transloco"></bl-frontend-button>
  <bl-frontend-button
    (click)="onNextStepClick()"
    [text]="'shared.continue' | transloco"
    [disabled]="form.invalid"></bl-frontend-button>
</ng-template>

<ng-template #footerStep2>
  <bl-frontend-button
    (click)="onPreviousStepClick()"
    variant="secondary"
    [text]="'shared.back' | transloco"></bl-frontend-button>
  <ng-container *transloco="let t; prefix: this.scope">
    <bl-frontend-button
      (click)="onConfirmClick()"
      [disabled]="form.invalid"
      [text]="t('createTeam')"></bl-frontend-button>
  </ng-container>
</ng-template>
