import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { TranslocoModule } from '@jsverse/transloco';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableFormDialogBaseComponent } from 'src/app/core/i18n/translatable-form-dialog-base.component';

@Component({
  template: `
    <ng-container *transloco="let t; prefix: scope">
      <p>{{ t('closeIntegrationMessage') }}</p>
      <p [innerHTML]="t('contactDueToEndSubscription')"></p>

      <div class="flex flex-row w-full justify-end mt-8" *transloco="let t2; prefix: 'shared'">
        <bl-frontend-button
          type="button"
          variant="secondary"
          (click)="close(null)"
          [text]="t2('cancel')"></bl-frontend-button>
        <bl-frontend-button
          (click)="close(true)"
          variant="primary-negative"
          class="ml-2"
          [text]="t('yesCloseIntegration')"></bl-frontend-button>
      </div>
    </ng-container>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BlFrontendButtonComponent, TranslocoModule],
  providers: [getTranslationProvider('deactivateIntegrationDialogComponent')],
})
export class DeactivateIntegrationDialogComponent extends TranslatableFormDialogBaseComponent<null, boolean> {
  protected static override getTranslationScope(): string {
    return 'deactivateIntegrationDialogComponent';
  }

  protected static override getDialogConfig(translation: Record<string, string>): Omit<DynamicDialogConfig, 'data'> {
    return {
      header: translation.confirmRemovalOfConnection,
      width: '530px',
      height: 'auto',
      modal: true,
    };
  }
}
