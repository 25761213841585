import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { RoleType } from '@app/core/state/types';
import { BlFrontendBadgeComponent } from '@app/core/wrappers/bl-frontend-badge.component';
import { BlFrontendButtonComponent } from '@app/core/wrappers/bl-frontend-button.component';
import { openTranslatableDialog, openTranslatableDialogWithConfig } from '@app/shared/misc/openDialog';
import { DialogService } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, combineLatest, filter, map, mergeMap, take } from 'rxjs';
import { ActivateIntegrationDialogComponent } from './activate-integration-dialog.component';
import { AmlService } from '@app/core/services/aml.service';
import { ToastActions } from '@app/core/state/toast/toast.actions';
import { DeactivateIntegrationDialogComponent } from './deactivate-integration-dialog.component';
import { TranslocoModule } from '@jsverse/transloco';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { TranslatableStringType } from 'src/app/core/i18n/types';

type StateType = { activeIntegration: boolean; isAdminUser: boolean };

interface DueComplianceActivationBoxTranslations {
  activateIntegrationWithDue: TranslatableStringType;
  updateIntegrationWithDue: TranslatableStringType;
  activationWasSuccessful: TranslatableStringType;
  integrationUpdateSuccessful: TranslatableStringType;
  contactDueToEndSubscription: TranslatableStringType;
  deactivationWasSuccessful: TranslatableStringType;
}

@Component({
  selector: 'app-due-compliance-activation-box',
  templateUrl: './due-compliance-activation-box.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    BlFrontendButtonComponent,
    BlFrontendBadgeComponent,
    TooltipModule,
    ActivateIntegrationDialogComponent,
    TranslocoModule,
  ],
  providers: [getTranslationProvider('DueComplianceActivationBoxComponent')],
})
export class DueComplianceActivationBoxComponent extends TranslatableComponent {
  state$: Observable<StateType>;

  private i18n: DueComplianceActivationBoxTranslations;

  constructor(
    private dialogService: DialogService,
    private amlService: AmlService,
  ) {
    super();
    this.state$ = this.createStateObservable();
    this.i18n = {} as DueComplianceActivationBoxTranslations;
  }

  protected override onTranslationsLoaded(): void {
    this.i18n = {
      activateIntegrationWithDue: this.translate('activateIntegrationWithDue'),
      updateIntegrationWithDue: this.translate('updateIntegrationWithDue'),
      activationWasSuccessful: this.translate('activationWasSuccessful'),
      integrationUpdateSuccessful: this.translate('integrationUpdateSuccessful'),
      contactDueToEndSubscription: this.translate('contactDueToEndSubscription'),
      deactivationWasSuccessful: this.translate('deactivationWasSuccessful'),
    };
  }

  showActivateIntegrationDialog() {
    this.openActivateIntegrationDialog(this.i18n.activateIntegrationWithDue)
      .pipe(mergeMap((response) => this.amlService.activateIntegration(response)))
      .subscribe({
        next: () => this.showActivationSuccessMessage(),
      });
  }

  showSettingsIntegrationDialog() {
    this.store
      .select(FirmSelectors.selectAmlApiEmail)
      .pipe(
        take(1),
        mergeMap((email) => this.openActivateIntegrationDialog(this.i18n.updateIntegrationWithDue, email)),
        mergeMap((response) => this.amlService.updateActiveIntegrationCredentials(response)),
      )
      .subscribe({
        next: () => this.showUpdatedActivationSuccessMessage(),
      });
  }

  private openActivateIntegrationDialog(header: string, email: string = null) {
    const config = {
      header,
      data: { email },
      width: '430px',
      height: 'auto',
      modal: true,
    };

    return openTranslatableDialogWithConfig(this.dialogService, ActivateIntegrationDialogComponent, config).pipe(
      filter((response) => !!response),
    );
  }

  showDeactivateIntegrationDialog() {
    openTranslatableDialog({
      deps: { dialogService: this.dialogService, translationService: this.translationService },
      componentType: DeactivateIntegrationDialogComponent,
      data: null,
    })
      .pipe(
        filter((response) => !!response),
        mergeMap(() => this.amlService.deactivateIntegration()),
      )
      .subscribe({
        next: () => this.showDeactivationSuccessMessage(),
      });
  }

  private createStateObservable(): Observable<StateType> {
    const hasActiveIntegration$ = this.store.select(FirmSelectors.selectHasActiveAmlConnection);

    const isAdminUser$ = this.store
      .select(AuthSelectors.selectAuthUser)
      .pipe(map((user) => user?.role === RoleType.FIRM_ADMIN));

    return combineLatest([hasActiveIntegration$, isAdminUser$]).pipe(
      map(([activeIntegration, isAdminUser]) => ({ activeIntegration, isAdminUser })),
    );
  }

  private showActivationSuccessMessage() {
    this.store.dispatch(ToastActions.showInfoMessage({ detail: '', summary: this.i18n.activationWasSuccessful }));
  }

  private showUpdatedActivationSuccessMessage() {
    this.store.dispatch(ToastActions.showInfoMessage({ detail: '', summary: this.i18n.integrationUpdateSuccessful }));
  }

  private showDeactivationSuccessMessage() {
    this.store.dispatch(
      ToastActions.showInfoMessage({
        detail: this.i18n.contactDueToEndSubscription,
        summary: this.i18n.deactivationWasSuccessful,
      }),
    );
  }
}
