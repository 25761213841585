import { Injectable } from '@angular/core';
import { Translation } from '@jsverse/transloco';
import { concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { first, map, switchMap } from 'rxjs';
import { TranslatableEffect } from '../../i18n/TranslatableEffects';
import { NameDescriptionType } from '../types';
import { TodoStatesActions } from './todo-states.actions';
import { todoStatesFeature } from './todo-states.reducers';
import { i18nActions } from '../i18n/i18n.actions';

@Injectable()
export class TodoStatesEffects extends TranslatableEffect {
  constructor() {
    super('todoStates');
  }

  updateStatesDescriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(i18nActions.languageChanged),
      switchMap(() =>
        this.selectedTranslation$.pipe(
          first(Boolean),
          map((translation) => this.filterTranslations(translation, 'selectableStates')),
        ),
      ),
      concatLatestFrom(() => this.store.select(todoStatesFeature.selectStates)),
      map(([translations, selectableStates]: [Translation, NameDescriptionType[]]) =>
        TodoStatesActions.updateStatesDescriptions({
          states: this.translateNameDescriptionType(selectableStates, translations),
        }),
      ),
    ),
  );

  updateTypesDescriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(i18nActions.languageChanged),
      switchMap(() =>
        this.selectedTranslation$.pipe(
          first(Boolean),
          map((translation) => this.filterTranslations(translation, 'selectableTypes')),
        ),
      ),
      concatLatestFrom(() => this.store.select(todoStatesFeature.selectTypes)),
      map(([translations, selectableTypes]: [Translation, NameDescriptionType[]]) =>
        TodoStatesActions.updateTypesDescriptions({
          types: this.translateNameDescriptionType(selectableTypes, translations),
        }),
      ),
    ),
  );

  private filterTranslations(translation: Translation, key: string): Record<string, string> {
    return Object.entries(translation).reduce((acc, [translationKey, value]) => {
      if (translationKey.startsWith(key)) {
        const keyWithoutPrefix = translationKey.slice(key.length + 1); // note: the translation key is in the format 'selectableStates.name'
        acc[keyWithoutPrefix] = value;
      }
      return acc;
    }, {});
  }

  private translateNameDescriptionType(
    items: NameDescriptionType[],
    translations: Record<string, string>,
  ): NameDescriptionType[] {
    return items.map((item) => ({
      name: item.name,
      description: translations[item.name] ?? item.description,
    }));
  }
}
