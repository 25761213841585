<app-please-wait [hidden]="hidePleaseWait"></app-please-wait>
<ng-container *transloco="let t; prefix: scope">
  <p>{{ t('selectWhatToImport') }}</p>
  <p-dropdown
    [(ngModel)]="selectedImportType"
    optionValue="value"
    [options]="importTypeSelectOptions$ | async"
    [autoOptionFocus]="false"
    [autoDisplayFirst]="false">
  </p-dropdown>

  <div class="mt-3 mb-5">
    <label class="btn btn-primary btn-file">
      {{ t('selectFile') }}
      <input
        #filechooser
        type="file"
        style="display: none"
        (change)="changeListener(filechooser)"
        [accept]="selectedImportType === 'client' ? '.csv, .txt, text/csv' : '.csv, text/csv'" />
    </label>
    {{ filePath }}
  </div>

  <div *ngIf="isVisible && isClientImportSelected">
    <input type="checkbox" [(ngModel)]="overWriteExistingOrgNumbers" /> {{ t('overwriteExistingClients') }}<br />
    <input type="checkbox" [(ngModel)]="includeEmptyOrgNumbers" /> {{ t('includeEmptyOrgNumbers') }}<br />
    <ng-container *appHideIfCountries="'NL'">
      <input type="checkbox" [(ngModel)]="applyDefaulTemplates" /> {{ t('addDefaultTemplates') }}<br />
      <br />
      <h5>{{ t('enterStartdateForTasks') }}</h5>
      <app-calendar
        [(selectedDate)]="templateStartDate"
        defaultDate=""
        appendTo="body"
        styleClass="w-48"
        [disabled]="!applyDefaulTemplates"></app-calendar>
      <br /><br />
    </ng-container>
    <input type="checkbox" [(ngModel)]="applyClientResponsible" /> {{ t('selectResponsibleUser') }}<br />
    <p-dropdown
      [disabled]="!applyClientResponsible"
      [options]="availableUsersWithoutAll$ | async"
      [(ngModel)]="selectedUserId"
      [style]="{ width: '200px' }"
      appendTo="body"></p-dropdown
    ><br /><br />
    <button
      type="button"
      class="btn btn-success"
      [disabled]="!filePath || !hidePleaseWait"
      (click)="startImport(filechooser)">
      {{ t('import') }}</button
    ><br /><br />
    <div *ngIf="ignoredImports.length">
      <p class="font-semibold">{{ t('ignoredImportRows') }}</p>
      <app-table
        [columns]="ignoredImportsColumns$ | async"
        [data]="ignoredImports"
        [searchField]="false"
        [columnSelector]="false"
        [paginator]="false">
        <ng-template tableTemplate="summary" let-data>
          {{ t('nrOfNonImportedClients', { numOfRows: data.length }) }}
        </ng-template>
      </app-table>
    </div>
  </div>

  <div *ngIf="isVisible && isDeferalImportSelected">
    <input type="checkbox" [(ngModel)]="clearPreviousDeferals" /> {{ t('clearPreviousDeferals') }}<br />
    <br /><br />
    <button
      type="button"
      class="btn btn-success"
      [disabled]="!filePath || !hidePleaseWait"
      (click)="startImport(filechooser)">
      {{ t('import') }}</button
    ><br /><br />
  </div>
</ng-container>
