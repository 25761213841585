import { Component } from '@angular/core';
import { AdvisoryToolService } from '@app/advisory-tool/services/advisory-tool.service';
import { FeaturesService } from '@app/core/services/features.service';
import { NotesService } from '@app/core/services/notes.service';
import { AuthSelectors } from '@app/core/state/auth/auth.selectors';
import { initialFeaturesState } from '@app/core/state/feature/feature.reducer';
import { FeatureSelectors } from '@app/core/state/feature/feature.selectors';
import { FeatureType, PrivilegeType } from '@app/core/state/types';
import { filter, map, Observable, of, switchMap } from 'rxjs';
import { CollaborationPackageSelectors } from '@core/state/collaboration-packages/collaboration-packages.selectors';
import { getTranslationProvider } from '../core/i18n/getTranslationProvider';
import { TranslatableComponent } from '../core/i18n/TranslatableComponent';

@Component({
  templateUrl: './main-app.component.html',
  host: { class: 'flex flex-col h-full' },
  providers: [getTranslationProvider('MainAppComponent')],
})
export class MainAppComponent extends TranslatableComponent {
  public unfinishedTodos$: Observable<number>;
  public advisoryNotification$: Observable<number>;
  public numberOfMissingCollaborationPackages$: Observable<number>;

  private hasAdminPrivilege: boolean;
  private features: FeatureType;

  constructor(
    featuresService: FeaturesService,
    private notesService: NotesService,
    private advisoryService: AdvisoryToolService,
  ) {
    super();
    featuresService.init();

    this.hasAdminPrivilege = false;
    this.features = { ...initialFeaturesState.features };

    this.store
      .select(AuthSelectors.selectAuthUser)
      .pipe(filter((user) => Boolean(user)))
      .subscribe((user) => {
        this.hasAdminPrivilege = user.privilege === PrivilegeType.ADMIN;
      });

    this.store
      .select(FeatureSelectors.selectFeaturesState)
      .pipe(filter((features) => Boolean(features?.initialized)))
      .subscribe(({ features }) => {
        this.features = features;

        if (!this.features.i18nSupport) {
          this.setActiveLanguage('sv');
        }
      });

    this.setupNotificationObservables();
  }

  public isAdminAndFeatureIsActive = (feature: string): boolean => this.features[feature] && this.hasAdminPrivilege;

  private setupNotificationObservables() {
    this.unfinishedTodos$ = this.notesService.unfinishedTodos$;

    this.advisoryNotification$ = this.advisoryService.showNotificationCount$.pipe(
      switchMap((showAdvisoryNotifications) =>
        showAdvisoryNotifications ? this.advisoryService.notificationsCount$ : of(null),
      ),
    );

    this.numberOfMissingCollaborationPackages$ = this.store
      .select(CollaborationPackageSelectors.selectNumberOfMissingCollaborationPackages)
      .pipe(map((value) => (value > 0 ? value : null)));
  }
}
