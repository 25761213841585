import { Pipe, PipeTransform } from '@angular/core';
import { TranslatableString } from 'src/app/core/i18n/TranslatableString';

@Pipe({
  name: 'transformParam',
})
export class TransformParamPipe implements PipeTransform {
  transform(value: string, param: string): string {
    if (!value || !param) {
      return value;
    }

    // replace the <<param>> with the "{0}" param which can't be used in translation files,
    // because they are also params for transloco
    return new TranslatableString(value).withParams({ [param]: '{0}' }).toString();
  }
}
