import { Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { combineLatest, Observable } from 'rxjs';
import { Client } from '../../core/entity/client';
import { getDateValidator } from 'src/app/core/entity/validation';
import { ValidationStatus } from 'src/app/core/entity/validationStatus';
import { ImportService } from '../../core/services/import.service';
import { TableColumn } from '../../shared/components/table/interfaces/table-column';
import { TodayDate } from '../../shared/misc/today.date';
import { ToastWithoutSeverity } from 'src/app/core/state/types';
import { ToastActions } from 'src/app/core/state/toast/toast.actions';
import { UserSelectors } from 'src/app/core/state/users/users.selectors';
import { map } from 'rxjs/operators';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { FirmSelectors } from 'src/app/core/state/firm/firm.selectors';
import { isSweden } from 'src/app/core/entity/locale';
import { TranslatableStringType } from 'src/app/core/i18n/types';

declare let jschardet: any;

enum ImportType {
  CLIENT = 'client',
  DEFERAL = 'deferal',
}

interface FileImportComponentTranslations {
  clients: TranslatableStringType;
  deferal: TranslatableStringType;
  name: TranslatableStringType;
  orgNumber: TranslatableStringType;
  address: TranslatableStringType;
  financialYearStart: TranslatableStringType;
  financialYearEnd: TranslatableStringType;
  enterStartDateForTasks: TranslatableStringType;
  fileformat: TranslatableStringType;
  importFileWrongFormatError: TranslatableStringType;
}

@Component({
  selector: 'app-file-import',
  templateUrl: './file-import.component.html',
  providers: [ImportService, getTranslationProvider('FileImportComponent')],
})
export class FileImportComponent extends TranslatableComponent<FileImportComponentTranslations> implements OnInit {
  @Input()
  set tabActive(active: boolean) {
    this.isVisible = active;
    this.hidePleaseWait = true;
    if (!this.isVisible) {
      this.filePath = '';
      this.selectedImportType = ImportType.CLIENT;
    }
  }

  public availableUsersWithoutAll$: Observable<SelectItem[]>;
  public ignoredImportsColumns$: Observable<TableColumn[]>;
  public filePath = '';
  public isVisible = false;

  hidePleaseWait = true;
  overWriteExistingOrgNumbers = false;
  clearPreviousDeferals = false;
  includeEmptyOrgNumbers = false;
  applyDefaulTemplates = false;
  applyClientResponsible = false;
  templateStartDate: string = new TodayDate().getTodayAsString();
  selectedUserId: number;
  selectedImportType: string = ImportType.CLIENT;
  ignoredImports: Client[] = [];
  importTypeSelectOptions$: Observable<{ label: string; value: ImportType }[]>;

  private i18n: FileImportComponentTranslations;

  get isClientImportSelected() {
    return this.selectedImportType === ImportType.CLIENT;
  }

  get isDeferalImportSelected() {
    return this.selectedImportType === ImportType.DEFERAL;
  }

  constructor(public importService: ImportService) {
    super();
    this.i18n = {} as FileImportComponentTranslations;
    this.importTypeSelectOptions$ = this.getImportTypeOptionsObservable();
    this.ignoredImportsColumns$ = this.getIgnoredImportsColumns();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.availableUsersWithoutAll$ = this.store.select(UserSelectors.activeUsersWithoutAll).pipe(
      map((users) => {
        if (users.length) {
          this.selectedUserId = users[0].id;
        }

        return users.map(({ name, initials, id }) => ({ label: `${name} (${initials})`, value: id }));
      }),
    );
  }

  protected override onTranslationsLoaded(): void {
    this.i18n = {
      clients: this.translate('clients'),
      deferal: this.translate('deferal'),
      name: this.translate('name'),
      orgNumber: this.translate('orgNumber'),
      address: this.translate('address'),
      financialYearStart: this.translate('financialYearStart'),
      financialYearEnd: this.translate('financialYearEnd'),
      enterStartDateForTasks: this.translate('enterStartDateForTasks'),
      fileformat: this.translate('fileformat'),
      importFileWrongFormatError: this.translate('importFileWrongFormatError'),
    };
  }

  changeListener(inputValue: any): void {
    if (inputValue && inputValue.files && inputValue.files.length > 0) {
      this.filePath = inputValue.files[0].name;
    } else {
      this.filePath = '';
    }
    this.ignoredImports = [];
  }

  startImport(inputValue: any): void {
    const { validate, min, max } = getDateValidator();
    const { status, title, text } = validate(this.templateStartDate, {
      min,
      max,
      label: this.i18n.enterStartDateForTasks,
    });
    const applyTemplates = this.applyDefaulTemplates;
    const hasValidationError = status !== ValidationStatus.Ok;

    if (this.isClientImportSelected && applyTemplates && hasValidationError) {
      this.store.dispatch(ToastActions.showWarnMessage({ summary: title, detail: text }));
      return;
    }

    this.readThis(inputValue);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    const clientImportParameters = {
      includeEmptyOrgNumbers: this.includeEmptyOrgNumbers ? '1' : '0',
      overWriteExistingOrgNumbers: this.overWriteExistingOrgNumbers ? '1' : '0',
      clientResponsible: this.applyClientResponsible ? String(this.selectedUserId) : '0',
      templatesDate: this.applyDefaulTemplates ? this.templateStartDate : '0',
    };

    this.hidePleaseWait = false;

    myReader.onloadend = () => {
      const result = this.isClientImportSelected
        ? jschardet.detect(myReader.result)
        : { encoding: 'UTF-8', confidence: 1.0 };

      if (!this.isValidEncoding(result) || result.confidence <= 0.85) {
        this.showErrorMessage({
          summary: this.i18n.fileformat,
          detail: this.i18n.importFileWrongFormatError,
        });
        this.hidePleaseWait = true;
        return;
      }

      if (this.isDeferalImportSelected) {
        this.importDeferal(myReader.result as string, this.clearPreviousDeferals);
        return;
      }

      this.importClients(myReader.result as string, clientImportParameters);
    };

    myReader.readAsText(file);
  }

  private getImportTypeOptionsObservable() {
    return combineLatest([this.translationsLoaded$, this.store.select(FirmSelectors.selectCountryCode)]).pipe(
      map(([translations, countryCode]) => {
        if (!isSweden(countryCode)) {
          return [{ label: translations.clients, value: ImportType.CLIENT }];
        }
        return [
          { label: translations.clients, value: ImportType.CLIENT },
          { label: translations.deferal, value: ImportType.DEFERAL },
        ];
      }),
    );
  }

  private getIgnoredImportsColumns() {
    return this.translationsLoaded$.pipe(
      map((translations) => {
        const toSafeString = (translationKey: keyof FileImportComponentTranslations) =>
          translations[translationKey] || '';

        return [
          { field: 'name', header: toSafeString('name'), sortable: false },
          { field: 'corporateIdentity', header: toSafeString('orgNumber'), sortable: false },
          { field: 'address', header: toSafeString('address'), sortable: false },
          { field: 'currentFinancialYear.span.start', header: toSafeString('financialYearStart'), sortable: false },
          { field: 'currentFinancialYear.span.end', header: toSafeString('financialYearEnd'), sortable: false },
        ];
      }),
    );
  }

  private isValidEncoding(data: any) {
    const validEncodings = ['utf-8', 'UTF-8', 'windows-1252'];
    return data && validEncodings.includes(data.encoding);
  }

  private setIgnoredImports(event: any) {
    this.hidePleaseWait = true;

    try {
      if (Array.isArray(event)) {
        this.ignoredImports = event;
      } else {
        this.ignoredImports = JSON.parse(event._body);
      }
    } catch (err: unknown) {
      this.showErrorMessage({
        summary: 'Okänt fel inträffade',
        detail: 'Importen genomföras men svaret kunde inte behandlas.',
      });
    }

    const extraMessage =
      this.ignoredImports.length > 0
        ? this.ignoredImports.length + ' importerades inte'
        : ' samtliga klienter har importerats';
    this.showInfoMessage({ summary: 'Importen avslutad', detail: extraMessage });
  }

  private importClients(
    body: string,
    importParameters: {
      includeEmptyOrgNumbers: string;
      overWriteExistingOrgNumbers: string;
      clientResponsible: string;
      templatesDate: string;
    },
  ) {
    this.importService.sendFileContentToImport(body, importParameters).subscribe(
      (ignored: any[]) => {
        if (ignored?.length > 0) {
          this.setIgnoredImports(ignored);
        }
        this.showInfoMessage({ summary: 'Import avslutad', detail: 'Klienter har importerats' });
        this.hidePleaseWait = true;
      },
      () => this.showImportFailedMessage(),
    );
  }

  private importDeferal(body: string, clearPreviousDeferals: boolean) {
    this.importService.sendFileContentToDeferalImport(body, clearPreviousDeferals).subscribe(
      () => {
        this.showInfoMessage({ summary: 'Import avslutad', detail: 'Anstånd har importerats' });
        this.hidePleaseWait = true;
      },
      () => this.showImportFailedMessage(),
    );
  }

  private showInfoMessage(config: ToastWithoutSeverity) {
    this.store.dispatch(ToastActions.showInfoMessage(config));
  }

  private showErrorMessage(config: ToastWithoutSeverity) {
    this.store.dispatch(ToastActions.showErrorMessage(config));
  }

  private showImportFailedMessage() {
    this.showErrorMessage({
      summary: 'Import avbruten',
      detail: 'Importen kunde inte genomföras',
    });
    this.hidePleaseWait = true;
  }
}
