import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { TranslocoModule } from '@jsverse/transloco';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';

@Component({
  selector: 'app-multiselect-dropdown-including-all',
  template: `
    <p-multiSelect
      [options]="options"
      [ngModel]="selected"
      (ngModelChange)="selectedChanged($event)"
      (onPanelShow)="onPanelShow()"
      (onPanelHide)="onPanelHide()"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [filter]="false"
      [showHeader]="false"
      [showToggleAll]="false"
      [selectedItemsLabel]="selectedItemsLabel"
      [maxSelectedLabels]="1"
      [scrollHeight]="scrollHeight"
      [autoOptionFocus]="false"
      [inputId]="inputId"
      [styleClass]="styleClass">
    </p-multiSelect>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, MultiSelectModule, TranslocoModule],
  providers: [getTranslationProvider('MultiSelectDropDownIncludingAllComponent')],
})
export class MultiSelectDropDownIncludingAllComponent extends TranslatableComponent implements OnChanges {
  @ViewChild(MultiSelect) multiSelect: MultiSelect;

  public selectedItemsLabel = '';
  public scrollHeight: string;

  private internalSelected: any[];
  private internalOptions: any[];
  private intialized = false;
  private allWithHyphensString = '';
  private selectedString = '';

  @Input() selectedItemLabel = '';
  @Input() allValue: string | number;
  @Input() allOption: any;
  @Input() optionLabel = 'label';
  @Input() optionValue = 'value';
  @Input() inputId: string;
  @Input() styleClass = '';

  @Input()
  get options(): any[] {
    return this.internalOptions;
  }

  set options(options: any[]) {
    if (!options?.length) {
      return;
    }

    this.internalOptions = this.allOption
      ? options
      : [{ [this.optionLabel]: this.allWithHyphensString, [this.optionValue]: this.allValue }].concat(options);
    this.scrollHeight = getScrollHeightString(this.internalOptions.length);
  }

  @Input()
  get selected(): any[] {
    return this.internalSelected;
  }

  set selected(selected: any[]) {
    this.internalSelected = this.selectedIsAllButAllvalue(selected) ? [this.allValue, ...selected] : selected;
    this.refreshSetItemsLabel();
  }

  @Output() selectedChange: EventEmitter<any[]>;

  constructor() {
    super();
    this.selectedChange = new EventEmitter();

    this.translationsLoaded$.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.allWithHyphensString = this.translate('constants.ALL_WITH_HYPHENS', { useComponentScope: false });
      this.selectedString = this.translate('shared.selected', { useComponentScope: false });

      this.runTranslationHack();
    });
  }

  // ngOnChanges makes sure we wait for async options to be loaded
  ngOnChanges(): void {
    if (this.intialized) {
      return;
    }

    if (!this.internalOptions?.length) {
      return;
    }

    // eslint-disable-next-line no-undefined
    if (this.allValue === undefined) {
      return;
    }

    this.intialized = true;
  }

  selectedChanged(data: unknown[]) {
    const prevIncludesAll = this.getPreviousIncludesAll();
    const nextIncludesAll = this.getNextIncludesAll(data);

    // all has been checked
    if (this.hasAllValueBeenChecked(prevIncludesAll, nextIncludesAll)) {
      this.selectAll();
      this.refreshSetItemsLabel();
      return;
    }

    // all has been unchecked
    if (this.hasAllValueBeenUnChecked(prevIncludesAll, nextIncludesAll)) {
      this.internalSelected = [];
      this.refreshSetItemsLabel();
      return;
    }

    // whenever all is selected and we uncheck all value
    if (this.hasOneBeenUnchecked(data)) {
      this.internalSelected = this.getSelectedWithoutAllValue(data);
      this.refreshSetItemsLabel();
      return;
    }

    // whenever we check the last one
    if (this.hasLastOneBeenChecked(data)) {
      this.internalSelected = this.getSelectedWithAllValue(data);
      this.refreshSetItemsLabel();
      return;
    }

    this.internalSelected = data;
    this.refreshSetItemsLabel();
  }

  onPanelShow() {}

  onPanelHide() {
    if (!this.selected || this.selected.length !== 0) {
      return;
    }
    this.selectAll();
  }

  private selectAll() {
    this.internalSelected = this.internalOptions.map((o) => o[this.optionValue]);
    this.refreshSetItemsLabel();
  }

  private refreshSetItemsLabel() {
    this.selectedChange.emit(this.selected);

    if (!this.internalOptions?.length) {
      this.setItemsLabelForNoOptions();
      return;
    }

    this.setItemsLabel();
  }

  private setItemsLabelForNoOptions() {
    if (this.allOption) {
      return;
    }
    this.selectedItemsLabel = !this.selected?.length ? this.allWithHyphensString : this.getSelectedItemsLabelString();
  }

  private setItemsLabel() {
    if (this.allOption) {
      this.setItemsLabelUsingAllOption();
      return;
    }

    this.selectedItemsLabel =
      this.selected?.length >= this.internalOptions?.length || this.selected?.length === 0
        ? this.allWithHyphensString
        : this.getSelectedItemsLabelString();
  }

  private setItemsLabelUsingAllOption = () => {
    this.selectedItemsLabel =
      this.selected?.length >= this.internalOptions?.length || this.selected?.length === 0
        ? this.getAllOptionLabel()
        : this.getSelectedItemsLabelString();
  };

  private getPreviousIncludesAll = () =>
    this.allOption ? this.selected.includes(this.getAllOptionValue()) : this.selected.includes(this.allValue);

  private getNextIncludesAll = (data: unknown[]) =>
    this.allOption ? data.includes(this.getAllOptionValue()) : data.includes(this.allValue);

  private hasAllValueBeenChecked = (prevIncludesAll: boolean, nextIncludesAll: boolean) =>
    nextIncludesAll && !prevIncludesAll;

  private hasAllValueBeenUnChecked = (prevIncludesAll: boolean, nextIncludesAll: boolean) =>
    prevIncludesAll && !nextIncludesAll;

  private hasOneBeenUnchecked = (data: unknown[]) =>
    this.selected.length === this.internalOptions.length && data.length < this.internalOptions.length;

  private hasLastOneBeenChecked = (data: unknown[]) =>
    this.selected.length === this.internalOptions.length - 2 && data.length === this.internalOptions.length - 1;

  private getSelectedWithoutAllValue = (data: unknown[]) =>
    this.allOption ? data.filter((d) => d !== this.getAllOptionValue()) : data.filter((d) => d !== this.allValue);

  private getSelectedWithAllValue = (data: unknown[]) =>
    this.allOption ? [this.getAllOptionValue(), ...data] : [this.allValue, ...data];

  private selectedIsAllButAllvalue = (selected: unknown[]) =>
    selected?.length === this.internalOptions?.length - 1 && !selected?.includes(this.allValue);

  private getAllOptionLabel = () => {
    if (!this.allOption) {
      return this.allWithHyphensString;
    }

    return this.allOption[this.optionLabel] || this.allOption.name || this.allWithHyphensString;
  };

  private getAllOptionValue = () => {
    if (!this.allOption) {
      return null;
    }

    return this.allOption[this.optionValue] || this.allOption.id || null;
  };

  private getSelectedItemsLabelString = () => `{0} ${this.selectedItemLabel} ${this.selectedString}`;

  private runTranslationHack = () => {
    if (!this.internalOptions?.length || !this.multiSelect) {
      return;
    }

    this.internalOptions = this.internalOptions.map((o) => {
      if (o[this.optionValue] === this.allValue) {
        return { ...o, [this.optionLabel]: this.allWithHyphensString };
      }

      return o;
    });

    const original = this.internalSelected.slice();
    this.multiSelect.updateModel([]);
    setTimeout(() => this.multiSelect.updateModel(original), 5);
  };
}
