import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
  QueryList,
  ContentChildren,
  Output,
  EventEmitter,
} from '@angular/core';
import { getScrollHeightString } from '@app/shared/misc/getScrollHeightString';
import { Table, TableColumnReorderEvent, TablePageEvent } from 'primeng/table';
import { memoize } from '../../decorators/memoize';
import { TableTemplateDirective } from './directives/table-template.directive';
import { TableColumn } from './interfaces/table-column';
import { TableHeader } from './interfaces/table-header';
import { TableService } from './services/table.service';
import { ObjectUtils } from 'primeng/utils';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state/appState';
import { FirmSelectors } from '@app/core/state/firm/firm.selectors';
import { CountryType } from '@app/core/entity/locale';
import { takeFirstTruthy } from '@app/core/misc/rxjs-operators';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  providers: [TableService],
})
export class TableComponent {
  // the key for this table in localstorage. Empty = not stored
  @Input() storageKey: string | undefined;
  @Input() data: any[];
  @Input()
  set columns(cols: TableColumn[]) {
    this.setColumnList(cols);
  }

  @Input() exportFilename = 'Untitled';
  @Input() rowsPerPage = 25;
  @Input() paginator = true;
  @Input() searchField = true;
  @Input() checkboxField = false;
  @Input() checkboxLabel: string;
  @Input() columnSelector = true;
  @Input() sortFirstColumn = false;
  @Input() sortField: string | undefined;
  @Input() rowClass: string;
  @Input() useFixedStyle = true;
  @Input() scrollHeight: string | undefined;
  @Input() customHeader: TableHeader[];
  @Input() additionalFilterFields: string[] | null = null;

  @Output() rowClick = new EventEmitter<{ columns: TableColumn[]; data: any }>();
  @Output() cellClick = new EventEmitter<{ column: TableColumn; data: any }>();
  @Output() pageChange = new EventEmitter<TablePageEvent>();
  @Output() checkboxChange = new EventEmitter<boolean>();

  @ViewChild('ptable', { static: true }) table: Table;
  @ViewChild('defaultTableBodyCell', { read: TemplateRef, static: true })
  defaultTableBodyCellTemplateRef: TemplateRef<any>;

  @ContentChildren(TableTemplateDirective) templates: QueryList<TableTemplateDirective>;

  public get columnSelectorOptions() {
    return this._columnSelectorOptions;
  }

  public get visibleSelectorOptions() {
    return this._visibleSelectorOptions;
  }

  public set visibleSelectorOptions(newColumns: TableColumn[]) {
    this._visibleSelectorOptions = newColumns;
  }

  public get visibleColumns() {
    return this._visibleColumns;
  }

  public get sortFieldKey() {
    if (this.sortFirstColumn) {
      return this.visibleColumns[0].field;
    }

    return this.sortField;
  }

  public get isScrollable() {
    return !!this.scrollHeight;
  }

  public get scrollHeightValue() {
    return this.isScrollable ? 'flex' : undefined;
  }

  public get maxHeightStyle() {
    return this.isScrollable ? { 'max-height': this.scrollHeight } : undefined;
  }

  public get tableStyle() {
    return this.useFixedStyle ? { 'table-layout': 'fixed' } : undefined;
  }

  public get globalFilterFields() {
    const fields: string[] = this.visibleColumns.reduce((acc, col) => {
      const newAcc = acc.concat(col.field);
      if (!col.additionalFilterFields) {
        return newAcc;
      }

      return newAcc.concat(col.additionalFilterFields);
    }, []);

    if (!this.additionalFilterFields?.length) {
      return fields;
    }

    return fields.concat(this.additionalFilterFields);
  }

  public columnPickerScrollHeight: string;
  showOnlyActiveTemplates = true;

  private _columns: TableColumn[];
  private _visibleColumns: TableColumn[];
  private _columnSelectorOptions: TableColumn[] = [];
  private _visibleSelectorOptions: TableColumn[] = [];

  // eslint-disable-next-line no-useless-constructor
  public constructor(
    private tableService: TableService,
    private store: Store<AppState>,
  ) {}

  public getColumnStyle(column: TableColumn) {
    if (!this.isScrollable) {
      return { width: column.width || 'auto' };
    }

    if (!column.width || column.width === 'auto') {
      return { flex: '1 1 0px' };
    }

    return { flex: `0 0 ${column.width}`, width: column.width };
  }

  public exportCSV() {
    // code copied and refactored from primeng table https://github.com/primefaces/primeng/blob/ac054f92f9c45be9709d2b0351e4ce0b5d1e430b/src/app/components/table/table.ts#L1860
    // details here: https://stackoverflow.com/a/155176

    const exportableColumns = this.getExportableColumns();
    // headers
    let csv = this.getHeadersAsCSV(exportableColumns);

    // body
    csv += this.getBodyAsCSV(exportableColumns);

    const blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;',
    });

    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    // eslint-disable-next-line no-undefined
    if (link.download !== undefined) {
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', `${this.exportFilename}.csv`);
      link.click();
      document.body.removeChild(link);
      return;
    }

    csv = `data:text/csv;charset=utf-8,${csv}`;
    window.open(encodeURI(csv));
    document.body.removeChild(link);
  }

  public getTemplateRef(templateName: string, defaultTemplateRef: TemplateRef<any> = undefined) {
    const template = this.templates.find((t) => t.name === templateName);
    return template ? template.templateRef : defaultTemplateRef;
  }

  public getCellData(rowData: any, col: TableColumn) {
    return col.field.split('.').reduce((prevObject, currentKey) => prevObject && prevObject[currentKey], rowData);
  }

  public onColReorder(event: TableColumnReorderEvent) {
    this.tableService.storeSortedColumnsState(this.storageKey, event.columns);
  }

  public onColResize(event: { element: HTMLElement; delta: number }) {
    this.tableService.storeWidthsState(this.storageKey, this.visibleColumns, event.element);
  }

  @memoize()
  public getCellTemplateRefForColumn(col: TableColumn): TemplateRef<any> {
    const bodyCellTemplates = this.getTemplates('bodyCell');
    let customTableBodyCell = bodyCellTemplates.find((t) => t.bodyCellField === col.field);

    if (!customTableBodyCell) {
      customTableBodyCell = bodyCellTemplates.find((t) => t.bodyCellField === 'default');
    }
    return (customTableBodyCell && customTableBodyCell.templateRef) || this.defaultTableBodyCellTemplateRef;
  }

  public updateSelectedColumns(newColumnsArray: TableColumn[]) {
    const selectedColumnFields = newColumnsArray.map((c) => c.field);
    const updatedColumns = this._columns.map((c) => ({
      ...c,
      visible: selectedColumnFields.includes(c.field) || c.selectable === false,
    }));

    this.tableService.storeSelectedColumnsState(
      this.storageKey,
      updatedColumns.filter((c) => c.visible !== false),
    );
    this.columns = updatedColumns;
  }

  public onPanelHide() {
    this.updateSelectedColumns(this._visibleSelectorOptions);
  }

  private getTemplates(templateName: string): TableTemplateDirective[] {
    return (this.templates?.toArray() || []).filter((t) => t.name === templateName);
  }

  private mapToSelectorOptions(cols: TableColumn[]) {
    return cols.filter((c) => c.selectable !== false).map((c) => ({ ...c, header: c.header || c.selectorLabel }));
  }

  private getFirmCountryCodeObservable() {
    return this.store.select(FirmSelectors.selectCountryCode).pipe(takeFirstTruthy());
  }

  private updateVisibleColumns(columns: TableColumn[]) {
    const isVisible = (col: TableColumn) => col.visible !== false;
    const isSelectable = (col: TableColumn) => col.selectable !== false;
    const isVisibleForCountry = (countryCode: CountryType) => (col: TableColumn) =>
      !col.hiddenForCountries || !col.hiddenForCountries.includes(countryCode);

    this.getFirmCountryCodeObservable().subscribe((countryCode: CountryType) => {
      this._columns = columns.filter(isVisibleForCountry(countryCode));
      this._visibleColumns = this._columns.filter(isVisible);
      this._visibleSelectorOptions = this._visibleColumns.filter(isSelectable);
      this._columnSelectorOptions = this.mapToSelectorOptions(this._columns);
      this.columnPickerScrollHeight = getScrollHeightString(this._columnSelectorOptions.length);
    });
  }

  private setColumnList(cols: TableColumn[]) {
    this.updateVisibleColumns(this.tableService.updateColumnsWithStoredState(this.storageKey, cols));
  }

  private getExportableColumns(columns: TableColumn[] = this.table.columns) {
    return columns.filter((c) => c.exportable !== false && c.field);
  }

  private getCSVEntry = (value: string, index: number, columns: TableColumn[]) =>
    `${value}${index < columns.length - 1 ? this.table.csvSeparator : ''}`;

  private getHeadersAsCSV(columns: TableColumn[]) {
    let csv = '\ufeff'; // this is the BOM character for utf-8 that fixes excel not showing utf-8 characters
    // headers
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];

      csv += this.getCSVEntry(`"${this.table.getExportHeader(column)}"`, i, columns);
    }

    return csv;
  }

  private getBodyAsCSV(columns: TableColumn[]) {
    let csv = '';
    const data = this.table.value || [];
    data.forEach((record) => {
      csv += '\n';
      csv += this.getRowAsCSV(record, columns);
    });

    return csv;
  }

  private getRowAsCSV(record: unknown, columns: TableColumn[]) {
    let csv = '';

    for (let j = 0; j < columns.length; j += 1) {
      const column = columns[j];

      if (column.fieldValue) {
        csv += this.getCSVEntry(`"${column.fieldValue(column.field, record)}"`, j, columns);
        // eslint-disable-next-line no-continue
        continue;
      }

      let cellData = ObjectUtils.resolveFieldData(record, column.field);

      if (!cellData) {
        cellData = '';
      }

      if (this.table.exportFunction) {
        cellData = this.table.exportFunction({
          data: cellData,
          field: column.field,
        });
      } else {
        cellData = String(cellData).replace(/"/g, '""');
      }

      csv += this.getCSVEntry(`"${cellData}"`, j, columns);
    }

    return csv;
  }
}
