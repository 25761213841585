<ng-container *transloco="let t; prefix: scope">
  <div>
    <p class="mb-6">{{ t('whatToDoWithCompany', { companyName }) }}</p>
    <bl-frontend-radiobuttons
      name="action"
      [items]="radioButtonsItems"
      (onChange)="actionChanged($event)"></bl-frontend-radiobuttons>

    <!-- onboarding part -->
    <div class="flex flex-col mt-4" *ngIf="isOnboardSelected">
      <label class="font-bold block" for="email">{{ t('emailToCompany') }} *</label>
      <input
        pInputText
        name="email"
        type="email"
        [placeholder]="t('email')"
        [ngModel]="email | async"
        (ngModelChange)="email.next($event)" />
    </div>

    <!-- onboard as mini part -->
    <div class="flex flex-col mt-4" *ngIf="isOnboardAsMiniSelected">
      <label class="font-bold block" for="email">{{ t('yourSocialSecurityNumber') }} *</label>
      <input
        pInputText
        name="socialSecurityNumber"
        [placeholder]="t('socialSecurityNumber')"
        [ngModel]="socialSecurityNumber | async"
        (ngModelChange)="socialSecurityNumber.next($event)" />

      <p class="mt-4" [innerHTML]="t('firmUserWillBeAdminMessage') | safeHtml"></p>
    </div>

    <!-- change packages part -->
    <bl-frontend-alert
      *ngIf="isChangePackageSelected"
      class="block mt-4"
      severity="info"
      [header]="t('changesAreMadeInBlapp')"
      [listItems]="[{ text: t('changePackageInfoMessage') }]"
      [useUnsafeHtml]="true"
      [dismissable]="false"></bl-frontend-alert>

    <!-- archive company part -->
    <div class="flex flex-col mt-4" *ngIf="isArchivedSelected">
      <label class="font-bold block" for="years">{{ t('numberOfYearsToArchive') }} *</label>
      <p>{{ t('youCanSelectBeween') }}</p>
      <div class="flex items-center">
        <input
          [title]="t('numberOfYearsToArchive')"
          pInputText
          class="w-20"
          name="years"
          type="number"
          [ngModel]="years | async"
          (ngModelChange)="years.next($event)"
          min="1"
          max="10" />
      </div>
      <div class="mt-4" *ngIf="existsInFirmSupport">
        <label class="font-bold block">{{ t('archiveInFirmSupport') }}</label>
        <bl-frontend-radiobuttons
          name="clientAction"
          [items]="clientActionRadioButtonsItems"
          (onChange)="clientActionChanged($event)"></bl-frontend-radiobuttons>
      </div>
      <div class="mt-4">
        <label class="font-bold block">{{ t('confirmArchive') }} *</label>
        <p-checkbox
          inputId="confirmArchive"
          [binary]="true"
          [label]="t('iUnderstandAndConfirmArchive')"
          [(ngModel)]="confirmArchive"></p-checkbox>
      </div>

      <div class="mt-4" *ngIf="showArchiveWarningIfRecentlyUsed">
        <bl-frontend-alert severity="warning" [content]="archiveWarningText" [useUnsafeHtml]="true"></bl-frontend-alert>
      </div>
    </div>

    <!-- unarchive company part -->
    <div class="flex flex-col mt-4" *ngIf="isUnarchiveSelected">
      <div class="mt-4">
        <label class="font-bold block" for="email">{{ t('yourSocialSecurityNumber') }} *</label>
        <input
          pInputText
          name="socialSecurityNumber"
          [placeholder]="t('socialSecurityNumber')"
          [ngModel]="socialSecurityNumber | async"
          (ngModelChange)="socialSecurityNumber.next($event)" />

        <p class="my-4" [innerHTML]="t('firmUserWillBeAdminMessage') | safeHtml"></p>
        <label class="font-bold block">{{ t('confirmUnarchive') }} *</label>
        <p-checkbox
          inputId="confirmUnarchive"
          [binary]="true"
          [label]="t('iUnderstandAndConfirmUnarchive')"
          [(ngModel)]="confirmUnarchive"></p-checkbox>
      </div>
    </div>

    <!-- delete company part -->
    <div class="flex flex-col mt-4" *ngIf="isDeleteSelected">
      <div *ngIf="existsInFirmSupport">
        <label class="font-bold block">{{ t('doYouWantToArchiveOrDeleteInFirmSupport') }}</label>
        <bl-frontend-radiobuttons
          name="clientAction"
          [items]="clientActionRadioButtonsItems"
          (onChange)="clientActionChanged($event)"></bl-frontend-radiobuttons>
      </div>
      <div class="mt-4">
        <label class="font-bold block">{{ t('confirmDelete') }} *</label>
        <p-checkbox
          inputId="confirmDelete"
          [binary]="true"
          [label]="t('iUnderstandAndConfirmDelete')"
          [(ngModel)]="confirmDelete"></p-checkbox>
      </div>
    </div>
    <div class="flex justify-end mt-6">
      <bl-frontend-button class="mr-2" [text]="'shared.cancel' | transloco" variant="secondary" (click)="close(null)"></bl-frontend-button>
      <bl-frontend-button
        [text]="primaryButtonI18nKey | transloco"
        variant="primary"
        [disabled]="!isValid"
        (click)="next()"></bl-frontend-button>
    </div>
  </div>
</ng-container>
