import { FirmSettings } from '../entity/firm.settings';
import { CountryType, LanguageType } from '../entity/locale';

export enum RoleType {
  BL_ADMIN = 'BL_ADMIN', // bl_admin används internt på BL användare i vissa fall
  FIRM_ADMIN = 'FIRM_ADMIN',
  FIRM_USER = 'FIRM_USER',
}

export enum RoleLabelType {
  FIRM_ADMIN = 'Byråadministratör',
  FIRM_USER = 'Byråmedarbetare',
}

export enum PrivilegeType {
  ADMIN = 'ADMIN',
  RW = 'RW',
}

export interface UserType {
  id: number;
  name: string;
  email: string;
  initials: string;
  username: string;
  firm: { name: string; corporateIdentity: string };
  role: RoleType;
  roleLabel: RoleLabelType;
  active: boolean;
  privilege?: PrivilegeType;
  teams?: number[]; // TODO: remove undefined once teams feature is released
  partnerProgram: boolean;
  languageCode: LanguageType;
}

export interface FirmType {
  id: number;
  name: string;
  corporateIdentity: string;
  address: string;
  zipcode: string;
  city: string;
  countryCode: CountryType;
  email: string;
  phone: string;
  dataOwnerId: string;
  firmKey: string;
  users: UserType[];
  properties: FirmSettings;
  sustainabilityReporting: boolean;
}

export type ToastWithoutSeverity = Omit<ToastMessageType, 'severity'>;

export interface ToastMessageType {
  severity: 'info' | 'warn' | 'error';
  summary: string;
  detail: string;
  sticky?: boolean;
  key?: string;
  life?: number;
  error?: unknown;
  button?: {
    text?: string;
    click: () => void;
  };
}

export interface NameDescriptionType {
  description: string;
  name: string;
}

export interface AssignmentType {
  id: number;
  label: string;
  name: string;
  rootTemplates: TaskType[];
  task: TaskType;
}

export interface StateChangeEventType {
  changedBy: string;
  eventDate: string;
  id: number;
  state: NameDescriptionType;
}

export interface SpanType {
  spanType?: string;
  spanLabel?: string;
  sameDay?: boolean;
  end?: string;
  start?: string;
}

export interface ActivityType {
  id: number;
  taskId: number;
  alertLevel: NameDescriptionType;
  alert_level: NameDescriptionType;
  state: NameDescriptionType;
  activeSpan: SpanType;
  span: SpanType;
  start_span: string;
  end_span: string;
  stateColor: string;
  deadline: string;
  comment: string;
  task: TaskType;
  users: UserType[];
  statusChangeDate: string;
  stateChangeEvents: StateChangeEventType[];
  latestStateChangeEvent?: StateChangeEventType;
  unsavedId?: number;
  materialUpdate?: {
    alertLevel?: NameDescriptionType;
    when?: string;
  };
}

export interface DeadlineRuleType {
  param: string;
  paramType: string;
  template: string;
  description?: string;
}

export interface TaskType {
  agent: string;
  agentCall: string;
  archived: boolean;
  assignmentAgent: string;
  assignmentAgreement: boolean;
  assignmentId: number;
  assignmentName: string;
  client: ClientType;
  client_id: number;
  client_name: string;
  code: string;
  comment: string;
  deadlineRule: DeadlineRuleType;
  deleteable: boolean;
  firmId: number;
  feedMode: string;
  id: number;
  parameterizedActivities: ActivityType[];
  periodicity: NameDescriptionType;
  preferredTemplate: boolean;
  preselectedCustomerResponsible: boolean;
  preselectedLoggedInUser: boolean;
  reminderDiff: number;
  selectablePeriodicities: NameDescriptionType[];
  selectableStates: NameDescriptionType[];
  selectable_states: NameDescriptionType[];
  span: SpanType;
  startDiff: number;
  statusesAsString: string;
  type: string;
  users: UserType[];
  usersAsString: string;
  warningDiff: number;
  taskGroupId: number | null;
}

export enum PermissionType {
  VisibleInNotification = 'VISIBLE_IN_NOTIFICATION',
  ShowTaxAccountBalance = 'SHOW_TAX_ACCOUNT_BALANCE',
}

export enum PermissionGroupType {
  None = 'NONE',
  All = 'ALL',
  OnlyResponsibleUserAndFirmAdmin = 'ONLY_RESPONSIBLE_USER_AND_FIRM_ADMIN',
  OnlyFirmAdmin = 'ONLY_FIRM_ADMIN',
  OnlySelectedUsers = 'ONLY_SELECTED_USERS',
}

export interface PermissionsType {
  id: number;
  clientId: number;
  permissionKey: PermissionType;
  permissionGroup: PermissionGroupType;
  userId: number;
}

export interface ContactType {
  id: number;
  clientId: number;
  label: string;
  phone: string;
  email: string;
  comment: string;
}

export interface FinancialYearType {
  clientId: number;
  span: SpanType;
  endDate: string;
  euCommerce: boolean;
  largeCompany: boolean;
  electronicVat: boolean;
  electronicCompilation: boolean;
  electronicTax: boolean;
  firmVatRespite: boolean;
  firmTaxRespite: boolean;
  id: number | null;
}

export interface SimpleUserType {
  id: number;
  name: string;
  initials: string;
  simple: true;
  teams?: number[];
}

export interface SimpleClientType {
  id: number;
  name: string;
  customerNumber: number;
  corporateIdentity: string;
  currentFinancialYear: FinancialYearType;
  responsible: SimpleUserType;
  simple: true;
}

export interface ClientType {
  id: number;
  name: string;
  customerNumber: number;
  corporateIdentity: string;
  type: NameDescriptionType;
  description: string;
  email: string;
  financialYears: FinancialYearType[];
  advisoryTool: boolean;
  alertIdentity: string;
  cloudApiKey: string;
  businessArea: string;
  specialRules: string;
  otherInfo: string;
  accountant: string;
  bank: string;
  registrationDate: string;
  contacts: ContactType[];
  saveFlag: number;
  deleteable: boolean;
  archived: boolean;
  userId: number;
  userAsString: string;
  responsible: UserType;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  residence: string;
  phone: string;
  cellphone: string;
  signatory: string;
  webpage: string;
  permissions: PermissionsType[];
  foreignCorporateIdentity: boolean;
  teams: { id: number; name: string }[];
  sustainabilityReporting: boolean;
}

export interface ClientTypeType extends NameDescriptionType {}

export interface ActivityStateType {
  description: string;
  name: string;
}

export enum ActivityStateGroup {
  Start = 'Start',
  InProgress = 'Pågående',
  Done = 'Klar',
}

export interface ActivityStateGroupType {
  description: string;
  name: ActivityStateGroup;
  items: ActivityStateType[];
}

export enum LoadingStatus {
  NotStarted = 'NotStarted',
  Loading = 'Loading',
  Failed = 'Failed',
}

export interface ListFilter {}

export interface CompositeListFilter extends ListFilter {
  includeAllDoneClients: boolean;
}

export interface ListParams {}

export interface CompositeListParams extends ListParams {
  responsibleId: number;
  executorId: number;
  year: string;
}

export interface BaseListState<
  EntityType extends Object,
  FilterType extends ListFilter,
  ParamsType extends ListParams,
> {
  filter: FilterType;
  params: ParamsType;
  status: LoadingStatus;
  items: EntityType[];
  error: any;
  selectedTeams?: number[];
}

export interface ClientListFilter {
  includeArchivedClients: boolean;
  responsibleUserId: number;
}

export interface ClosingListParams extends CompositeListParams {
  months: number[];
  clientTypes: string[];
}

export interface SalaryListParams extends CompositeListParams {
  periodIndex: number;
}

export interface TaxDeclarationListParams extends CompositeListParams {
  periodIndex: number;
}

export interface IncomeTaxDeclarationListParams extends CompositeListParams {
  periods: string[];
}

export interface AccountingListFilter extends CompositeListFilter {
  periodicy: string;
}

export interface AccountingListParams extends CompositeListParams {
  searchCriteria: number;
  periodIndex: number;
}

export interface TodoListFilter {
  includeAllDoneClients: boolean;
  includeAllUncompleted: boolean;
}

export interface TodoListParams {
  fromDate: string;
  toDate: string;
  executorId: number;
}

export interface CompositeBasePropertyType {
  activity: ActivityType;
  readyDate: string;
  task: { comment: string };
  agentCall: string;
  shard: { info: string } | null;
}

export interface MainSpanType {
  end: string;
  sameDay: boolean;
  spanLabel: string;
  spanType: string;
  start: string;
}

export enum CompositeType {
  accounting = 'accounting',
  closing = 'closing',
  incomeStatement = 'incomestatement',
  salaries = 'salaries',
  taxDeclaration = 'taxdeclaration',
  incomeTaxDeclaration = 'incometaxdeclaration',
}
export interface CompositeBaseType {
  type: CompositeType;
  taskUsers: string;
  client: string;
  clientId: number;
  corporateIdentity: string;
  customerNumber: string;
  responsible: string;
}

export interface ClosingCompositeType extends CompositeBaseType {
  type: CompositeType.closing;
  accountant: string;
  mainSpan: MainSpanType;
  best: CompositeBasePropertyType;
  forb: CompositeBasePropertyType;
  boks: CompositeBasePropertyType;
  uppr: CompositeBasePropertyType;
  arsr: CompositeBasePropertyType;
  arss: CompositeBasePropertyType;
  uppa: CompositeBasePropertyType;
  skic: CompositeBasePropertyType;
  bokr: CompositeBasePropertyType;
  bfrb: CompositeBasePropertyType;
}

export interface SalaryCompositeType extends CompositeBaseType {
  type: CompositeType.salaries;
  materialStatusColor: string;
  paymentDay: string;
  paymentMaterial: {
    alertLevel: NameDescriptionType;
    when: string;
  };
  lonb: CompositeBasePropertyType;
  lone: CompositeBasePropertyType;
  lonu: CompositeBasePropertyType;
  ovri: CompositeBasePropertyType;
  stat: CompositeBasePropertyType;
  form: CompositeBasePropertyType;
  coll: CompositeBasePropertyType;
}

export interface TaxDeclarationCompositeType extends CompositeBaseType {
  type: CompositeType.taxDeclaration;
  mainSpan: MainSpanType;
  mainSpanOrigin: string;
  arbe: CompositeBasePropertyType;
  beta: CompositeBasePropertyType;
  betg: CompositeBasePropertyType;
  lonn: CompositeBasePropertyType;
  momd: CompositeBasePropertyType;
  momu: CompositeBasePropertyType;
  pers: CompositeBasePropertyType;
}

export interface IncomeTaxDeclarationCompositeType extends CompositeBaseType {
  type: CompositeType.incomeTaxDeclaration;
  mainSpan: MainSpanType;
  deadline: string;
  firmTaxRespite: boolean;
  electronicTax: boolean;
  declarationPeriodCode: string;
  materialStatusColor: string;
  declarationMaterial: {
    materialReceivedAlertLevel: NameDescriptionType;
    materialReceivedAt: string;
    materialSortValue: string;
  };
  uppi: CompositeBasePropertyType;
  uppb: CompositeBasePropertyType;
}

export interface AccountingCompositeType extends CompositeBaseType {
  type: CompositeType.accounting;
  mainSpan: MainSpanType;
  accountingMaterial: { alertLevel: { name: ''; description: '' }; when: string };
  materialStatusColor: string;
  dags: CompositeBasePropertyType;
  bokf: CompositeBasePropertyType;
  kont: CompositeBasePropertyType;
  avst: CompositeBasePropertyType;
  rapp: CompositeBasePropertyType;
}

export interface RuleType {
  paramType: string;
  code: string;
  text: string;
}

export type TaskGroupTaskType = Pick<TaskType, 'assignmentName' | 'code' | 'type' | 'id'>;

export interface TaskGroupType {
  id: number;
  name: string;
  tasks: TaskGroupTaskType[];
}

export interface CreateTaskGroupCommand {
  name: string;
  tasks: number[];
}

export interface UpdateTaskGroupCommand {
  name?: string;
  tasks?: number[];
}

export enum TeamTypes {
  Root = 0,
  Client = 1,
  Task = 2,
}

// TODO - hitta ett bättre namn!
export type TeamTypeProp = {
  count: number;
  readonly: boolean;
  data: number[];
};

export interface TeamType {
  id: number;
  name: string;
  typeId: number;
  readonly: boolean;
  users: TeamTypeProp;
  clients: TeamTypeProp;
  tasks: TeamTypeProp;
}

export interface CreateTeamType {
  name: string;
  typeId: number;
  users?: number[];
  clients?: number[];
  tasks?: number[];
}

export interface UpdateTeamType {
  id: number;
  typeId: number;
  name?: string;
  users?: number[];
  clients?: number[];
  tasks?: number[];
}

export type ResponseTeamType = {
  id: number;
  typeId: number;
  name: string;
  readonly: boolean;
  users: Pick<TeamTypeProp, 'count' | 'readonly'> & { data: { id: number }[] };
  clients: Pick<TeamTypeProp, 'count' | 'readonly'> & { data: { id: number }[] };
  tasks: Pick<TeamTypeProp, 'count' | 'readonly'> & { data: { id: number }[] };
};

export interface FeatureType {
  messaging: boolean;
  showMyFirm: boolean;
  i18nSupport: boolean;
}

export interface ActivateSustainabilityReporting {
  name: string;
  email: string;
  corporateIdentity: string;
}

export interface MessagingMessage<T = any> {
  channel: string;
  data: MessagingMessageData<T>;
}

export interface MessagingMessageData<T> {
  firmId: number;
  messageId: string;
  userId: number;
  props: T | undefined;
}
