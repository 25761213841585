import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  template: `
    <div [hidden]="!active">
      <ng-content></ng-content>
    </div>
  `,
})
export class TabComponent {
  @Input() tabTitle: string;
  @Input() tabTooltip: string;
  @Input() tabId: string;
  @Input()
  get active() {
    return this.activeValue;
  }

  set active(value: boolean) {
    this.activeValue = value;
    this.activeChange.emit(value);
  }

  @Input() route: string = null;
  @Input() disabled = false;
  @Input() newFeature = false;
  @Input() notificationBadge: number = null;

  @Output() activeChange = new EventEmitter<boolean>();

  private activeValue = false;
}
