import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ControlValueAccessorFn } from '@app/core/state/angularTypes';
import { ClientSelectors } from '@app/core/state/clients/clients.selectors';
import { ClientType } from '@app/core/state/types';
import { BystSharedModule } from '@app/shared/byst-shared.module';
import { TableColumn } from '@app/shared/components/table/interfaces/table-column';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { getTranslationProvider } from 'src/app/core/i18n/getTranslationProvider';
import { TranslocoModule } from '@jsverse/transloco';
import { TranslatableComponent } from 'src/app/core/i18n/TranslatableComponent';
import { TranslatableStringType } from 'src/app/core/i18n/types';

type TeamClientTableFilter = {
  showOnlySelectedRows: boolean;
  includeArchivedClients: boolean;
};

interface ClientTypeWithArchivedName extends ClientType {
  archivedName: string;
}

interface TeamClientListComponentTranslation {
  name: TranslatableStringType;
  companyType: TranslatableStringType;
  clientResponsibleUser: TranslatableStringType;
  archived: TranslatableStringType;
}

@Component({
  selector: 'app-team-client-list',
  templateUrl: './team-client-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, BystSharedModule, TranslocoModule],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TeamClientListComponent), multi: true },
    getTranslationProvider('TeamClientListComponent'),
  ],
})
export class TeamClientListComponent
  extends TranslatableComponent<TeamClientListComponentTranslation>
  implements ControlValueAccessor
{
  clients$: Observable<ClientTypeWithArchivedName[]>;
  columns$: Observable<TableColumn[]>;

  selectedClients: ClientTypeWithArchivedName[] = [];
  showOnlySelectedRows = false;
  includeArchivedClients = false;
  private i18n: TeamClientListComponentTranslation;

  onChange: ControlValueAccessorFn = () => {};
  onTouched: ControlValueAccessorFn = () => {};

  private tableFilterBS = new BehaviorSubject<TeamClientTableFilter>({
    showOnlySelectedRows: false,
    includeArchivedClients: false,
  });

  get filterFields$() {
    return this.columns$.pipe(map((columns) => columns.map((column) => column.field)));
  }

  get sortField$() {
    return this.columns$.pipe(map((columns) => columns[0]?.field));
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();

    this.i18n = {} as TeamClientListComponentTranslation;

    this.columns$ = this.getTranslatedColumns();

    const filteredClients$ = this.store
      .select(ClientSelectors.allClients)
      .pipe(switchMap((clients) => this.getFilteredClientsObservable(clients)));

    const translationsLoadedWithValues$ = this.translationsLoaded$.pipe(filter(Boolean));

    this.clients$ = combineLatest([filteredClients$, translationsLoadedWithValues$]).pipe(
      tap(([, translations]) => {
        this.i18n = translations;
      }),
      map(([clients]) => this.mapToClientTypeWithArchivedName(clients)),
    );
  }

  writeValue(clients: ClientType[]): void {
    this.selectedClients = this.mapToClientTypeWithArchivedName(clients);
    this.changeDetectorRef.markForCheck();
    // reset checkboxes and filter
    this.tableFilterBS.next({ showOnlySelectedRows: false, includeArchivedClients: false });
    this.showOnlySelectedRows = false;
    this.includeArchivedClients = false;
  }

  registerOnChange(fn: ControlValueAccessorFn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: ControlValueAccessorFn): void {
    this.onTouched = fn;
  }

  getColumnWidth(column: TableColumn) {
    if (!column.width || column.width === 'auto') {
      return {};
    }

    return { maxWidth: column.width };
  }

  filterTableBaseOnShowOnlySelectedRows(showOnlySelectedRows: boolean) {
    this.refilterClients(this.updateTableFilter({ showOnlySelectedRows }));
  }

  filterTableBasedOnIncludeArchivedClients(includeArchivedClients: boolean) {
    this.refilterClients(this.updateTableFilter({ includeArchivedClients }));
  }

  refilterClients(filterValue?: TeamClientTableFilter) {
    const updatedFilterValue = filterValue ?? { ...this.tableFilterBS.value };
    this.tableFilterBS.next(updatedFilterValue);
  }

  private updateTableFilter(value: Partial<TeamClientTableFilter>) {
    return { ...this.tableFilterBS.value, ...value };
  }

  private getFilteredClientsObservable(clients: ClientType[]) {
    return this.tableFilterBS.pipe(map((filter) => this.applyFilterOnClients(clients, filter)));
  }

  private applyFilterOnClients(
    clients: ClientType[],
    { showOnlySelectedRows, includeArchivedClients }: TeamClientTableFilter,
  ) {
    const archivedClientsFilter = (client: ClientType) => includeArchivedClients || !client.archived;

    if (!showOnlySelectedRows) {
      return clients.filter(archivedClientsFilter);
    }

    const filteredClientIds = (this.selectedClients || []).filter(archivedClientsFilter).map((client) => client.id);
    return clients.filter((client) => filteredClientIds.includes(client.id));
  }

  private mapToClientTypeWithArchivedName = (clients: ClientType[]) => {
    if (!clients) {
      return null;
    }

    const archivedNamePrefix = `${this.i18n.archived} - `;
    const clientsWithArchivedName = clients.map((client) => ({
      ...client,
      archivedName: `${client.archived ? archivedNamePrefix : ''}${client.name}`,
    }));
    return clientsWithArchivedName;
  };

  private getTranslatedColumns() {
    return this.translationsLoaded$.pipe(
      map((translations) => {
        const toSafeString = (value: TranslatableStringType) => (value ? value : '');

        return {
          name: toSafeString(translations?.name),
          companyType: toSafeString(translations?.companyType),
          clientResponsibleUser: toSafeString(translations?.clientResponsibleUser),
        };
      }),
      map(({ name, clientResponsibleUser, companyType }) => [
        { field: 'archivedName', header: name, width: '200px' },
        { field: 'type.description', header: companyType, width: '100px' },
        { field: 'responsible.name', header: clientResponsibleUser, width: '100px' },
      ]),
    );
  }
}
